// const { FirebaseApp } = require('firebase/app');
// const { CustomProvider } = require('firebase/app-check');
// const { Functions } = require('firebase/functions');
import env from '../env';
import { FIREBASE_APPCHECK_DEBUG_TOKEN, STAX_APP_ENV } from '../../environment/env.json'
import Helpers from '@/helpers/ipc-helpers';

const appCheckCustomProvider = async (): Promise<any> => {
  const { ReCaptchaV3Provider } = await require('firebase/app-check');
  const prov = new ReCaptchaV3Provider('6LeWjNMlAAAAAMAO51_dCZLu0O4-HIbr6hf_2e8W');
  return prov;
}

export default class FirebaseService {
  static _functions: any = null;
  static _app: any = null;
  static _instance: FirebaseService;
  _receipt: {receipt: string};
  public static API_URL = env.FIREBASE_FUNCTIONS_ENDPOINT;

  constructor(receipt: {receipt: string}) {
    this._receipt = receipt;
  }

  static create(): Promise<FirebaseService> {
    return new Promise((resolve, reject) => {
      if (STAX_APP_ENV !== 'mas') {
        reject(false)
      } else {
        Helpers.getReceiptB64()
          .then(receipt => {
            resolve(new FirebaseService({ receipt }));
          })
          .catch(reject)
      }
    })
  }

  async getFunctions(): Promise<any> {
    if (!FirebaseService._functions) {
      const { getFunctions, connectFunctionsEmulator } = await require("@firebase/functions");
      const app = await this.getApp();
      FirebaseService._functions = getFunctions(app, FirebaseService.API_URL);
      if (FIREBASE_APPCHECK_DEBUG_TOKEN)
        connectFunctionsEmulator(FirebaseService._functions, 'localhost', 5001);
    }
    return FirebaseService._functions;
  }

  async getApp(): Promise<any>  {
    if (!FirebaseService._app) {
      // TODO get from creds/env vars
      const { initializeApp } = await require('firebase/app');
      // const { initializeAppCheck } = await require('firebase/app-check');
      FirebaseService._app = initializeApp({
        apiKey: "AIzaSyCDfNzMg05-Ldaz6Ov-YmTGHgH655utZeY",
        authDomain: "symbol-tax.firebaseapp.com",
        projectId: "symbol-tax",
        appId: "1:1096266160421:web:dda2e317e8ec9307d322cc"
      });

      if (FIREBASE_APPCHECK_DEBUG_TOKEN)
        self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
      // const provider = await appCheckCustomProvider();
      // await provider.getToken();
      // await initializeAppCheck(FirebaseService._app, { provider, isTokenAutoRefreshEnabled: true });
    }
    return FirebaseService._app;
  }

  async verify(): Promise<boolean> {
    // console.debug('Verifying receipt', this._receipt);
    if (!this._receipt.receipt)
      throw new Error('No receipt.');
    const functions = await this.getFunctions();
    const { httpsCallable } = await require("@firebase/functions");
    const verifyReceipt = httpsCallable(functions, 'verifyReceipt');
    const result = await verifyReceipt(this._receipt);
    const { status, signature } = result.data;
    if (status !== 0)
      throw new Error('Invalid status from receipt verification.')

    const isVerified = await Helpers.serviceVerify('signature', {
      key: this._receipt.receipt,
      secret: signature,
      passphrase: null
    });
    if (!isVerified)
      throw new Error('Invalid signature.')

    return true;
  }

}