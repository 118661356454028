export default {
  btcusd: {
    symbol: 'BTCUSD',
    base_currency: 'BTC',
    quote_currency: 'USD',
    tick_size: 1e-8,
    quote_increment: 0.01,
    min_order_size: '0.00001',
    status: 'open'
  },
  btcdai: {
    symbol: 'BTCDAI',
    base_currency: 'BTC',
    quote_currency: 'DAI',
    tick_size: 1e-8,
    quote_increment: 0.01,
    min_order_size: '0.00001',
    status: 'limit_only'
  },
  btcgbp: {
    symbol: 'BTCGBP',
    base_currency: 'BTC',
    quote_currency: 'GBP',
    tick_size: 1e-8,
    quote_increment: 0.01,
    min_order_size: '0.00001',
    status: 'open'
  },
  btceur: {
    symbol: 'BTCEUR',
    base_currency: 'BTC',
    quote_currency: 'EUR',
    tick_size: 1e-8,
    quote_increment: 0.01,
    min_order_size: '0.00001',
    status: 'open'
  },
  btcsgd: {
    symbol: 'BTCSGD',
    base_currency: 'BTC',
    quote_currency: 'SGD',
    tick_size: 1e-8,
    quote_increment: 0.01,
    min_order_size: '0.00001',
    status: 'open'
  },
  ethbtc: {
    symbol: 'ETHBTC',
    base_currency: 'ETH',
    quote_currency: 'BTC',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.001',
    status: 'limit_only'
  },
  ethusd: {
    symbol: 'ETHUSD',
    base_currency: 'ETH',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  ethgbp: {
    symbol: 'ETHGBP',
    base_currency: 'ETH',
    quote_currency: 'GBP',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  etheur: {
    symbol: 'ETHEUR',
    base_currency: 'ETH',
    quote_currency: 'EUR',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  ethsgd: {
    symbol: 'ETHSGD',
    base_currency: 'ETH',
    quote_currency: 'SGD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  ethdai: {
    symbol: 'ETHDAI',
    base_currency: 'ETH',
    quote_currency: 'DAI',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'limit_only'
  },
  bchusd: {
    symbol: 'BCHUSD',
    base_currency: 'BCH',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  bchbtc: {
    symbol: 'BCHBTC',
    base_currency: 'BCH',
    quote_currency: 'BTC',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.001',
    status: 'open'
  },
  bcheth: {
    symbol: 'BCHETH',
    base_currency: 'BCH',
    quote_currency: 'ETH',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.001',
    status: 'open'
  },
  ltcusd: {
    symbol: 'LTCUSD',
    base_currency: 'LTC',
    quote_currency: 'USD',
    tick_size: 0.00001,
    quote_increment: 0.01,
    min_order_size: '0.01',
    status: 'open'
  },
  ltcbtc: {
    symbol: 'LTCBTC',
    base_currency: 'LTC',
    quote_currency: 'BTC',
    tick_size: 0.00001,
    quote_increment: 0.00001,
    min_order_size: '0.01',
    status: 'limit_only'
  },
  ltceth: {
    symbol: 'LTCETH',
    base_currency: 'LTC',
    quote_currency: 'ETH',
    tick_size: 0.00001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'open'
  },
  ltcbch: {
    symbol: 'LTCBCH',
    base_currency: 'LTC',
    quote_currency: 'BCH',
    tick_size: 0.00001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'open'
  },
  zecusd: {
    symbol: 'ZECUSD',
    base_currency: 'ZEC',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  zecbtc: {
    symbol: 'ZECBTC',
    base_currency: 'ZEC',
    quote_currency: 'BTC',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.001',
    status: 'limit_only'
  },
  zeceth: {
    symbol: 'ZECETH',
    base_currency: 'ZEC',
    quote_currency: 'ETH',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.001',
    status: 'limit_only'
  },
  zecbch: {
    symbol: 'ZECBCH',
    base_currency: 'ZEC',
    quote_currency: 'BCH',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.001',
    status: 'open'
  },
  zecltc: {
    symbol: 'ZECLTC',
    base_currency: 'ZEC',
    quote_currency: 'LTC',
    tick_size: 0.000001,
    quote_increment: 0.001,
    min_order_size: '0.001',
    status: 'open'
  },
  batusd: {
    symbol: 'BATUSD',
    base_currency: 'BAT',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '1',
    status: 'open'
  },
  batbtc: {
    symbol: 'BATBTC',
    base_currency: 'BAT',
    quote_currency: 'BTC',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '1',
    status: 'open'
  },
  bateth: {
    symbol: 'BATETH',
    base_currency: 'BAT',
    quote_currency: 'ETH',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.001',
    status: 'open'
  },
  linkusd: {
    symbol: 'LINKUSD',
    base_currency: 'LINK',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.1',
    status: 'open'
  },
  linkbtc: {
    symbol: 'LINKBTC',
    base_currency: 'LINK',
    quote_currency: 'BTC',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'open'
  },
  linketh: {
    symbol: 'LINKETH',
    base_currency: 'LINK',
    quote_currency: 'ETH',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.1',
    status: 'open'
  },
  daiusd: {
    symbol: 'DAIUSD',
    base_currency: 'DAI',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.1',
    status: 'open'
  },
  oxtusd: {
    symbol: 'OXTUSD',
    base_currency: 'OXT',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '1',
    status: 'open'
  },
  oxtbtc: {
    symbol: 'OXTBTC',
    base_currency: 'OXT',
    quote_currency: 'BTC',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '1',
    status: 'open'
  },
  oxteth: {
    symbol: 'OXTETH',
    base_currency: 'OXT',
    quote_currency: 'ETH',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '1',
    status: 'open'
  },
  filusd: {
    symbol: 'FILUSD',
    base_currency: 'FIL',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  ampusd: {
    symbol: 'AMPUSD',
    base_currency: 'AMP',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '10',
    status: 'open'
  },
  paxgusd: {
    symbol: 'PAXGUSD',
    base_currency: 'PAXG',
    quote_currency: 'USD',
    tick_size: 1e-8,
    quote_increment: 0.01,
    min_order_size: '0.0001',
    status: 'open'
  },
  compusd: {
    symbol: 'COMPUSD',
    base_currency: 'COMP',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  mkrusd: {
    symbol: 'MKRUSD',
    base_currency: 'MKR',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.001',
    status: 'open'
  },
  zrxusd: {
    symbol: 'ZRXUSD',
    base_currency: 'ZRX',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  kncusd: {
    symbol: 'KNCUSD',
    base_currency: 'KNC',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'open'
  },
  storjusd: {
    symbol: 'STORJUSD',
    base_currency: 'STORJ',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  manausd: {
    symbol: 'MANAUSD',
    base_currency: 'MANA',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '1',
    status: 'limit_only'
  },
  aaveusd: {
    symbol: 'AAVEUSD',
    base_currency: 'AAVE',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.001',
    status: 'open'
  },
  snxusd: {
    symbol: 'SNXUSD',
    base_currency: 'SNX',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'open'
  },
  yfiusd: {
    symbol: 'YFIUSD',
    base_currency: 'YFI',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.01,
    min_order_size: '0.00001',
    status: 'open'
  },
  umausd: {
    symbol: 'UMAUSD',
    base_currency: 'UMA',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'limit_only'
  },
  balusd: {
    symbol: 'BALUSD',
    base_currency: 'BAL',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'limit_only'
  },
  crvusd: {
    symbol: 'CRVUSD',
    base_currency: 'CRV',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  renusd: {
    symbol: 'RENUSD',
    base_currency: 'REN',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  uniusd: {
    symbol: 'UNIUSD',
    base_currency: 'UNI',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'open'
  },
  enjusd: {
    symbol: 'ENJUSD',
    base_currency: 'ENJ',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  bntusd: {
    symbol: 'BNTUSD',
    base_currency: 'BNT',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'limit_only'
  },
  '1inchusd': {
    symbol: '1INCHUSD',
    base_currency: '1INCH',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'limit_only'
  },
  sklusd: {
    symbol: 'SKLUSD',
    base_currency: 'SKL',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  grtusd: {
    symbol: 'GRTUSD',
    base_currency: 'GRT',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  lrcusd: {
    symbol: 'LRCUSD',
    base_currency: 'LRC',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  sandusd: {
    symbol: 'SANDUSD',
    base_currency: 'SAND',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  cubeusd: {
    symbol: 'CUBEUSD',
    base_currency: 'CUBE',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.01',
    status: 'limit_only'
  },
  bondusd: {
    symbol: 'BONDUSD',
    base_currency: 'BOND',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.0001,
    min_order_size: '0.001',
    status: 'limit_only'
  },
  dogeusd: {
    symbol: 'DOGEUSD',
    base_currency: 'DOGE',
    quote_currency: 'USD',
    tick_size: 0.000001,
    quote_increment: 0.00001,
    min_order_size: '0.1',
    status: 'limit_only'
  },
  gusdusd: {
    symbol: 'GUSDUSD',
    base_currency: 'GUSD',
    quote_currency: 'USD',
    tick_size: 1e-34,
    quote_increment: 1e-34,
    min_order_size: '0.00000001',
    status: 'closed'
  }  
}