import { Transaction } from "@/models/transaction";
import { ApiCredentials, BaseApi, BaseApiService, OauthCredentials, TokenCredentials } from "../base-api";
import crypto from 'crypto';
import { AxiosError, AxiosResponse } from "axios";
import env from "../env";
import { CoinbaseApiAccount } from "./coinbase-api-account";
import { CoinbaseApiListResponse, CoinbaseApiPagination, CoinbaseApiTransactionResource, CoinbaseTransactionResource } from "./coinbase-api-transaction-resource";

export default class CoinbaseApi extends BaseApiService implements BaseApi {
  static API_URL = env.COINBASE_API_URL;
  static API_VERSION = 'v2';
  constructor(credentials: ApiCredentials|OauthCredentials|TokenCredentials) {
    super(credentials)
  }
  private signature(timestamp, method, path, body, isPro = false, query = {}) {
    const bunkUrl = new URL('http://example.com');
    Object.keys(query).forEach(q => {
      if (query[q]) {
        bunkUrl.searchParams.append(q, query[q]);
      }
    })
    const message = `${timestamp}${method}${path}${bunkUrl.search}${body}`;
    const key = isPro ? Buffer.from(this._credentials.secret, 'base64') : this._credentials.secret;
    return crypto.createHmac('sha256', key).update(message).digest(isPro ? 'base64' : 'hex');
  }
  protected headers(timestampMs, method, path, body, isPro = false, query = {}) {
    const seconds = Math.floor(timestampMs / 1000);
    return {
      'CB-ACCESS-SIGN': this.signature(seconds, method, path, body, isPro, query),
      'CB-ACCESS-TIMESTAMP': seconds,
      'CB-ACCESS-KEY': this._credentials.key,
      'CB-VERSION': '2021-04-24'
    }
  }
  private get tokenHeaders() {
    return {
      'Authorization': `Bearer ${this._tokenCreds.access_token}`,
      'CB-VERSION': '2021-04-24'
    }
  }

  timeout = 201;

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAccounts()
        .then(res => resolve(true))
        .catch(err => reject(this.handleError(err)))
    })
  }

  oauth(): Promise<TokenCredentials> {
    return new Promise((resolve, reject) => {
      if (this._oauthCreds.code) {
        const path = '/oauth/token';
        const params = {
          grant_type: 'authorization_code',
          code: this._oauthCreds.code,
          client_id: this._oauthCreds.client_id,
          client_secret: this._oauthCreds.client_secret,
          redirect_uri: env.COINBASE_AUTH_REDIRECT_URI
        }
        this._service.post(path, null, { params })
          .then(((res: AxiosResponse) => resolve(res.data)))
          .catch((err: AxiosError) => reject(this.handleError(err)))
      } else {
        reject('No authorization code present')
      }
    })
  }

  refresh(): Promise<TokenCredentials> {
    return new Promise((resolve, reject) => {
      const path = '/oauth/token';
      const params = {
        grant_type: 'refresh_token',
        refresh_token: this._tokenCreds.refresh_token,
        client_id: this._oauthCreds.client_id,
        client_secret: this._oauthCreds.client_secret
      }
      this._service.post(path, null, { params })
        .then(((res: AxiosResponse) => resolve(res.data)))
        .catch((err: AxiosError) => reject(this.handleError(err)))
    })
  }

  protected handleError(err: any) {
  // console.debug('err in coinbase request', err, err.response);
    if (err.response && err.response.data.errors && err.response.data.errors[0]) {
      if (err.response.data.errors[0].id === 'expired_token') {
        return 'expired'
      } else {
        return err.response.data.errors[0].message;
      }
    } else if (err.response && err.response.data.error && err.response.data.error_description) {
      return err.response.data.error_description;
    } else if (err.response && err.response.data.message) {
      return err.response.data.message;
    } else if (err.message) {
      return err.message;
    } else {
      return err.toString();
    }
  }

  async fetchTransactions(options: any = {}): Promise<Transaction[]> {
    try {
      let accounts = [];
      let accountsResponse = await this.getAccounts();
      accounts = accounts.concat(accountsResponse.data);
      while (accountsResponse.pagination.next_starting_after) {
        accountsResponse = await this.getAccounts(accountsResponse.pagination.next_starting_after);
        accounts = accounts.concat(accountsResponse.data);
      }
      let results: Transaction[] = [];
      for (const acct of accounts as CoinbaseApiAccount[]) {
        const starting_after = options.starting_afters ? options.starting_afters[acct.id] : null;
        const txResponse = await this.getTransactionsForAccount(acct, starting_after);
        const txs = (txResponse.data as CoinbaseApiTransactionResource[]).map((tx: CoinbaseApiTransactionResource) => { tx.account_id = acct.id; return tx; });
        results = results.concat(txs.map(tx => new CoinbaseTransactionResource(tx)));
      }
      return results;
    } catch (e) {
      return this.handleError(e);
    }
  }

  private getAccounts(starting_after: string = null): Promise<CoinbaseApiListResponse> {
    return new Promise((resolve, reject) =>  {
      const path = `/${CoinbaseApi.API_VERSION}/accounts`
      const query = {
        limit: 250,
        order: 'asc',
        starting_after
      }
      const options = {
        headers: this.headers(Date.now(), 'GET', path, '', false, query),
        params: query
      }
      this._service.get(path, options)
        .then((res: AxiosResponse) => resolve(res.data))
        .catch(err => reject(this.handleError(err)))
    });
  }

  private getTransactionsForAccount(acct: CoinbaseApiAccount, starting_after: string = null): Promise<CoinbaseApiListResponse> {
    return new Promise((resolve, reject) => {
      const path = `/${CoinbaseApi.API_VERSION}/accounts/${acct.id}/transactions`
      const query = {
        limit: 300,
        order: 'asc',
        starting_after
      }
      const options = {
        headers: this.headers(Date.now(), 'GET', path, '', false, query),
        params: query
      }
      this._service.get(path, options)
        .then((res: AxiosResponse) => resolve(res.data))
        .catch(err => reject(this.handleError(err)))
    });
  }

}