import Helpers from "@/helpers/ipc-helpers";
import { DbTable } from "@/helpers/DbTable";
import { StoreChart } from "../interfaces/base-store-state";
import { Income, IncomeDbTransformer, IncomeStoreState } from "../interfaces/income-store-state";
import { commonMutations, paginationMutations, sortingGetteres, toastActions } from "../mixins";

export default {
  namespaced: true,
  state: (): IncomeStoreState => ({
    dbReady: false,
    loading: false,
    incomes: [],
    chart: {
      labels: [],
      values: []
    },
    paginationFields: {
      noRows: 0,
      perPage: 100,
      currentPage: 1,
      sortBy: 'date',
      sortDesc: false
    }
  }),
  getters: {
    dateFilter (state, rootState, getters, rootGetters) {
      return rootGetters['reports/dateFilter'];
    },
    ...sortingGetteres()
  },
  actions: {
    ...toastActions(),
    fetchIncome ({ dispatch, commit, getters, state, rootState}) {
      return new Promise((resolve, reject) => {
        commit('loading');
        Helpers.dbFind(
          DbTable.INCOMES,
          { date: getters.dateFilter },
          null,
          state.paginationFields,
          getters.sorting,
          rootState.reports.filters)
          .then((docs: any[]) => {
          // console.debug('income fetched', docs);
            const income = docs.map(d => IncomeDbTransformer.fromDb(d));
            commit('setIncome', income);
            commit('loaded');
            resolve(income);
          })
          .catch(err => {
            dispatch('dbError', err);
            reject(err);
          });
      });
    },
    fetchIncomeCount ({ dispatch, commit, getters, state, rootState}) {
      return new Promise((resolve, reject) => {
        Helpers.dbCount(DbTable.INCOMES, { date: getters.dateFilter }, rootState.reports.filters)
        .then(count => {
        // console.debug('income count fetched...', count);
          commit('setNoRows', count);
          resolve(count);
        })
        .catch(err => {
          dispatch('dbError', err);
          reject(err);
        });
      });
    },
    calcIncome ({ dispatch, commit, getters, rootState, rootGetters }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        Helpers.incomeCalc(
          {},
          { startDate: null, endDate: null, types: ['Deposits'], symbols: []},
          rootGetters.portfolioCoins,
          rootGetters['prices/pricesGetter']
          )
          .then((result: Income[]) => {
            commit('loaded')
            resolve(result);
          })
          .catch((err) => {
            dispatch('dbError', err);
            reject(err);
          })
      })
    },
    fetchIncomeChart ({ state, dispatch, commit, getters, rootState, rootGetters }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        Helpers.incomeChart({ date: getters.dateFilter }, rootState.reports.filters)
          .then((chart: StoreChart) => {
            commit('setIncomeChart', chart)
            commit('loaded')
            resolve(chart)
          })
          .catch(err => dispatch('dbError', err))
      });
    }
  },
  mutations: {
    ...commonMutations(),
    ...paginationMutations(),
    setIncome (state: IncomeStoreState, income: Income[]) {
      state.incomes = income
    },
    setIncomeChart (state: IncomeStoreState, chart: StoreChart) {
      state.chart = chart
    }
  }
}