import Vue from 'vue';

declare global {
  interface HTMLElement {
    clickOutsideEvent: any;
  }
}

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    const vm = vnode.context;
    const callback = binding.value;
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target)) && el.offsetWidth && el.offsetHeight) {
        callback.call(vm, event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});