import { GainLossEntry, PriceData } from "@/models/gain-loss-entry";
import { CalcMethod } from "@/store/interfaces/CalcMethod";
import { CoinstatsDbCoin } from "../coinstats/CoinstatsDbCoin";

export function glInit(prices: PriceData[], coins: CoinstatsDbCoin[], calcMethod: CalcMethod) {
  GainLossEntry.clear();
  GainLossEntry.prices = prices;
  GainLossEntry.coinMap = coins.reduce((a,b) => {
    a[b._id] = b;
    return a;
  }, {})
  GainLossEntry.calcMethod = calcMethod || CalcMethod.FIFO;
  // console.debug('gl:init --- calcMethod', calcMethod, GainLossEntry.calcMethod);
  return Promise.resolve(true);
}
