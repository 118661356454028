import Helpers from '@/helpers/ipc-helpers'
import { DbTable } from "@/helpers/DbTable";
import { PaginationFields } from '@/store/interfaces/pagination-fields';
import { Coin, CoinsStoreState } from '../interfaces/coin-store-state';
import { toastActions } from '../mixins';
import { ImportResponse } from '@/services/import-response';
 
export default {
  namespaced: true,
  state: (): CoinsStoreState => ({
    dbReady: false,
    coins: [],
    coinSuggestions: [],
    loading: true,
    paginationFields: {
      sortBy: 'rank',
      sortDesc: false,
      currentPage: 1,
      perPage: 2000,
      noRows: 0
    }
  }),
  getters: {
    symbolCoinMap: (state: CoinsStoreState) => {
      const map = {};
      state.coins.forEach(coin => {
        if (map[coin.symbol]) {
          map[coin.symbol].push(coin);
        } else {
          map[coin.symbol] = [coin];
        }
        map[coin.symbol].sort((a, b) => a.rank - b.rank);
      });
      return map;
    }
  },
  actions: {
    ...toastActions(),
    refresh ({ dispatch }) {
      dispatch('fetchCoins', true)
    },
    fetchCoins ({ commit, dispatch, rootState }, refresh: false) {
      commit('loading')
      Helpers.serviceImport('coinstats', rootState.settings.settings.apiKeys.coinstats)
        .then((res: ImportResponse) => {
          commit('dbReady');
          commit('loaded');
        })
        .catch(err => {
          commit('dbReady');
          commit('loaded');
          console.error(err);
          // dispatch('dbError', err);
          dispatch('dbWarning', 'Update your CoinStats API Key in settings to get accurate historical coin prices.');
        })
    },
    queryCoins ({ commit, dispatch }, query: string): Promise<Coin[]> {
      if (!query) {
        commit('setQueryCoins', []);
        return Promise.resolve([]);
      }
      // console.debug('running query', query);
      const regExp = new RegExp(query, 'i');
      return new Promise((resolve, reject) => {
        commit('loading');
        // Helpers.dbFind(DbTable.COINS, { symbol: regExp }, null, { currentPage: 1, perPage: 10 })
        Helpers.dbFind(DbTable.COINS, { $or: [{ symbol: regExp }, { name: regExp }] }, null, { currentPage: 1, perPage: 10 }, { rank: 1 })
          .then(coins => {
            // console.debug('result for query...', query, coins);
            commit('setQueryCoins', coins)
            commit('loaded')
          })
          .catch(err => {
            commit('loaded')
            commit('dbError')
          })

      });
    }
  },
  mutations: {
    loading (state: CoinsStoreState) {
      state.loading = true;
    },
    loaded (state: CoinsStoreState) {
      state.loading = false;
    },
    setCoins (state: CoinsStoreState, docs: Coin[]) {
      state.coins = docs;
    },
    dbReady (state: CoinsStoreState) {
      state.dbReady = true;
    },
    setQueryCoins (state: CoinsStoreState, coins: Coin[]) {
      state.coinSuggestions = coins;
    }
  }
}