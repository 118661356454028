import { Income } from "@/store/interfaces/income-store-state";
import { dateHelper } from "@/helpers/dateHelper";
import fs from 'fs';
import { stringify } from 'csv-stringify';
import { CsvOutput } from "../gl/csvifyGains";

export function csvifyIncome(incomes: Income[], filename: string): Promise<CsvOutput> {
  return new Promise((resolve, reject) => {
    stringify(
      incomes.map(i => {
        const date = dateHelper(i, 'date');
        return [`${i.amount} ${i.symbol}`, date, i.value.toFixed(2)];
      }),
      {
        header: true,
        columns: ['Description', 'Date', 'Value']
      },
      (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve({ filename, output: res });
        }
      });
  });
}
