import { DbTable } from "@/helpers/DbTable";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ImportResponse } from "@/services/import-response";
import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import { BaseImport, BaseImportService } from "../base-import";
import { dbFind } from "../db/dbFind";
import { dbInsert } from "../db/dbInsert";
import CoinbaseProApi, { CoinbaseProApiFill, CoinbaseProApiProduct, CoinbaseProFill } from "./coinbase-pro-api";

const productBeforePagination = (fills: CoinbaseProApiFill[]): { befores: { [product: string]: number } } => {
  return fills.reduce((a: { befores: { [product: string]: number } }, b: CoinbaseProApiFill) => {
    a.befores = a.befores || {};
    const product = b.product_id;
    if (product) {
      if (a.befores[product]) {
        a.befores[product] = Math.max(a.befores[product], b.trade_id);
      } else {
        a.befores[product] = b.trade_id;
      }
    }
    return a;
  }, Object.create({}))
}

const productAfterPagination = (fills: CoinbaseProApiFill[]): { afters: { [product: string]: number } } => {
  return fills.reduce((a: { afters: { [product: string]: number } }, b: CoinbaseProApiFill) => {
    a.afters = a.afters || {};
    const product = b.product_id;
    if (product) {
      if (a.afters[product]) {
        a.afters[product] = Math.min(a.afters[product], b.trade_id);
      } else {
        a.afters[product] = b.trade_id;
      }
    }
    return a;
  }, Object.create({}))
}

export default class CoinbaseProImport extends BaseImportService implements BaseImport {
  static historicalDones: { [product: string]: boolean } = {};
  static realyDones: { [product: string]: boolean } = {};
  declare api: CoinbaseProApi;
  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super('coinbasePro', creds);
  }

  async import(): Promise<ImportResponse> {
    const response: ImportResponse = {
      new: 0,
      existing: 0,
      linked: 0
    }
    const products: CoinbaseProApiProduct[] = await this.api.getProducts();
    let results: CoinbaseProFill[] = [];
    for (const product of products) {
      if (CoinbaseProImport.realyDones[product.id]) continue;
      const docs: any = await dbFind(null, DbTable.TRANSACTIONS, { 'source.name': 'Coinbase Pro Fill', 'source.product_id': product.id }, null, { date: 1 });
      const fills = docs.map(d => d.source) as CoinbaseProApiFill[];
      let pages: any = CoinbaseProImport.historicalDones[product.id] ? productBeforePagination(fills) : productAfterPagination(fills);
      let txs = await this.api.getFills(product, pages);
      if (txs.length === 0 && !CoinbaseProImport.historicalDones[product.id]) {
        CoinbaseProImport.historicalDones[product.id] = true;
        pages = productBeforePagination(fills);
        txs = await this.api.getFills(product, pages);
      }
      if (txs.length === 0) {
        CoinbaseProImport.realyDones[product.id] = true;
      }
      results = results.concat(txs.map(t => new CoinbaseProFill(t)));
    }
    return await this.findExisting(results, response);
  }

  private findExisting(txs: CoinbaseProFill[], response: ImportResponse): Promise<ImportResponse> {
    return new Promise((resolve, reject) => {
      dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(txs), null, { date: 1 })
      .then((matching: any) => {
        response.existing = matching.length;
        const newTxs = this.newTxs(txs, matching);
        dbInsert(DbTable.TRANSACTIONS, newTxs.map(t => TransactionFactory.toDB(t)))
          .then((docs) => {
            response.new += docs.length;
            resolve(response);
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
    });
  }
}