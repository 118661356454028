'use strict';
import { dbLoad } from "@/services/db/dbLoad";
import { dbIndex } from "@/services/db/dbIndex";



export async function initDb() {
  await dbLoad('prices');
  await dbLoad('coins');
  await dbLoad('reports');
  await dbLoad('transactions');
  await dbLoad('gains');
  await dbLoad('settings');
  await dbLoad('incomes');
  await dbLoad('migrations');
  await dbIndex('transactions', { fieldName: 'date' });
  await dbIndex('transactions', { fieldName: 'receivedSymbol' });
  await dbIndex('transactions', { fieldName: 'sentSymbol' });
  await dbIndex('gains', { fieldName: 'symbol' });
  await dbIndex('gains', { fieldName: 'soldAt' });
  await dbIndex('coins', { fieldName: 'symbol' });
  await dbIndex('coins', { fieldName: 'rank' });
  await dbIndex('coins', { fieldName: 'contractAddress' });
  await dbIndex('incomes', { fieldName: 'symbol' });
  await dbIndex('incomes', { fieldName: 'date' });
}
