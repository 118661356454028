<template>
  <b-modal
    :id="isEdit ? 'editTransaction' : 'newTransaction'"
    ref="modal"
    :title="isEdit ? 'Edit Transaction' : 'New Transaction'"
    ok-variant="outline-dark"
    size="lg"
    @show="resetForm"
    @hidden="resetForm"
    @ok="handleOk">
    <b-alert variant="warning" :show="isEdit && editTx && editTx.hash"><b-icon-exclamation-triangle></b-icon-exclamation-triangle> This transaction is associated with a blockchain transaction hash, proceed with caution when editing.</b-alert>
    <form ref="newTransaction" @submit.stop.prevent="handleSubmit" v-if="$v && $v.form">
      <b-form-row>
        <b-col>
          <!-- Date -->
          <b-form-group
            label="Date"
            label-for="date"
            :state="validateState('date')">
            <b-form-datepicker
              id="date"
              @input="getPriceFor('fee'); getPriceFor('received'); getPriceFor('sent');"
              v-model="$v.form.date.$model"
              :state="validateState('date')"
              required>
            </b-form-datepicker>
            <b-form-invalid-feedback v-if="!$v.form.date.required">Date is required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col>
          <!-- Time -->
          <b-form-group
            label="Time"
            label-for="time"
            :state="validateState('time')">
            <b-form-timepicker
              id="time"
              v-model="$v.form.time.$model"
              :state="validateState('time')"
            ></b-form-timepicker>
            <b-form-invalid-feedback v-if="!$v.form.time.required">Time is required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <!-- Type -->
          <b-form-group
            label="Transaction Type"
            label-for="type">
            <b-form-select
              id="type"
              v-model="transactionType">
              <b-form-select-option value="trade">Trade</b-form-select-option>
              <b-form-select-option value="withdrawal">Withdrawal</b-form-select-option>
              <b-form-select-option value="deposit">Deposit</b-form-select-option>
              <b-form-select-option value="fee">Fee (or other)</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Description"
            label-for="description">
            <b-textarea
              id="description"
              v-model="form.description"
            ></b-textarea>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row v-if="transactionType === 'trade' || transactionType === 'deposit'">
        <b-col cols="12">
          <h5>Received <b-icon-arrow-left></b-icon-arrow-left></h5>
          <hr>
        </b-col>
        <b-col>
          <!-- Receieved Symbol -->
          <b-form-group
            label="Symbol"
            label-for="receivedSymbol"
            :state="validateState('receivedSymbol')">
            <b-form-input
              id="receivedSymbol"
              v-model="$v.form.receivedSymbol.$model"
              :state="validateState('receivedSymbol')"
              @keyup.down="incrementCoinIndex()"
              @keyup.up="decrementCoinIndex()"
              @keyup.enter="selectReceived()"
              @focus="queryCoins(null); selectedField = 'received';"
              @input="queryCoins(form.receivedSymbol); $v.form.receivedAmount.$touch; $v.form.receivedSymbol.$touch;"
            ></b-form-input>
            <mini-coin-list
              v-show="selectedField === 'received'"
              v-click-outside="clearField"
              idPrefix="received"
              :coinSuggestions="coinSuggestions"
              :selectedCoinIndex="selectedCoinIndex"
              @coinSelected="selectReceived($event)"
            ></mini-coin-list>
            <b-form-invalid-feedback v-show="!$v.form.receivedSymbol.required">Received symbol required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- Received Amount -->
          <b-form-group
            label="Amount"
            label-for="receivedAmount"
            :state="validateState('receivedAmount')">
            <b-form-input
              id="receivedAmount"
              v-model="$v.form.receivedAmount.$model"
              :state="validateState('receivedAmount')"
              @input="$v.form.receivedAmount.$touch; $v.form.receivedSymbol.$touch; getPriceFor('received');"
            ></b-form-input>
            <b-form-invalid-feedback v-show="!$v.form.receivedAmount.decimal">Valid decimal number required</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.receivedAmount.minValue">Amount must be greater than zero</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.receivedAmount.required">Received amount required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- Received Value -->
          <b-form-group
            label="Value"
            label-for="receivedValue"
            :state="validateState('receivedValue')">
            <b-form-input
              id="receivedValue"
              :placeholder="'USD value est. $' + estimates.received"
              v-model="$v.form.receivedValue.$model"
              :state="validateState('receivedValue')"
              @input="$v.form.receivedValue.$touch; $v.form.receivedSymbol.$touch;"
            ></b-form-input>
            <b-form-invalid-feedback v-show="!$v.form.receivedValue.decimal">Valid decimal number required</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.receivedValue.minValue">Value must be greater than zero</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row v-if="transactionType === 'trade' || transactionType === 'withdrawal'">
        <b-col cols="12">
          <h5>Sent <b-icon-arrow-right></b-icon-arrow-right></h5>
          <hr>
        </b-col>
        <b-col>
          <!-- Sent Symbol -->
          <b-form-group
            label="Symbol"
            label-for="sentSymbol"
            :state="validateState('sentSymbol')">
            <b-form-input
              id="sentSymbol"
              v-model="$v.form.sentSymbol.$model"
              :state="validateState('sentSymbol')"
              @keyup.down="incrementCoinIndex()"
              @keyup.up="decrementCoinIndex()"
              @keyup.enter="selectSent()"
              @focus="queryCoins(null); selectedField = 'sent';"
              @input="queryCoins(form.sentSymbol); $v.form.sentAmount.$touch; $v.form.sentSymbol.$touch;"
            ></b-form-input>
            <mini-coin-list
              v-show="selectedField === 'sent'"
              v-click-outside="clearField"
              idPrefix="sent"
              :coinSuggestions="coinSuggestions"
              :selectedCoinIndex="selectedCoinIndex"
              @coinSelected="selectSent($event)"
            ></mini-coin-list>
            <b-form-invalid-feedback v-show="!$v.form.sentSymbol.required">Sent symbol required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>  
        <b-col>
          <!-- Sent Amount -->
          <b-form-group
            label="Amount"
            label-for="sentAmount"
            :state="validateState('sentAmount')">
            <b-form-input
              id="sentAmount"
              v-model="$v.form.sentAmount.$model"
              :state="validateState('sentAmount')"
              @input="$v.form.sentAmount.$touch; $v.form.sentSymbol.$touch; getPriceFor('sent')"
            ></b-form-input>
            <b-form-invalid-feedback v-show="!$v.form.sentAmount.decimal">Valid decimal number required</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.sentAmount.minValue">Amount must be greater than zero</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.sentAmount.required">Sent amount required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- Sent Value -->
          <b-form-group
            label="Value"
            label-for="sentValue"
            :state="validateState('sentValue')">
            <b-form-input
              id="sentValue"
              :placeholder="'USD value est. $' + estimates.sent"
              v-model="$v.form.sentValue.$model"
              :state="validateState('sentValue')"
              @input="$v.form.sentValue.$touch; $v.form.sentSymbol.$touch;"
            ></b-form-input>
            <b-form-invalid-feedback v-show="!$v.form.sentValue.decimal">Valid decimal number required</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.sentValue.minValue">Value must be greater than zero</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="12">
          <h5>Fee <b-icon-percent></b-icon-percent></h5>
          <hr>
        </b-col>
        <b-col>
          <!-- Fee Symbol -->
          <b-form-group
            label="Symbol"
            label-for="feeSymbol"
            :state="validateState('feeSymbol')">
            <b-form-input
              id="feeSymbol"
              v-model="$v.form.feeSymbol.$model"
              :state="validateState('feeSymbol')"
              @keyup.down="incrementCoinIndex()"
              @keyup.up="decrementCoinIndex()"
              @keyup.enter="selectFee()"
              @focus="queryCoins(null); selectedField = 'fee';"
              @input="queryCoins(form.feeSymbol); $v.form.fee.$touch; $v.form.feeSymbol.$touch;"
            ></b-form-input>
            <mini-coin-list
              v-show="selectedField === 'fee'"
              v-click-outside="clearField"
              idPrefix="fee"
              :coinSuggestions="coinSuggestions"
              :selectedCoinIndex="selectedCoinIndex"
              @coinSelected="selectFee($event)"
            ></mini-coin-list>
            <b-form-invalid-feedback v-show="!$v.form.feeSymbol.required">Fee symbol required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>  
        <b-col>
          <!-- Fee Amount -->
          <b-form-group
            label="Amount"
            label-for="fee"
            :state="validateState('fee')">
            <b-form-input
              id="fee"
              v-model="$v.form.fee.$model"
              :state="validateState('fee')"
              @input="$v.form.fee.$touch; $v.form.feeSymbol.$touch; getPriceFor('fee')"
            ></b-form-input>
            <b-form-invalid-feedback v-show="!$v.form.fee.decimal">Valid decimal number required</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.fee.minValue">Amount must be greater than zero</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.fee.required">Fee required</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <!-- Fee Value -->
          <b-form-group
            label="Value"
            label-for="feeValue"
            :state="validateState('feeValue')">
            <b-form-input
              id="feeValue"
              :placeholder="'USD value est. $' + estimates.fee"
              v-model="$v.form.feeValue.$model"
              :state="validateState('feeValue')"
              @input="$v.form.feeValue.$touch; $v.form.feeSymbol.$touch;"
            ></b-form-input>
            <b-form-invalid-feedback v-show="!$v.form.feeValue.decimal">Valid decimal number required</b-form-invalid-feedback>
            <b-form-invalid-feedback v-show="!$v.form.feeValue.minValue">Value must be greater than zero</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-form-row>
    </form>
  </b-modal>
</template>

<script>
import Decimal from 'decimal.js-light';
import { validationMixin } from "vuelidate";
import { required, decimal, minValue } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex';
import MiniCoinList from '@/components/MiniCoinList.vue'
import Helpers from '@/helpers/ipc-helpers';

export default {
  components: { MiniCoinList },
  mixins: [validationMixin],
  mounted () {
  // console.debug(this.$v);
  },
  props: {
    editTx: Object,
    isEdit: Boolean,
    childIdx: Number
  },
  data () {
    return {
      priceDebouncer: null,
      transactionType: 'trade',
      selectedField: null,
      receivedCoinId: null,
      receivedCoin: null,
      sentCoinId: null,
      sentCoin: null,
      feeCoinId: null,
      feeCoin: null,
      selectedCoinIndex: -1,
      form: {
        date: null,
        time: null,
        receivedSymbol: null,
        receivedAmount: null,
        receivedValue: null,
        sentSymbol: null,
        sentAmount: null,
        sentValue: null,
        fee: null,
        feeSymbol: null,
        feeValue: null,
        description: null
      },
      estimates: {
        fee: 0,
        sent: 0,
        received: 0
      }
    }
  },
  validations () {
    return { ...this.formValidations }
  },
  computed: {
    ...mapState('coins', ['coinSuggestions']),
    timestamp () {
      return new Date(this.form.date + 'T' + this.form.time);
    },
    formValidations () {
      const formValidations = {
        form: {
          date: {
            required
          },
          time: {
            required
          },
          receivedSymbol: {},
          receivedAmount: {
            decimal,
            minValue: minValue(0)
          },
          receivedValue: {
            decimal,
            minValue: minValue(0)
          },
          sentSymbol: {},
          sentAmount: {
            decimal,
            minValue: minValue(0)
          },
          sentValue: {
            decimal,
            minValue: minValue(0)
          },
          feeSymbol: {},
          fee: {
            decimal,
            minValue: minValue(0)
          },
          feeValue: {
            decimal,
            minValue: minValue(0)
          },
        }
      }
      if (this.transactionType === 'trade' || this.transactionType === 'deposit') {
        formValidations.form.receivedSymbol.required = required
        formValidations.form.receivedAmount.required = required
      }

      if (this.transactionType === 'trade' || this.transactionType === 'withdrawal') {
        formValidations.form.sentSymbol.required = required
        formValidations.form.sentAmount.required = required
      }

      if (this.form.fee || this.form.feeSymbol) {
        formValidations.form.feeSymbol.required = required
        formValidations.form.fee.required = required
      }

      return formValidations;
    }
  },
  methods: {
    ...mapActions('transactions', ['createTransaction', 'updateNotFoundCoins']),
    ...mapActions('coins', ['queryCoins']),
    ...mapActions('prices', ['fetchPrice']),
    validateState(name) {
      if (this.$v.form[name]) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      } else {
        return null;
      }
    },
    handleOk (evt) {
      evt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit () {
      if (this.transactionType === 'withdrawal') {
        this.form.receivedSymbol = null
        this.form.receivedAmount = null 
        this.form.receivedValue = null
        this.receivedCoinId = null
        this.receivedCoin = null
      }

      if (this.transactionType === 'deposit') {
        this.form.sentSymbol = null
        this.form.sentAmount = null
        this.form.sentValue = null
        this.sentCoinId = null
        this.sentCoin = null
      }

      if (this.transactionType === 'fee') {
        this.form.receivedSymbol = null
        this.form.receivedAmount = null
        this.form.receivedValue = null
        this.receivedCoinId = null
        this.receivedCoin = null
        this.form.sentSymbol = null
        this.form.sentAmount = null
        this.form.sentValue = null
        this.sentCoinId = null
        this.sentCoin = null
      }

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let txToSend = {
        date: this.timestamp,
        receivedSymbol: this.form.receivedSymbol,
        receivedCoinId: this.receivedCoinId,
        receivedCoin: this.receivedCoin,
        receivedAmount: Decimal(this.form.receivedAmount || 0),
        receivedValue: Decimal(this.form.receivedValue || 0),
        sentSymbol: this.form.sentSymbol,
        sentCoinId: this.sentCoinId,
        sentCoin: this.sentCoin,
        sentValue: Decimal(this.form.sentValue || 0),
        sentAmount: Decimal(this.form.sentAmount || 0),
        fee: Decimal(this.form.fee || 0),
        feeSymbol: this.form.feeSymbol,
        feeCoinId: this.feeCoinId,
        feeCoin: this.feeCoin,
        feeValue: Decimal(this.form.feeValue || 0),
        description: this.form.description
      }

      if (this.editTx && this.isEdit && this.childIdx === -1) {
        txToSend._id = this.editTx._id;
        txToSend.hash = this.editTx.hash;
        txToSend.source = this.editTx.source || {};
        txToSend.children = this.editTx.children || [];
      }

      if (this.editTx && this.isEdit && this.childIdx > -1) {
        txToSend._id = this.editTx.children[this.childIdx]._id;
        txToSend.hash = this.editTx.children[this.childIdx].hash;
        txToSend.source = this.editTx.children[this.childIdx].source || {};
        txToSend.children = this.editTx.children[this.childIdx].children || [];
      }

      if (this.childIdx > -1) {
        const newTxToSend = {...this.editTx}
        newTxToSend.children[this.childIdx] = txToSend;
        txToSend = newTxToSend;
      }
      
      this.createTransaction(txToSend)
        .then(res => {
          this.updateNotFoundCoins()
            .then(() => {
              this.$nextTick(() => {
                if (this.isEdit) {
                  this.$bvModal.hide('editTransaction')
                } else {
                  this.$bvModal.hide('newTransaction')
                }
                this.$parent.refreshTable()
              })
            })
            .catch(console.error)
        })
        .catch(err => console.error(err))
    },
    resetForm () {
      const dateFormat = (date) => {
        const year = date.getFullYear();
        let month = 1 + date.getMonth();
        if (month < 10) {
          month = '0' + month;
        }
        let day = date.getDate();
        if (day < 10) {
          day = '0' + day;
        }
        return `${year}-${month}-${day}`;
      }

      const timeFormat = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) {
          hours = '0' + hours;
        }
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        return `${hours}:${minutes}`;
      }

      this.estimates.fee = 0;
      this.estimates.received = 0;
      this.estimates.sent = 0;
      const editTx = this.childIdx > -1 ? {...this.editTx.children[this.childIdx]} : {...this.editTx};
      this.form = this.isEdit && editTx && editTx._id ? {
        date: dateFormat(editTx.date),
        time: timeFormat(editTx.date),
        receivedSymbol: editTx.receivedSymbol,
        receivedAmount: editTx.receivedAmount > 0 ? editTx.receivedAmount : null,
        receivedValue : editTx.receivedValue > 0 ? editTx.receivedValue : null,
        sentSymbol: editTx.sentSymbol,
        sentAmount: editTx.sentAmount > 0 ? editTx.sentAmount : null,
        sentValue: editTx.sentValue > 0 ? editTx.sentValue : null,
        fee: editTx.fee > 0 ? editTx.fee : null,
        feeSymbol: editTx.feeSymbol,
        feeValue: editTx.feeValue > 0 ? editTx.feeValue : null,
        description: editTx.description,
      } : {
        date: null,
        time: null,
        receivedSymbol: null,
        receivedAmount: null,
        receivedValue : null,
        sentSymbol: null,
        sentAmount: null,
        sentValue: null,
        fee: null,
        feeSymbol: null,
        feeValue: null,
        description: null,
      }
      this.receivedCoinId = null
      this.receivedCoin = null
      this.sentCoinId = null
      this.sentCoin = null
      this.feeCoinId = null
      this.feeCoin = null
      this.transactionType = 'trade'
      if (this.isEdit && editTx && editTx._id) {
        this.receivedCoinId = editTx.receivedCoinId
        this.receivedCoin = editTx.receivedCoin
        this.sentCoinId = editTx.sentCoinId
        this.sentCoin = editTx.sentCoin
        this.feeCoinId = editTx.feeCoinId
        this.feeCoin = editTx.feeCoin
        if (editTx.receivedSymbol && editTx.sentSymbol) {
          // this.transactionType = 'trade';
        } else if (editTx.receivedSymbol)   {
          this.transactionType = 'deposit';
        } else if (editTx.sentSymbol) {  
          this.transactionType = 'withdrawal';
        } else {
          this.transactionType = 'fee';
        }
      }
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    selectReceived (coin) {
      this.select('received', coin);
    },
    selectSent (coin) {
      this.select('sent', coin);
    },
    selectFee (coin) {
      this.select('fee', coin);
    },
    select (type, coin) {
      if (!coin) {
        coin = this.coinSuggestions[this.selectedCoinIndex];
        if (!coin) return;
      }
      this.form[`${type}Symbol`] = coin.symbol;
      this[`${type}CoinId`] = coin._id;
      this[`${type}Coin`] = coin;
      this.clearField();
    },
    clearField () {
      this.queryCoins(null);
      this.selectedField = null;
      this.selectedCoinIndex = -1;
    },
    incrementCoinIndex () {
      if (this.selectedCoinIndex < this.coinSuggestions.length - 1)
        this.selectedCoinIndex++;
    },
    decrementCoinIndex () {
      if (this.selectedCoinIndex > 0)
        this.selectedCoinIndex--;
    },
    getPriceFor (type) {
      if (this.priceDebouncer) {
        clearTimeout(this.priceDebouncer);
      }
      this.priceDebouncer = setTimeout(() => {
        const coinId = this[`${type}CoinId`];
        const amount = type === 'fee' ? this.form.fee : this.form[`${type}Amount`];
        // console.debug(type, coinId, amount);
        if (coinId && amount && amount > 0 && this.timestamp && !coinId.startsWith('stax-fiat')) {
          this.fetchPrice(coinId)
            .then(res => {
              Helpers.priceAt(this.timestamp, coinId)
                .then((price) => {
                  this.estimates[type] = new Decimal(price).mul(amount).toDecimalPlaces(2);
                })
                .catch(console.error)
            })
            .catch(console.error)
        } else if (coinId === 'stax-fiat-usd' || this.form[`${type}Symbol`] === 'USD') {
          this.estimates[type] = new Decimal(amount).toDecimalPlaces(2);
        } else {
          this.estimates[type] = 0;
        }
      }, 500);
    }
  },
  watch: {
    coinSuggestions () {
      const exactMatch = this.coinSuggestions.find(c => this.form[`${this.selectedField}Symbol`] && c.symbol === this.form[`${this.selectedField}Symbol`]);
      if (exactMatch) {
        this.selectedCoinIndex = this.coinSuggestions.indexOf(exactMatch);
        this[`${this.selectedField}Coin`] = exactMatch;
        this[`${this.selectedField}CoinId`] = exactMatch._id;
      } else {
        this.selectedCoinIndex = -1;
        this[`${this.selectedField}Coin`] = null;
        this[`${this.selectedField}CoinId`] = null;
      }
    }
  }
}
</script>