import { TransactionFactory, Transaction } from '../../models/transaction';
import Helpers from '@/helpers/ipc-helpers';
import { DbTable } from "@/helpers/DbTable";
import { TransactionStoreState } from '../interfaces/transaction-store-state';
import { commonMutations, currentYearGetter, dateFilterGetter, filtersMutations, paginationMutations, sortingGetteres, toastActions } from '../mixins';
import { ImportResponse } from "@/services/import-response";
import { CoinstatsDbCoin } from "@/services/coinstats/CoinstatsDbCoin";

const handleImportResponse = (res: ImportResponse, dispatch: any) => {
  const summaryText = `${res.existing} existing transactions, ${res.new} new transactions, and ${res.linked} linked transactions.`;
  if (res.existing > 0) {
    dispatch('dbWarning', `Transactions imported with warnings. ${summaryText}`);
  } else if (res.new === 0 && res.existing === 0 && res.linked === 0) {
    dispatch('dbWarning', 'No new transactions detected.');
  } else {
    const split = summaryText.split(', ');
    split.shift();
    dispatch('dbSuccess', `Transactions imported. ${split.join(', ')}`);
  }
}

export default {
  namespaced: true,
  state: (): TransactionStoreState => ({
    dbReady: false,
    transactions: [],
    selected: [],
    uniqueYears: [],
    uniqueCoins: {},
    linkSuggestions: [],
    balances: [],
    loading: true,
    importing: false,
    txProcessed: 0,
    existingTxs: 0,
    matchingTxs: 0,
    newTxs: 0,
    paginationFields: {
      sortBy: 'date',
      sortDesc: false,
      currentPage: 1,
      perPage: 100,
      noRows: 0
    },
    filters: {
      startDate: null,
      endDate: null,
      symbols: [],
      types: []
    }
  }),
  getters: {
    ...dateFilterGetter(),
    ...sortingGetteres(),
    ...currentYearGetter(),
    uniqueSymbols (state) {
      try {
        return Object.values(state.uniqueCoins).map((c: CoinstatsDbCoin) => c.symbol)
      } catch (e) {
        console.error(e);
        return [];
      }
    }
  },
  actions: {
    ...toastActions(),
    importWallet ({ commit, dispatch, state, rootState }, details: { address: string; service: string; pageIndex: number }) {
      return new Promise((resolve, reject) => {
        // console.debug('details...', details);
        commit('importing');
        Helpers.serviceImport(details.service, rootState.settings.settings.apiKeys[details.service], details.address, details.pageIndex)
          .then((res: ImportResponse) => {
            if (res.new === 0)
              commit('imported');
            handleImportResponse(res, dispatch);
            resolve(res);
          })
          .catch(err => { commit('imported'); dispatch('dbError', err); reject(err); });
      });
    },
    importExchange ({ commit, dispatch, state, rootState }, service: string): Promise<ImportResponse> {
      return dispatch('importWallet', { service });
    },
    importNimiq ({ commit, dispatch, state, rootState }, details: any): Promise<ImportResponse> {
      return new Promise((resolve, reject) => {
        commit('importing')
        Helpers.serviceImport('nimiq', rootState.settings.settings.apiKeys['nimiq'], details.address, details.height)
          .then((res: ImportResponse) => {
            commit('imported');
            handleImportResponse(res, dispatch);
            resolve(res);
          })
          .catch(err => { commit('imported'); dispatch('dbError', err); reject(err); });
      });
    },
    import ({ commit, dispatch }, file: File): Promise<ImportResponse> {
      return new Promise((resolve, reject) => {
        commit('importing');
        file.text()
          .then(csv => {
            Helpers.serviceImport('csv', null, csv)
              .then((res: ImportResponse) => {
                commit('imported');
                handleImportResponse(res, dispatch);
                resolve(res);
              })
              .catch(err => { commit('imported'); dispatch('dbError', err); reject(err); });
          })
          .catch(err => { commit('imported'); dispatch('dbError', err); reject(err); })
      });
    },
    updateNotFoundCoins ({ commit, dispatch }): Promise<void> {
      return Helpers.dbUpdateNotFound();
    },
    initTransactions ({ dispatch }) {
      dispatch('fetchUniqueCoins');
      dispatch('fetchUniqueYears');
    },
    fetchUniqueCoins ({ commit, dispatch }) {
      Helpers.dbFind(DbTable.TRANSACTIONS, {}, { receivedCoin: 1, sentCoin: 1, feeCoin: 1, 'children.receivedCoin': 1, 'children.sentCoin': 1, 'children.feeCoin': 1 })
        .then(docs => {
          // console.debug(docs);
          // const docsWithoutCoins = TODO
          const flattenIt = (d) => {
            const result = [d.receivedCoin, d.sentCoin, ...d.children.receivedCoin, ...d.children.sentCoin].flat();
            // console.debug('received sent... it', result);
            return result;
          }
          const flattenFeeIt = (d) => {
            const result = [d.feeCoin, ...d.children.feeCoin].flat();
            // console.debug('fee... it', result);
            return result;
          }
          const coinsMap = docs.map(d => flattenIt(d)).flat().filter(c => !!c).reduce((prev, curr) => {
            // console.debug('regular...', curr);
            prev[curr._id] = prev[curr._id] || curr;
            prev[curr._id].transactionCount = prev[curr._id].transactionCount || 0;
            prev[curr._id].transactionCount++;
            return prev;
          }, {});
          const feeCoinsMap = docs.map(d => flattenFeeIt(d)).flat().filter(c => !!c).reduce((prev, curr) => {
            // console.debug('in fee', curr);
            prev[curr._id] = prev[curr._id] || curr;
            prev[curr._id].transactionCount = prev[curr._id].transactionCount || 0;
            return prev;
          }, coinsMap);
          // console.debug('feeCoinsMap', feeCoinsMap);
          commit('setUniqueCoins', feeCoinsMap);
          commit('loaded')
        })
        .catch(err => { console.debug('debug fetch unique coins'); console.error(err); dispatch('dbError', err); })
    },
    fetchUniqueYears ({ commit, dispatch }) {
      Helpers.dbFind(DbTable.TRANSACTIONS, {}, { date: 1 })
        .then(docs => {
          const years = [...new Set(docs.map(d => d.date.getFullYear()))];
          commit('setUniqueYears', years);
        })
        .catch(err => dispatch('dbError', err))
    },
    fetchTransactions ({ commit, dispatch, state, getters }, imported = false) {
      commit('loading');
      return new Promise((resolve, reject) => {
        Helpers.dbFind(
          DbTable.TRANSACTIONS,
          { date: getters.dateFilter },
          null,
          state.paginationFields,
          getters.sorting,
          state.filters)
          .then((docs: Transaction[]) => {
            commit('importTransactions', docs);
            if (!state.dbReady) {
              commit('dbReady');
            }
            dispatch('initTransactions');
            commit('loaded');
            resolve(docs);
          })
          .catch(err => {
            dispatch('dbError', err);
            reject(err);
          })
      });
    },
    fetchTransactionCount ({ commit, dispatch, getters, state }) {
      Helpers.dbCount(DbTable.TRANSACTIONS, { date: getters.dateFilter }, state.filters)
        .then(count => {
          if (count !== state.paginationFields.noRows) {
            commit('resetPagination')
          }
          commit('setNoRows', count)
        })
        .catch(err => dispatch('dbError', err))
    },
    fetchBalances ({ commit, dispatch }) {
      Helpers.dbFind(DbTable.TRANSACTIONS, { 'source.name': 'Coinbase Pro Account' }, null, null, { date: 1 })
        .then(docs => commit('setBalances', docs))
        .catch(err => dispatch('dbError', err))
    },
    deleteTransactions ({ commit, dispatch, state }, ids) {
      commit('loading')
      const query: any = {};
      if (ids && ids.length > 0) {
        query._id = { $in: ids };
      }
      Helpers.dbRemove(DbTable.TRANSACTIONS, query, { multi: true }, state.filters)
        .then(numRemoved => {
          dispatch('dbSuccess', `${numRemoved} Transactions removed`);
        })
        .catch(err => {
          dispatch('dbError', err);
        })
    },
    createTransaction ({ commit, dispatch }, transaction: Transaction) {
      commit('loading')
      transaction.children = transaction.children || [];
      // console.debug('tx before db convert', transaction);
      if (transaction._id) {
        Helpers.dbUpdate(DbTable.TRANSACTIONS, { _id: transaction._id }, TransactionFactory.toDB(transaction))
          .then(doc => {
            // console.debug('tx created in db', transaction)
            dispatch('dbSuccess', 'Transaction updated');
          })
          .catch(err => dispatch('dbError', err))
      } else {
        Helpers.dbInsert(DbTable.TRANSACTIONS, TransactionFactory.toDB(transaction))
          .then(doc => {
            // console.debug('tx created in db', transaction)
            dispatch('dbSuccess', 'Transaction created');
          })
          .catch(err => dispatch('dbError', err))
      }
    },
    processLinkSuggestion ({ commit, dispatch }, payload: any) {
      const  { suggestion, isAll } = payload;
      const ids = [suggestion.sent._id, suggestion.received._id];
      return new Promise((resolve, reject) => {
        dispatch('linkTransactions', { ids, isAll: isAll || false })
          .then(numRemoved => {
            commit('removeLinkSuggestion', suggestion);
            resolve(suggestion);
          })
          .catch(err => {
            dispatch('dbError', err);
            reject(err);
          })
      })
    },
    linkAllSuggestions ({ commit, dispatch, state }, suggestion: any) {
      return new Promise((resolve, reject) => {
        Promise.all(state.linkSuggestions.map(suggestion => {
          return dispatch('processLinkSuggestion', { suggestion, isAll: true })
        }))
          .then(res => {
            dispatch('dbSuccess', 'Transactions linked!')
            resolve(res);
          })
          .catch(err => { dispatch('dbError', err); reject(err); });
      });
    },
    linkTransactions ({ commit, dispatch }, payload) {
      const { ids, isAll } = payload;
      const parentId = ids.shift();
      commit('loading');
      return new Promise((resolve, reject) => {
        Helpers.dbFindById(DbTable.TRANSACTIONS, parentId)
        .then(parentDoc => {
          Helpers.dbFind(DbTable.TRANSACTIONS, { _id: { $in: ids }})
            .then((children: Transaction[]) => {
              dispatch('addChildren', { id: parentId, children: [ ...children, ...children.map(child => child.children)].flat().map(TransactionFactory.fromDB).map(TransactionFactory.toDB) })
                .then((addChildrenRes) => {
                // console.debug('linked children...', addChildrenRes);
                  Helpers.dbRemove(DbTable.TRANSACTIONS, { _id: { $in: children.map(c => c._id)}}, { multi: true })
                    .then(numRemoved => {
                      if (!isAll) {
                        dispatch('dbSuccess', 'Transactions linked');
                      }
                      resolve(numRemoved);
                    })
                    .catch(childRemovederr => { dispatch('dbError', childRemovederr); reject(childRemovederr); })
                })
                .catch((addChildrenErr) => { dispatch('dbError', addChildrenErr); reject(addChildrenErr); });
            })
            .catch(childErr => { dispatch('dbError', childErr); reject(childErr); })
        })
        .catch(err => { dispatch('dbError', err); reject(err); })
      });
    },
    unlinkTransactions ({ commit, dispatch }, payload) {
      const { ids } = payload;
      const parentId = ids.shift();
      const childId = ids.shift();
      return new Promise((resolve, reject) => {
        Helpers.dbFindById(DbTable.TRANSACTIONS, parentId)
          .then(parentDoc => {
            const children = parentDoc.children;
            const childDoc = children.find(d => d._id === childId);
            if (!childDoc) {
              const err = 'Linked Transaction not found!';
              dispatch('dbError', err);
              reject(err);
            } else {
              const childIndex = children.indexOf(childDoc);
              // console.debug('children before splice', children);
              children.splice(childIndex, 1);
              // console.debug('children after splice', children);
              Helpers.dbUpdate(DbTable.TRANSACTIONS, { _id: parentId }, { $set: { children }})
                .then((updated) => {
                  // console.debug('unlink update res...', updated);
                  Helpers.dbInsert(DbTable.TRANSACTIONS, childDoc)
                    .then(res => {
                      // console.debug('childDoc updated...', res);
                      dispatch('dbSuccess', 'Transaction unlinked!');
                      resolve(TransactionFactory.fromDB(parentDoc));
                    })
                    .catch(err => { dispatch('dbError', err); reject(err); })
                })
                .catch(err => { dispatch('dbError', err); reject(err); })
            }
          })
      });
    },
    fetchLinkSuggestions ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        Helpers.linkTheSuggestions()
          .then(suggestions => {
            const converted = suggestions.map(s => {
              return {
                sent: TransactionFactory.fromDB(s.sent),
                received: TransactionFactory.fromDB(s.received)
              }
            });
            commit('setLinkSuggestions', converted);
            resolve(converted);
          })
          .catch(err => { dispatch('dbError', err); reject(err); });
      });
    },
    addChildren ({ commit, dispatch }, payload): Promise<any> {
      return Helpers.addChildren(payload);
    }
  },
  mutations: {
    ...commonMutations(),
    ...paginationMutations(),
    ...filtersMutations(),
    importing (state: TransactionStoreState) {
      state.importing = true;
    },
    imported (state: TransactionStoreState) {
      state.importing = false;
    },
    importTransactions (state: TransactionStoreState, transactions: any[]) {
      state.transactions = transactions.map(t => TransactionFactory.fromDB(t));
    },
    setUniqueCoins (state: TransactionStoreState, coinsMap: { [key: string]: CoinstatsDbCoin }) {
      state.uniqueCoins = coinsMap;
    },
    // setTransactionCoinCounts (state: TransactionStoreState, coins: CoinstatsDbCoin[]) {

    // },
    setBalances (state: TransactionStoreState, balances: any) {
      state.balances = balances;
    },
    setChildren (state: TransactionStoreState, payload: any) {
      const tx = state.transactions.find(t => t._id === payload.id);
      if (!tx) {
        return;
      }
      if (payload.children) {
        tx.children = payload.children;
      } else {
        tx.children = [];
      }
      const txIndex = state.transactions.indexOf(tx);
      state.transactions.splice(txIndex, 1, tx);
    },
    setUniqueYears (state: TransactionStoreState, years: string[]) {
      state.uniqueYears = years.sort()
    },
    setExistingTxs (state: TransactionStoreState, num: number) {
      state.matchingTxs = num
    },
    setNewTxs (state: TransactionStoreState, num: number) {
      state.newTxs = num
    },
    resetExistingTxs (state: TransactionStoreState, num: number) {
      state.matchingTxs = 0
    },
    resetNewTxs (state: TransactionStoreState, num: number) {
      state.newTxs = 0
    },
    setSelectedTypes (state: TransactionStoreState, selected: string[]) {
      state.filters.types = selected
    },
    deselectType (state: TransactionStoreState, type: string) {
      const existingType = state.filters.types.find(t => type === t);
      if (existingType) {
        state.filters.types.splice(state.filters.types.indexOf(existingType), 1);
      }
    },
    setLinkSuggestions (state: TransactionStoreState, suggestions: any[]) {
      state.linkSuggestions = suggestions;
    },
    removeLinkSuggestion (state: TransactionStoreState, suggestion: any) {
      const existingSuggestion = state.linkSuggestions.find(s => s.sent._id === suggestion.sent._id && s.received._id === suggestion.received._id);
    // console.debug('found suggestions....', existingSuggestion);
      if (existingSuggestion) {
        state.linkSuggestions.splice(state.linkSuggestions.indexOf(existingSuggestion), 1);
      }
    },
    resetPagination (state: TransactionStoreState) {
      state.paginationFields.currentPage = 1;
    },
    setSelected (state: TransactionStoreState, selected: Transaction[]) {
      state.selected = selected
    }
  }
}
