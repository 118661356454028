<template>
  <div>
    <b-badge class="ml-1 mr-1 mb-1" v-for="sym in symbols" :key="sym">{{ sym }} <b-icon-x-circle-fill style="cursor: pointer;" @click="deselectSym(sym);"></b-icon-x-circle-fill></b-badge>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    table: String
  },
  mounted () {
  // console.debug('badges mounted', this);
  },
  computed: {
    ...mapState({
      reportsSymbols: state => state.reports.filters.symbols,
      transactionsSymbols: state => state.transactions.filters.symbols
    }),
    symbols () {
      return this[`${this.table}Symbols`];
    }
  },
  methods: {
    deselectSym(sym) {
      this.$store.commit(`${this.table}/deselectSymbol`, sym);
    }
  }
}
</script>