import { Transaction } from "@/models/transaction";
import { ApiCredentials, BaseApi, BaseApiService, OauthCredentials, TokenCredentials } from "../base-api";
import env from "../env";
import crypto from 'crypto'
import axios, { AxiosError, AxiosResponse } from "axios";
import Decimal from "decimal.js-light";
import md5 from "blueimp-md5";

export default class KucoinApi extends BaseApiService implements BaseApi {
  static API_VERSION = 'v1'
  static API_URL = env.KUCOIN_API_URL;
  static API_KEY_VERSION = 2
  timeout = 300;

  constructor(credentials: ApiCredentials | OauthCredentials | TokenCredentials) {
    super(credentials)
  }

  private signature(timestamp, method, path, body) {
    const message = `${timestamp}${method}${path}${body}`;
    return crypto.createHmac('sha256', this._credentials.secret).update(message).digest('base64');
  }

  private signPassphrase() {
    return crypto.createHmac('sha256', this._credentials.secret).update(this._credentials.passphrase).digest('base64');
  }

  headers(timestamp, method, path, body) {
    return {
      'KC-API-SIGN': this.signature(timestamp, method, path, body),
      'KC-API-TIMESTAMP': timestamp,
      'KC-API-KEY': this._credentials.key,
      'KC-API-PASSPHRASE': this.signPassphrase(),
      'KC-API-KEY-VERSION': KucoinApi.API_KEY_VERSION
    }
  }

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAccounts()
        .then(res => resolve(true))
        .catch(err => reject(err));
    });
  }

  async fetchTransactions(pagination?: { [account: string]: KucoinPagination }): Promise<Transaction[]> {
    const accounts = await this.getAccounts();
    let result: KucoinTransaction[] = [];
    for (const account of accounts) {
      const pages: KucoinPagination = pagination && pagination[account.id] ? pagination[account.id] : {
        startAt: null,
        endAt: null,
        currentPage: 1,
        pageSize: 500
      };
      const res = await this.getLedgers(pages, account);
      result = result.concat(res);
    }
    return result;
  }

  getLedgers(pagination: KucoinPagination, account: KucoinAccount): Promise<KucoinTransaction[]> {
    return new Promise((resolve, reject) => {
      const url = new URL(KucoinApi.API_URL);
      if (pagination) {
        Object.keys(pagination).forEach(k => {
          if (pagination[k]) {
            url.searchParams.append(k, pagination[k]);
          }
        })
      }
      const path = `/api/${KucoinApi.API_VERSION}/accounts/${account.id}/ledgers${url.search}`;
      this._service.get(path, {
        headers: this.headers(Date.now(), 'GET', path, '')
      })
        .then((res: AxiosResponse<KucoinApiLedgerResponse>) => {
          let txs = res.data.data.items.map(t => new KucoinTransaction(t, account))
          txs = txs.filter(t => {
            if (t._tx.bizType === 'Transfer' && !t._tx.context) {
              return false;
            } else {
              return t;
            }
          })
          resolve(txs);
        })
        .catch((err: AxiosError) => {
          // console.debug(err);
          if (err.response && err.response.data) {
            reject(err.response.data.msg)
          } else if (err.message) {
            reject(err.message)
          } else {
            reject(err)
          }
        })
    })
  }

  getAccounts(): Promise<KucoinAccount[]> {
    const path = `/api/${KucoinApi.API_VERSION}/accounts`;
    return new Promise((resolve, reject) => {
      this._service.get(path, {
        headers: this.headers(Date.now(), 'GET', path, '')
      })
        .then((res: AxiosResponse<KucoinAccountResponse>) => {
          // console.debug('accounts response...', res.data);
          resolve(res.data.data);
          // resolve(res.data.data.filter(a => a.type !== 'margin'));
        })
        .catch((err: AxiosError|any) => {
          if (err.response && err.response.data) {
            reject(err.response.data.msg)
          } else if (err.message) {
            reject(err.message)
          } else {
            reject(err)
          }
        })
    });
  }

}

export interface KucoinPagination {
  startAt: number;
  endAt: number;
  pageSize: number;
  currentPage: number;
}

export interface KucoinTradeContext {
  symbol: string;
  orderId: string;
  tradeId: string;
}

export interface KucoinTrsfContext {
  orderId: string;
  txId: string;
}

export interface KucoinApiLedgerResponse {
  code: string;
  data: {
    currentPage: number;
    pageSize: number;
    totalNum: number;
    totalPage: number;
    items: KucoinApiLedgerEntry[];
  };
}

export interface KucoinApiLedgerEntry {
  accountId: string;
  id: string;
  currency: string;
  amount: string;
  fee: string;
  balance: string;
  accountType: string;
  bizType: string;
  direction: string;
  createdAt: number;
  context: string;
}

export interface KucoinAccount {
  id: string;
  currency: string;
  type: string;
  balance: string;
  available: string;
  holds: string;
}

export interface KucoinAccountResponse {
  code: string;
  data: KucoinAccount[];
}

export class KucoinTransaction implements Transaction {
  _tx: KucoinApiLedgerEntry;

  constructor(tx: KucoinApiLedgerEntry, account: KucoinAccount) {
    this._tx = tx;
    this._tx.accountId = account.id;
  }

  get date(): Date { return new Date(this._tx.createdAt); }

  get isTrade(): boolean {
    return !!this.tradeContext && (!!this.tradeContext.orderId || !!(this.tradeContext as any).order_id)
  }

  get isTrsf(): boolean {
    return !!this.trsfContext && !!this.trsfContext.txId;
  }

  get receivedSymbol(): string {
    if (this._tx.direction === 'in') {
      return this._tx.currency;
    } else {
      return null;
    }
  }
  get receivedAmount(): Decimal {
    if (this._tx.direction === 'in') {
      return new Decimal(this._tx.amount);
    } else {
      return new Decimal(0);
    }
  }

  get sentSymbol(): string {
    if (this._tx.direction === 'out') {
      return this._tx.currency;
    } else {
      return null;
    }
  }

  get sentAmount(): Decimal {
    if (this._tx.direction === 'out') {
      return new Decimal(this._tx.amount);
    } else {
      return new Decimal(0);
    }
  }

  get price(): Decimal { return new Decimal(0); }
  get fee(): Decimal { return new Decimal(this._tx.fee); }
  get feeSymbol(): string { return this._tx.currency; }
  get description(): string { return `KuCoin ${this._tx.accountType} acct. - ${this._tx.bizType} - ${this._tx.currency}` }
  get hash(): string {
    const h = this.trsfContext && this.trsfContext.txId ? this.trsfContext.txId.replace('@0', '') : null;
    if (h && h.startsWith('0x')) {
      return h.substring(2, h.length);
    } else {
      return h;
    }
  }
  get source(): any { return { name: 'KuCoin', ...this._tx }; }
  get _id(): string { return md5(JSON.stringify(this.source)); }
  children: Transaction[] = [];

  get trsfContext(): KucoinTrsfContext {
    if (!this._tx.context) return null;
    try {
      const parsed = JSON.parse(this._tx.context);
      return parsed as KucoinTrsfContext;
    } catch (e) {
      // console.debug(e);
      return null;
    }
  }

  get tradeContext(): KucoinTradeContext {
    if (!this._tx.context) return null;
    try {
      const parsed = JSON.parse(this._tx.context);
      if (parsed && parsed.order_id) {
        return {
          symbol: parsed.symbol,
          orderId: parsed.order_id,
          tradeId: parsed.trade_id
        }
      } else if (parsed) {
        return parsed as KucoinTradeContext;
      } else {
        return null;
      }
    } catch (e) {
      // console.error(e, this._tx.context); // bad json
      return null;
    }
  }

  get asTransaction(): Transaction {
    return {
      _id: this._id,
      date: this.date,
      receivedSymbol: this.receivedSymbol,
      receivedAmount: this.receivedAmount,
      sentSymbol: this.sentSymbol,
      sentAmount: this.sentAmount,
      fee: this.fee,
      feeSymbol: this.feeSymbol,
      price: this.price,
      source: this.source,
      description: this.description,
      children: this.children,
      hash: this.hash
    }
  }
}