import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import EtherscanImport from "../etherscan/etherscan-import";
import { AvalancheApi } from "./avalanche-api";


export class AvalancheImport extends EtherscanImport {
  declare api: AvalancheApi;
  name = 'Avalanche';

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials, address?: string, startBlock = 0) {
    super(creds, address, startBlock, 'Avalanche');
  }
}