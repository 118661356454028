import { DbTable } from "@/helpers/DbTable";
import SymbolTaxDb from "@/services/db/db";
import { processFilters } from "./processFilters";


export function dbRemove(table: string, payload: any, options: any, filters?: any): any {
  payload = payload || {};
  // console.debug(filters);
  if (filters) {
    processFilters(filters, table as DbTable, payload);
  }
  // console.debug('dbRemove payload', payload, filters);
  return new Promise((resolve, reject) => {
    SymbolTaxDb.db[table].remove(payload, options || {}, function (err, numRemoved) {
      if (err) {
        // console.debug('err', err);
        reject(err);
      } else {
        // console.debug('numRemoved', numRemoved);
        resolve(numRemoved);
      }
    });
  });
}
