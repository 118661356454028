import { DbTable } from "@/helpers/DbTable";
import { dbUpdate } from './dbUpdate';
import { dbFindById } from './dbFindById';
import { TransactionFactory } from "@/models/transaction";


export const addChildren = (payload: any): Promise<any> => {
  return Promise.all(payload.children.map((child: any) => {
    return new Promise((resolve, reject) => {
      dbFindById(DbTable.TRANSACTIONS, payload.id)
        .then(found => {
          const foundChild = found.children.find(fc => fc._id === child._id);
          if (found && foundChild) {
            // console.warn('child already there...', foundChild._id);
            resolve(null);
          } else if (found && child._id !== found._id) {
            dbUpdate(DbTable.TRANSACTIONS, { _id: payload.id }, { $push: { children: child } }, null)
              .then(docReplaced => {
                resolve(docReplaced);
              })
              .catch(updateErr => {
                reject(updateErr);
              });
          } else {
          // console.debug('already exists as base transaction ', payload.id);
            resolve(null);
          }
        })
        .catch(docFinderr => {
          reject(docFinderr);
        });
    });
  }));
};
