

































import { ReportStoreState } from '@/store/interfaces/report-store-state';
import { mapActions, mapGetters, mapState } from 'vuex';
import SymbolBadges from '@/components/shared/SymbolBadges.vue';

export default {
  components: {
    SymbolBadges
  },
  mounted () {
    this.calcReportsTotals();
  },
  computed: {
    ...mapGetters('reports', ['currentYear']),
    ...mapState('reports', {
      symbolTotals: (state: ReportStoreState) => state.symbolTotals
    }),
    ...mapGetters(['portfolioCoins']),
    coins () {
      return this.portfolioCoins;
    },
    totalProfit () {
      return this.symbolTotals['all'] ? this.symbolTotals['all'].profit.toFixed(2): '--';
    },
    totalProceeds () {
      return this.symbolTotals['all'] ? this.symbolTotals['all'].proceeds.toFixed(2): '--';
    },
    totalCost () {
      return this.symbolTotals['all'] ? this.symbolTotals['all'].cost.toFixed(2): '--';
    }
  },
  methods: {
    ...mapActions('reports', ['calcReportsTotals']),
    proceedsFor(sym) {
      return this.symbolTotals[sym] ? this.symbolTotals[sym].proceeds.toFixed(2): '--';
    },
    costFor(sym) {
      return this.symbolTotals[sym] ? this.symbolTotals[sym].cost.toFixed(2): '--';
    },
    profitFor(sym) {
      return this.symbolTotals[sym] ? this.symbolTotals[sym].profit.toFixed(2): '--';
    },
    selectSym(symbol) {
      const syms = symbol === 'all' ? [] : [symbol];
      this.$store.commit('reports/setSymbolFilters', syms);
    }
  }
}
