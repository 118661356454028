import { Transaction } from "@/models/transaction";
import Decimal from "decimal.js-light";
import { CoinbaseApiAccount } from "./coinbase-api-account";

export interface CoinbaseMoneyHash {
  amount: string;
  currency: string;
}

export enum CoinbaseTransactionType {
  SEND = 'send', // deposit/withdrawal - to is present for withdrawal (negative amount), from is present for deposits 
  BUY = 'buy', // buying crypto with base currency
  TRADE = 'trade', // crypto to crypto trades
  SELL = 'sell', // selling crypto with base currency
  REWARD = 'staking_reward',
  EXCHANGE_WITHDRAWAL = 'exchange_withdrawal', // pro transfer
  EXCHANGE_DEPOSIT = 'exchange_deposit', // pro transfer
  ADVANCED_TRADE_FILL = 'advanced_trade_fill' // 
}

export interface CoinbaseApiResource {
  id: string;
  resource: string;
  resource_path: string;
}

export interface CoinbaseApiPagination {
  ending_before: string;
  starting_after: string;
  previous_ending_before: string;
  next_starting_after: string;
  limit: number;
  order: string;
  previous_uri: string;
  next_uri: string;
}

export interface CoinbaseApiListResponse {
  pagination: CoinbaseApiPagination;
  data: CoinbaseApiTransactionResource[] | CoinbaseApiAccount[];
}

export interface CoinbaseApiTransactionDetails {
  title: string;
  subtitle: string;
  header: string;
  health: string;
}

export interface CoinbaseApiTransactionNetwork {
  status: string;
  status_description: string;
  hash: string;
  transaction_url: string;
  transaction_fee: CoinbaseMoneyHash;
  transaction_amount: CoinbaseMoneyHash;
  confirmations: number;
}

export interface CoinbaseApiTransactionAddress {
  resource: string;
  address: string;
  currency: string;
  address_info: {
    address: string;
  };
  address_url: string;
}

export interface CoinbaseApiAdvancedTradeFill {
  fill_price: string;
  product_id: string;
  order_id: string;
  commission: string;
  order_side: string;
}

export interface CoinbaseApiTransactionResource extends CoinbaseApiResource {
  type: CoinbaseTransactionType;
  status: string;
  amount: CoinbaseMoneyHash;
  native_amount: CoinbaseMoneyHash;
  description: string;
  created_at: string;
  updated_at: string;
  details: CoinbaseApiTransactionDetails;
  network?: CoinbaseApiTransactionNetwork;
  to?: CoinbaseApiTransactionAddress;
  from?: CoinbaseApiTransactionAddress;
  address?: CoinbaseApiTransactionAddress;
  application?: CoinbaseApiResource;
  trade?: CoinbaseApiResource;
  advanced_trade_fill?: CoinbaseApiAdvancedTradeFill;
  account_id: string;
}

export interface CoinbaseApiTradeResource extends CoinbaseApiTransactionResource {
  trade: CoinbaseApiResource;
}

export interface CoinbaseApiBuyResource extends CoinbaseApiTransactionResource {
  buy: CoinbaseApiResource;
}

export interface CoinbaseApiSellResource extends CoinbaseApiTransactionResource {
  sell: CoinbaseApiResource;
}

export interface CoinbaseApiAdvancedTradeFillResource extends CoinbaseApiTransactionResource {
  advanced_trade_fill: CoinbaseApiAdvancedTradeFill;
}

export class CoinbaseTransactionResource implements Transaction {
  _tx: CoinbaseApiTransactionResource;
  children: Transaction[] = [];
  constructor(tx: CoinbaseApiTransactionResource) {
    this._tx = tx;
  }
  get date(): Date {
    return new Date(this._tx.created_at)
  }
  get symobl(): string {
    return this._tx.amount.currency;
  }
  get amount(): Decimal {
    return new Decimal(this._tx.amount.amount);
  }
  get nativeAmount(): Decimal {
    return new Decimal(this._tx.native_amount.amount).abs();
  }
  get receivedValue(): Decimal {
    if (this.nativeAmount.lt(0)) {
      return new Decimal(0);
    } else {
      return this.nativeAmount;
    }
  }
  get receivedSymbol(): string {
    if (this.amount.gt(0)) {
      return this._tx.amount.currency;
    } else {
      return null;
    }
  }
  get receivedAmount(): Decimal {
    if (this.amount.gt(0)) {
      return this.amount;
    } else {
      return new Decimal(0);
    }
  }
  get sentSymbol(): string {
    if (this.amount.lt(0)) {
      return this._tx.amount.currency;
    } else {
      return null;
    }
  }
  get sentAmount(): Decimal {
    if (this.amount.lt(0)) {
      return this.amount.abs();
    } else {
      return new Decimal(0);
    }
  }
  get sentValue(): Decimal {
    if (this.nativeAmount.lt(0)) {
      return this.nativeAmount;
    } else {
      return new Decimal(0);
    }
  }
  get price(): Decimal {
    return null;
  }
  get fee(): Decimal {
    return new Decimal(0);
  }
  get feeSymbol(): string {
    return null;
  }
  get description(): string {
    return `${this._tx.details.title} ${this._tx.details.subtitle}`;
  }
  get source(): any {
    return {
      name: 'Coinbase',
      ...this._tx
    }
  }
  get _id(): string {
    return this._tx.id;
  }
  get hash(): string {
    return this._tx.network && this._tx.network.hash ? this._tx.network.hash : null;
  }

  get order_id(): string {
    let oidKey = 'id';
    if (this._tx.type === CoinbaseTransactionType.ADVANCED_TRADE_FILL) {
      oidKey = 'order_id';
    }
    return this._tx[this._tx.type] ? this._tx[this._tx.type][oidKey] : null;
  }

}