



















































































































































































































import { Transaction } from '@/models/transaction';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TransactionForm from '@/components/transactions/TransactionForm.vue';
import Decimal from 'decimal.js-light';
import SymbolBadges from '@/components/shared/SymbolBadges.vue';
import { TransactionStoreState } from '@/store/interfaces/transaction-store-state';
import TransactionsTable from '@/components/transactions/TransactionsTable.vue';
import { ImportResponse } from '@/services/import-response';
import Helpers from '@/helpers/ipc-helpers';
// import Linker from '@/components/Linker.vue';

export default {
  components: { TransactionForm, SymbolBadges, TransactionsTable },
  data () {
    return {
      showSuggestions: false,
      transactionsImportFile: null,
      walletAddress: null,
      walletService: 'etherscan',
      selectedExchange: null,
      isBusy: false,
      importIndex: 0
    }
  },
  mounted () {
    this.fetchLinkSuggestions()
      .then((res) => console.debug(res))
      .catch(err => console.error(err))
  },
  computed: {
    ...mapState('transactions', {
      noRows: (state: TransactionStoreState) => state.paginationFields.noRows,
      transactions: (state: TransactionStoreState) => state.transactions,
      selected: (state: TransactionStoreState) => state.selected,
      loading: (state: TransactionStoreState) => state.loading,
      importing: (state: TransactionStoreState) => state.importing,
      dbReady: (state: TransactionStoreState) => state.dbReady,
      uniqueYears: (state: TransactionStoreState) => state.uniqueYears,
      linkSuggestions: (state: TransactionStoreState) => state.linkSuggestions,
    }),
    ...mapState({
      ready: (state: any) => state.loadingInfo === 'loaded'
    }),
    ...mapGetters('transactions', ['currentYear', 'uniqueSymbols']),
    ...mapGetters(['isWebApp']),
    validWallet () {
      return this.walletAddress ? Boolean(
        this.walletService === 'etherscan' ||
        this.walletService === 'avalanche' ||
        this.walletService === 'fantom' ?
        this.walletAddress.trim().match(/0x[a-fA-F0-9]{40}/) :
        (this.walletAddress.startsWith('NQ') &&
         this.walletAddress.length === 44 &&
         this.walletAddress.split(' ').length === 9) // ||
        //  (this.walletService === 'harmony' && // TODO
        //  this.walletAddress.startsWith('one')))
       ) : null
    },
    linkSuggestionCount () {
      return this.linkSuggestions ? this.linkSuggestions.length : 0;
    },
    selectedSymbols: {
      get () {
        return this.$store.state.transactions.filters.symbols
      },
      set (val) {
        this.$store.commit('transactions/setSymbolFilters', val)
      }
    },
    selectedTypes: {
      get () {
        return this.$store.state.transactions.filters.types
      },
      set (val) {
        this.$store.commit('transactions/setSelectedTypes', val)
      }
    },
    currentPage: {
      get () {
        return this.$store.state.transactions.paginationFields.currentPage
      },
      set (val) {
        this.$store.commit('transactions/setCurrentPage', val)
      }
    },
    perPage: {
      get () {
        return this.$store.state.transactions.paginationFields.perPage
      },
      set (val) {
        this.$store.commit('transactions/setPerPage', val)
      }
    },
    startDate: {
      get () {
        return this.$store.state.transactions.filters.startDate
      },
      set (val) {
        this.$store.commit('transactions/setStartDate', val)
      }
    },
    endDate: {
      get () {
        return this.$store.state.transactions.filters.endDate
      },
      set (val) {
        this.$store.commit('transactions/setEndDate', val)
      }
    },
    walletPlaceholder () {
      const map = {
        'etherscan': '0xabcdefg...',
        'avalanche': '0xabcdefg...',
        'fantom': '0xabcdefg...',
        'nimiq': 'NQXX XXXX...',
        'harmony': 'one1wjsz5jrvs...' // TODO
      }
      return map[this.walletService];
    }
  },
  methods: {
    ...mapActions('transactions', [
      'import',
      'importWallet',
      'deleteTransactions',
      'fetchLinkSuggestions',
      'processLinkSuggestion',
      'linkAllSuggestions',
      'importExchange',
      'importNimiq',
      'updateNotFoundCoins'
    ]),
    ...mapActions(['dbError']),
    handleImport (evt) {
    // console.debug(evt)
    },
    openLink (url) {
      Helpers.openLink(url);
    },
    async handleOk (evt) {
      // console.debug(evt)
      let response: ImportResponse = { new: 1, existing: 1, linked: 1};
      let trys = 10;
      const pageIndex = 0;
      while (response.new > 0 && trys > 0) {
        if (this.importIndex === 0 && this.walletService === 'nimiq') {
          response = await this.importNimiq({ address: this.walletAddress, height: 0 });
        } else if (this.importIndex === 0) {
          response = await this.importWallet({ address: this.walletAddress, service: this.walletService, pageIndex });
        } else if (this.importIndex === 2) {
          if (!this.transactionsImportFile) break;
          await this.import(this.transactionsImportFile);
          response.new = 0;
          response.existing = 0;
          response.linked = 0;
        } else if (this.importIndex === 1) {
          if (!this.selectedExchange) break;
          response = await this.importExchange(this.selectedExchange);
        }
        trys--;
        // pageIndex++;
      }
      try {
        await this.updateNotFoundCoins();
      } catch(e) {
        console.error(e);
        this.dbError(e);
      }
      this.refreshTable();
    },
    async deleteAll (evt) {
      // this.isBusy = true
      try {
        const result = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete ${this.selected.length === 0 ? `${this.noRows}` : this.selected.length + ' selected'} transactions.`, {
          title: 'Are you sure?',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        if (!result) { throw new Error('Delete cancelled'); }
        // console.debug('seleected????', this.selected);
        await this.deleteTransactions(this.selected.map(t => t._id))
        this.refreshTable();
      } catch {
        // this.isBusy = false
      }
    },
    linkSuggested (suggestion) {
      this.processLinkSuggestion({ suggestion })
        .then(res => { this.refreshTable(); })
        .catch(err => console.error(err))
    },
    linkAll () {
      this.linkAllSuggestions()
        .then(res => { this.refreshTable(); })
        .catch(err => console.error(err))
    },
    deselectType(type: string) {
      this.$store.commit('transactions/deselectType', type);
    },
    refreshTable() {
      this.$refs.transactionTableComponent.refreshTable();
    }
  }
}
