import { DbTable } from "@/helpers/DbTable";
import { Transaction } from "@/models/transaction";
import SymbolTaxDb from "@/services/db/db";
import { CoinstatsDbCoin } from "../coinstats/CoinstatsDbCoin";
import { processFilters } from "./processFilters";

const setCoinIds = (tx: Transaction): Promise<void> => {
  return new Promise((resolve, reject) => {
    const coinIds = [tx.receivedCoinId, tx.sentCoinId, tx.feeCoinId].filter(c => !!c);
    const coinSymbols = [tx.feeSymbol, tx.sentSymbol, tx.receivedSymbol].filter(c => !!c); //.map(s => new RegExp(s, 'i'));
    for (const child of (tx.children || [])) {
      if (child.receivedCoinId)
        coinIds.push(child.receivedCoinId);
      if (child.receivedSymbol)
        coinSymbols.push(child.receivedSymbol);
      if (child.sentCoinId)
        coinIds.push(child.sentCoinId);
      if (child.sentSymbol)
        coinSymbols.push(child.sentSymbol);
      if (child.feeCoinId)
        coinIds.push(child.feeCoinId);
      if (child.feeSymbol)
        coinSymbols.push(child.feeSymbol);
    }

    SymbolTaxDb.db.coins.find({ $or: [{ _id: { $in: coinIds } }, { symbol: { $in: coinSymbols } }] }).sort({ rank: 1 }).exec((err, coins: CoinstatsDbCoin[]) => {
      if (err) {
        reject(err)
      } else {
        updateCoins(tx, coins);
        for (const child of (tx.children || [])) {
          updateCoins(child, coins);
        }
        resolve();
      }
    })
  })
}

const updateCoins = (tx: Transaction, coins: CoinstatsDbCoin[]) => {
  // tx.feeCoin = coins.find(c => tx.feeCoinId && c._id === tx.feeCoinId);
  // tx.receivedCoin = coins.find(c => tx.receivedCoinId && c._id === tx.receivedCoinId);
  // tx.sentCoin = coins.find(c => tx.sentCoinId && c._id === tx.sentCoinId);
  // if (!tx.feeCoin && tx.feeSymbol) {
  //   tx.feeCoin = coins.find(c => c.symbol.toLocaleLowerCase() === tx.feeSymbol.toLocaleLowerCase());
  // }
  // if (!tx.receivedCoin && tx.receivedSymbol) {
  //   tx.receivedCoin = coins.find(c => c.symbol.toLocaleLowerCase() === tx.receivedSymbol.toLocaleLowerCase());
  // }
  // if (!tx.sentCoin && tx.sentSymbol) {
  //   tx.sentCoin = coins.find(c => c.symbol.toLocaleLowerCase() === tx.sentSymbol.toLocaleLowerCase());
  // }

  // if (tx.source && tx.source.name && tx.source.name.startsWith('Etherscan')) {
  // }
  // if (tx.source && tx.source.name && tx.source.name.startsWith('Fantom')) {
  // }
  // if (tx.source && tx.source.name && tx.source.name.startsWith('Avalanche')) {
  // }
}

export function dbFind(options: any, table: DbTable, payload: any, pagination: any, sorting: any, filters?: any): Promise<any> {
  return new Promise((resolve, reject) => {
    if (filters) {
      processFilters(filters, table, payload);
    }
    try {
      let find = options ? SymbolTaxDb.db[table].find(payload, options) : SymbolTaxDb.db[table].find(payload);
      if (pagination) {
        find = find.limit(pagination.perPage).skip((pagination.currentPage - 1) * pagination.perPage);
      }
      if (sorting) {
        find = find.sort(sorting);
      }
      find.exec((err, docs) => {
        if (err) {
          reject(err);
        } else {
          resolve(docs);
          // if (table === DbTable.TRANSACTIONS) {
          //   Promise.all(docs.map((tx: Transaction) => setCoinIds(tx)))
          //     .then(() => resolve(docs))
          //     .catch(err => reject(err));
          // } else {
          //   resolve(docs);
          // }
        }
      });
    } catch (e) {
      reject(e);
    }
  });
}
