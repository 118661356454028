import env from "@/services/env";

export interface YearMonth {
  year: number;
  month: number;
}

export class DateHelpers {
  static getMonths (fromDate: Date, toDate: Date): YearMonth[] {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    for(let year = fromYear; year <= toYear; year++) {
      let month = year === fromYear ? fromMonth : 0;
      const monthLimit = year === toYear ? toMonth : 11;
      for(; month <= monthLimit; month++) {
        months.push({ year, month })
      }
    }
    return months;
  }
}

// deprecated
export const CbAuthUrl = (): URL => {
  const url = new URL('https://www.coinbase.com/oauth/authorize')
  url.searchParams.append('client_id', env.COINBASE_AUTH_CLIENT_ID)
  url.searchParams.append('redirect_uri', env.COINBASE_AUTH_REDIRECT_URI)
  url.searchParams.append('response_type', 'code')
  url.searchParams.append('scope', 'wallet:user:read,wallet:accounts:read,wallet:transactions:read')
  url.searchParams.append('account', 'all')
  url.searchParams.append('state', JSON.stringify({ provider: 'coinbase' }))
  return url
}