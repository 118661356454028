import { DbTable } from "@/helpers/DbTable";
import { dbInsert } from "@/services/db/dbInsert";
import { dbFind } from "@/services/db/dbFind";
import { addChildren } from "@/services/db/addChildren";
import { sumUp } from "@/helpers/sumUp";
import { LinkWalletsResponse } from "@/services/link-wallets-response";
import { ImportResponse } from "@/services/import-response";
import { EtherscanApiInternalTransaction } from "@/services/etherscan/etherscan-api-internal-transaction";
import { EtherscanApiNFTTransaction, EtherscanApiTokenTransaction } from "@/services/etherscan/etherscan-api-token-transaction";
import { EtherscanApiTransaction } from "@/services/etherscan/etherscan-api-transaction";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import { BaseImport, BaseImportService } from "../base-import";
import EtherscanApi from "./etherscan-api";

// TODO
const expUrls = {
  'Etherscan': 'https://etherscan.io/',
  'Fantom': 'https://ftmscan.com/',
  'Avalanche': 'https://snowtrace.io/'
}

export const linkWalletHashes = (transactions: Transaction[]): Promise<LinkWalletsResponse>  => {
  return new Promise((resolve, reject) => {
    const walletResponse: LinkWalletsResponse = {
      new: 0,
      linked: 0
    }
    // for each hash key

    // console.debug('txs in link hashes...', transactions.map(t => t.source))
    const hashes = transactions.map(t => t.hash).filter(hash => !!hash);
    // console.debug('hashess...', hashes, hashesNo0x, hashes0x);
    dbFind(null, DbTable.TRANSACTIONS, { 'hash': { $in: hashes } }, null, null)
      .then((docs: Transaction[]) => {
        const matchingHashes: string[] = docs.map(d => d.hash);
        const otherTransactions: Transaction[] = transactions.filter(tx => {
          return matchingHashes.indexOf(tx.hash) === -1;
        });

        walletResponse.new = otherTransactions.length;
        Promise.all(docs.map(d => {
          return new Promise((resolve, reject) => {
            const children = transactions.filter(tx => {
                return tx.hash && d.hash && tx.hash === d.hash;
              });
            while (d.children.length > 0) {
              const nestedChild = d.children.shift();
              children.push(nestedChild);
            }
            walletResponse.linked += children.length;
            addChildren({ id: d._id, children: children.map(child => TransactionFactory.toDB(child)) })
              .then((addChildrenRes) => { resolve(addChildrenRes); })
              .catch((addChildrenErr) => { reject(addChildrenErr); });
          });
        })).then(linkAllDocsRes => {
            // console.debug('other transactions', linkAllDocsRes, otherTransactions.length);
            dbInsert(DbTable.TRANSACTIONS, otherTransactions.map(t => TransactionFactory.toDB(t)))
              .then(newDocs => {
                // console.debug('compare newDocs to estimated...', newDocs.length, walletResponse.new);
                resolve(walletResponse);
              })
              .catch(err => reject(err))
          })
          .catch(allDocsErr => reject(allDocsErr));
      })
      .catch(err => reject(err))
  });
}

export default class EtherscanImport extends BaseImportService implements BaseImport {
  declare api: EtherscanApi;
  name = 'Etherscan';
  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials, address?: string, startblock = 0, altService?: string) {
    super(altService ? altService.toLocaleLowerCase() : 'etherscan', creds, address, startblock);
    if (altService) {
      this.name = altService;
    }
  }

  async import(): Promise<ImportResponse> {
    const importResponse: ImportResponse = {
      existing: 0,
      linked: 0,
      new: 0
    }
    const ethDocs = await dbFind({ 'source.blockNumber': 1 }, DbTable.TRANSACTIONS, { 'source.myAddress': (this.api as EtherscanApi).address.toLocaleLowerCase(), 'source.name': `${this.name} Tx` }, null, { date: -1 });
    const internalDocs = await dbFind({ 'source.blockNumber': 1 }, DbTable.TRANSACTIONS, { 'source.myAddress': (this.api as EtherscanApi).address.toLocaleLowerCase(), 'source.name': `${this.name} Internal Tx` }, null, { date: -1 });
    const tokenDocs = await dbFind({ 'source.blockNumber': 1 }, DbTable.TRANSACTIONS, { 'source.myAddress': (this.api as EtherscanApi).address.toLocaleLowerCase(), 'source.name': `${this.name} Token Tx` }, null, { date: -1 });
    const nftDocs = await dbFind({ 'source.blockNumber': 1 }, DbTable.TRANSACTIONS, { 'source.myAddress': (this.api as EtherscanApi).address.toLocaleLowerCase(), 'source.name': `${this.name} NFT Tx` }, null, { date: -1 });
    const ethBlock = ethDocs[0] ? Number(ethDocs[0].source.blockNumber) + 1 : 0;
    const internalBlock = internalDocs[0] ? Number(internalDocs[0].source.blockNumber) + 1 : 0;
    const tokenBlock = tokenDocs[0] ? Number(tokenDocs[0].source.blockNumber) + 1 : 0;
    const nftBlock = nftDocs[0] ? Number(nftDocs[0].source.blockNumber) + 1 : 0;
    const txs: Transaction[] = await (this.api as EtherscanApi).fetchTransactions(ethBlock, internalBlock, tokenBlock, nftBlock);
    
    const baseEthTxs = txs.filter(t => t instanceof EtherscanApiTransaction && t.currencySymbol !== 'FTM');
    const baseInternalTxs = txs.filter(t => t instanceof EtherscanApiInternalTransaction);
    const baseTokens = txs.filter(t => t instanceof EtherscanApiTokenTransaction) as EtherscanApiTokenTransaction[];
    const baseNFTs = txs.filter(t => t instanceof EtherscanApiNFTTransaction) as EtherscanApiNFTTransaction[];

    const tokenTxs = baseTokens.map((t: EtherscanApiTokenTransaction) => t.asTransaction);
    const nftTxs = baseNFTs.map((t: EtherscanApiNFTTransaction) => t.asTransaction);
    const ethTxs = baseEthTxs.map((t: EtherscanApiTransaction) => t.asTransaction);
    const internalTxs = baseInternalTxs.map((t: EtherscanApiInternalTransaction) => t.asTransaction);

    const matchingTxs: any = await dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(txs), null, null);
    importResponse.existing = matchingTxs.length;
    const newTxs = this.newTxs(ethTxs, matchingTxs);
    const ethLinkSummary: LinkWalletsResponse = await linkWalletHashes(newTxs);
    const newInternalTransactions = this.newTxs(internalTxs, matchingTxs);
    const internalSummary: LinkWalletsResponse = await linkWalletHashes(newInternalTransactions);
    const newTokenTransactions = this.newTxs(tokenTxs, matchingTxs);
    const tokenSummary: LinkWalletsResponse = await linkWalletHashes(newTokenTransactions);
    const newNftTransactions = this.newTxs(nftTxs, matchingTxs);
    const nftSummary: LinkWalletsResponse = await linkWalletHashes(newNftTransactions);
    importResponse.linked = ethLinkSummary.linked + internalSummary.linked + tokenSummary.linked + nftSummary.linked;
    importResponse.new = ethLinkSummary.new + internalSummary.new + tokenSummary.new + nftSummary.new;
    return importResponse;
  }
}


