import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import EtherscanImport from "../etherscan/etherscan-import";
import { FantomApi } from "./fantom-api";


export class FantomImport extends EtherscanImport {
  declare api: FantomApi;
  name = 'Fantom';

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials, address?: string, startBlock = 0) {
    super(creds, address, startBlock, 'Fantom');
  }
}