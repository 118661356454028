import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import env from "../env";
import EtherscanApi from "../etherscan/etherscan-api";

export class FantomApi extends EtherscanApi {
  static API_URL = env.FANTOM_API_URL;

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials = { key: null, secret: null, passphrase: null }, address?: string) {
    super(creds, address, 'Fantom');
  }
}