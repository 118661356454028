<template>
  <div id="app">
    <div class="title-bar"></div>
    <template>
      <div id="sidebar" class="container border-right">
        <b-nav vertical class="pt-4">
          <b-nav-item to="/">
            <div class="logo"></div>
            <!-- <p>v{{ settings.version }}</p> -->
          </b-nav-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-nav-item to="/"><b-icon-graph-up></b-icon-graph-up> Summary</b-nav-item>
          <b-nav-item to="/transactions"><b-icon-cash-stack></b-icon-cash-stack> Transactions</b-nav-item>
          <b-nav-item to="/reports"><b-icon-table></b-icon-table> Reports</b-nav-item>
          <b-nav-item to="/settings"><b-icon-gear-fill></b-icon-gear-fill> Settings</b-nav-item>
          <b-nav-item to="/docs"><b-icon-file-earmark-text></b-icon-file-earmark-text> Docs</b-nav-item>
        </b-nav>
      </div>
      <div class="main pt-4 bg-white">
        <router-view/>
      </div>
    </template>
    <toasters></toasters>
  </div>
</template>

<script>
import Toasters from '@/components/Toasters'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Helpers from '@/helpers/ipc-helpers'
import OauthRedirect from './views/OauthRedirect.vue'
import FirebaseService from './services/firebase/firebase-service'

const waitABit = () => {
  const rand = Math.random();
  return new Promise((resolve) => setTimeout(resolve, (rand * 1500)))
}

export default {
  components: {
    Toasters
    // OauthRedirect
  },
  data () {
    return {
      clientType: 'light',
      nimiqInstance: {
        client: null,
        consensus: null,
        blockchain: null,
        network: null,
      }
    }
  },
  async created () {
    window.ipcRenderer.receive('messages', (msg) => {
      if (msg === 'INIT') {
        console.debug('This should only call once not every window open');
        this.verifyPurchase()
      } else {
        this.$store.commit('dbInfo', msg);
      }
    })
    window.ipcRenderer.receive('purchase:message', (msg) => {
      this.handlePurchaseMessage(msg);
    })
    window.ipcRenderer.receive('nimiq:verify', (msg) => {
      if (msg.startsWith('code=')) {
        if (this.$route.path !== '/settings') {
          this.$router.push({ path: 'settings' });
        }
        const code = msg.split('code=')[1];
        this.setNimiqVerificationCode(code);
        // console.debug('code set...', code);
      }
    })
    await Helpers.appReady();
    
    const platform = await Helpers.processPlatform();
    if (platform !== 'darwin') {
      document.body.setAttribute('class', 'website')
    }
    this.startNimiqInstance();

    // Run migrations
    this.setLoadingInfo('Running data migrations...')
    await waitABit()
    await this.runMigrations()

    // Fetching settings
    this.setLoadingInfo('Checking settings...')
    await waitABit()
    await this['settings/fetchSettings']()
    // Updating coin prices
    await waitABit()
    this.setLoadingInfo('Refreshing coin list...')
    await this['coins/refresh']()
    // Getting prices
    this.setLoadingInfo('Getting coin prices...')
    await waitABit()
    await this['prices/ensurePriceData']()
    // Updating missing transaction values
    this.setLoadingInfo('Updating missing transaction values...')
    await waitABit()
    await this['transactions/updateNotFoundCoins']()
    // Fetching portfolio summary
    this.setLoadingInfo('Fetching portfolio summary...')
    await waitABit()
    await this['transactions/initTransactions']()
    // loaded
    this.setLoadingInfo('loaded')
  },
  computed: {
    ...mapState({
      ready: state => state.loadingInfo === 'loaded',
    }),
    ...mapState('settings', ['settings']),
    theme () {
      return window.getComputedStyle(document.body).backgroundColor === 'rgb(0, 0, 0)' ? 'dark' : 'light'
    }
  },
  mounted() {
    // this.verifyPurchase()
  },
  methods: {
    ...mapActions([
      'coins/refresh',
      'transactions/initTransactions',
      'settings/fetchSettings',
      'settings/updateSetting',
      'transactions/updateNotFoundCoins',
      'prices/ensurePriceData'
    ]),
    async runMigrations() {
      await Helpers.runMigrations();
    },
    ...mapActions('settings', ['updateSetting']),
    ...mapMutations(['setNimiqVerificationCode', 'setLoadingInfo', 'dbError']),
    startNimiqInstance() {
      document.addEventListener('nimconsensus', (evt) => {
        // console.debug('nimconsensus evt', evt);
        this.$store.commit('setNimiqConsensus', evt.detail.consensus);
      })
    },
    resetPurchaseInfo() {
      setTimeout(() => {
        this.$store.commit('purchaseInfo', null);
      }, 5000);
    },
    verifyPurchase() {
      FirebaseService.create()
        .then(service => {
          service.verify()
            .then(res => {
              this.storePurchase('receipt')
            })
            .catch(err => {
              console.error(err)
              this.storePurchase(null)
            })
        })
        .catch(err => {
          console.error(err)
          this.storePurchase(null)
        })
    },
    storePurchase(val) {
      const setting = {}
      setting[`apiKeys.mas`] = {
        key: val,
        secret: '',
        passphrase: ''
      }
      this['settings/updateSetting'](setting)
        .then(console.debug)
        .catch(console.error)
      this.resetPurchaseInfo()
    },
    handlePurchaseMessage(msg) {
      console.debug(msg)
      this.$store.commit('purchaseInfo', msg)

      switch(msg.state) {
        case 'purchasing':
          break
        case 'purchased':
          this.verifyPurchase()
          break;
        case 'restored':
          this.verifyPurchase()
          break;
        case 'productInfo':
          console.debug('productInfo', msg)
          this.$store.commit('productInfo', msg)
          this.resetPurchaseInfo()
          break;
        default:
          this.resetPurchaseInfo()
          break;
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom";
</style>