import { DbTable } from "@/helpers/DbTable";
import { dbFindById } from "../services/db/dbFindById";
import { PriceData } from "@/models/gain-loss-entry";
import { priceAt } from "@/services/db/priceAt";

export function priceById(date: Date, id: string): any {
  return new Promise((resolve, reject) => {
    dbFindById(DbTable.PRICES, id)
      .then((price: PriceData) => {
        // console.debug('price...', price, date);
        resolve(priceAt(price, date).toNumber());
      })
      .catch(err => { console.error(err); reject(err); });
  });
}
