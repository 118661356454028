import md5 from "blueimp-md5";
import Decimal from "decimal.js-light";
import { EtherscanInternalTransaction, EtherscanTransaction } from "./etherscan-api-token-transaction";
import { Transaction } from "../../models/transaction";
import { EtherscanApiBaseTransaction } from "./etherscan-api-base-transaction";

export class EtherscanApiInternalTransaction extends EtherscanApiBaseTransaction implements Transaction {
  declare _tx: EtherscanInternalTransaction; 
  children: Transaction[] = [];

  constructor(tx: EtherscanInternalTransaction, myAddress: string = null, altService?: string) {
    super(tx, myAddress, altService);
  }

  get amount(): Decimal {
    return this._tx.isError === "1" ? new Decimal(0) : new Decimal(this._tx.value).mul(1e-18);
  }

  get source(): any {
    return {
      name: `${this.name} Internal Tx`,
      ...this._tx,
      myAddress: this._myAddress
    }
  }
}