import { DbTable } from "@/helpers/DbTable";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import { BaseImportService } from "../base-import";
import { dbFind } from "../db/dbFind";
import { dbInsert } from "../db/dbInsert";
import { linkWalletHashes } from "../etherscan/etherscan-import";
import { ImportResponse } from "../import-response";
import { LinkWalletsResponse } from "../link-wallets-response";
import GeminiApi, { GeminiAccountTimestamp, GeminiTrade, GeminiTransaction, GeminiTransfer } from "./gemini-api";

const tradeGrouping = (txs: Transaction[]): { [key: string]: Transaction } => {
  return txs.reduce((a: { [key: string]: Transaction }, b: GeminiTransaction) => {
    if (b.isTrade) {
      if (a[b._tx.order_id.toString()]) {
        a[b._tx.order_id.toString()].children.push(b);
      } else {
        a[b._tx.order_id.toString()] = b;
      }
    }
    return a;
  }, Object.create({}))
}

const timestampGrouping = (txs: Transaction[]): GeminiAccountTimestamp => {
  return txs.reduce((a: GeminiAccountTimestamp, b: Transaction) => {
    if (b.source.name === 'Gemini Transfer') {
      const transfer = b.source as GeminiTransfer;
      if (!a[transfer.account.account]) {
        a[transfer.account.account] = {
          transfer: 0,
          trade: 0
        }
      }
      a[transfer.account.account].transfer = Math.max(a[transfer.account.account].transfer || 0, transfer.timestampms);
    } else if (b.source.name === 'Gemini Trade') {
      const trade = b.source as GeminiTrade;
      if (!a[trade.account.account]) {
        a[trade.account.account] = {
          transfer: 0,
          trade: 0
        }
      }
      a[trade.account.account].trade = Math.max(a[trade.account.account].trade, trade.timestampms);
    }
    return a;
  }, Object.create({}))
}

export class GeminiImport extends BaseImportService {
  declare api: GeminiApi;
  
  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super('gemini', creds);
  }

  import(): Promise<ImportResponse> {
    return new Promise((resolve, reject) => {
      dbFind(null, DbTable.TRANSACTIONS, { $or: [{ 'source.name': 'Gemini Trade' }, { 'source.name': 'Gemini Transfer' }] }, null, { date: 1 })
        .then((docs: any[]) => {
          const txs = docs.map(d => TransactionFactory.fromDB(d));
          const accountTimestamps = timestampGrouping(txs);
          (this.api as GeminiApi).fetchTransactions(accountTimestamps)
            .then((txs: GeminiTransaction[]) => {
              const importResponse: ImportResponse = {
                new: 0,
                existing: 0,
                linked: 0
              }
              dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(txs), null, null)
                .then((matchingTxs: any[]) => {
                  importResponse.existing = matchingTxs.length;
                  const newTxs = this.newTxs(txs, matchingTxs) as GeminiTransaction[];

                  const trades = newTxs.filter(t => t.isTrade);
                  const transfers = newTxs.filter(t => t.isTrsf);

                  const grouped = Object.values(tradeGrouping(trades));
                  importResponse.new = grouped.length;
                  importResponse.linked = trades.length - grouped.length;
                  dbInsert(DbTable.TRANSACTIONS, grouped.map(t => TransactionFactory.toDB(t)))
                    .then(res => {
                      linkWalletHashes(transfers)
                        .then((linkRes: LinkWalletsResponse) => {
                          importResponse.linked = importResponse.linked + linkRes.linked;
                          importResponse.new = importResponse.new + linkRes.new;
                          resolve(importResponse);
                        })
                        .catch(err => reject(err))
                    })
                    .catch(err => reject(err))
                })
                .catch(err => reject(err))
            })
            .catch(err => reject(err))
        })
        .catch(err => reject(err))
    })
  }
}