import { DbTable } from "@/helpers/DbTable";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ImportResponse } from "@/services/import-response";
import Decimal from "decimal.js-light";
import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import { BaseImport, BaseImportService } from "../base-import";
import { addChildren } from "../db/addChildren";
import { dbFind } from "../db/dbFind";
import { dbInsert } from "../db/dbInsert";
import { dbUpdate } from "../db/dbUpdate";
import { linkWalletHashes } from "../etherscan/etherscan-import";
import { LinkWalletsResponse } from "../link-wallets-response";
import ServiceVerificationFactory from "../service-verification-factory";
import KucoinApi, { KucoinApiLedgerEntry, KucoinPagination, KucoinTransaction } from "./kucoin-api";

const tradeGrouping = (txs: KucoinTransaction[], existing?: { [key: string]: Transaction }): { [key: string]: Transaction } => {
  return txs.reduce((a: { [key: string]: Transaction }, b: KucoinTransaction) => {
    if (b.tradeContext && b.tradeContext.symbol && b.tradeContext.orderId) {
      const txKey = `${b.tradeContext.symbol}_${b.tradeContext.orderId}`;
      if (a[txKey]) {
        // a[txKey].receivedSymbol = a[txKey].receivedSymbol || b.receivedSymbol;
        // a[txKey].sentSymbol = a[txKey].sentSymbol || b.sentSymbol;
        // a[txKey].receivedAmount = a[txKey].receivedAmount.add(b.receivedAmount);
        // a[txKey].sentAmount = a[txKey].sentAmount.add(b.sentAmount);
        // b._tx.amount = '0';
        const child = b.asTransaction;
        const children = [...child.children];
        child.children = [];
        a[txKey].children.push(child);
        a[txKey].children = a[txKey].children.concat(children);
      } else {
        a[txKey] = b.asTransaction;
      }
    }
    return a;
  }, existing ? existing : Object.create({}))
}

const alignTrades = async (trades: KucoinTransaction[], existing: KucoinTransaction[]): Promise<Transaction[]> => {
  const newGrouping = tradeGrouping(trades);
  const existingGrouping = tradeGrouping(existing);
  const newTrades: Transaction[] = [];
  for (const key of Object.keys(newGrouping)) {
    const newTx = newGrouping[key];
    if (key in existingGrouping) {
      const tx = existingGrouping[key];
      const newChildren = [...newTx.children];
      newTx.children = [];
      const children = [newTx, ... newChildren].map(t => TransactionFactory.toDB(t));
      await addChildren({ id: tx._id, children });
    } else {
      newTrades.push(newTx);
    }
  }
  return newTrades;
};

function setLatestDate(b: KucoinTransaction, a: { [account: string]: KucoinPagination }, before = true) {
  const nextDate = b.date.getTime();
  a[b.source.accountId] = a[b.source.accountId] ? a[b.source.accountId] : {
    pageSize: 500,
    currentPage: 1,
    startAt: before ? new Date('10/31/2008').getTime() : nextDate + 1,
    endAt: before ? nextDate - 1 : new Date().getTime()
  };
  if (before && nextDate < a[b.source.accountId].endAt) {
    a[b.source.accountId].startAt = new Date('10/31/2008').getTime();
    a[b.source.accountId].endAt = nextDate - 1;
  } else if (!before && nextDate > a[b.source.accountId].startAt) {
    a[b.source.accountId].startAt = nextDate + 1;
    a[b.source.accountId].endAt = new Date().getTime();
  }
}

const paginationGrouping = (txs: KucoinTransaction[]): { [account: string]: KucoinPagination } => {
  return txs.reduce((a: { [account: string]: KucoinPagination }, b: KucoinTransaction) => {
    a = a || {};
    setLatestDate(b, a, b.source ? !KucoinImport.beforeDones[b.source.accountId] : false);
    b.children.forEach(child => {
      setLatestDate(new KucoinTransaction(child.source, { id: child.source.accountId } as any), a, child.source ? !KucoinImport.beforeDones[child.source.accountId] : false);
    });
    return a;
  }, Object.create({}))
}

export default class KucoinImport extends BaseImportService implements BaseImport {
  static beforeDones: { [account: string]: boolean } = {};
  declare api: KucoinApi;

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super('kucoin', creds);
  }

  async import(): Promise<ImportResponse> {
    const docs: Transaction[] = (await dbFind(null, DbTable.TRANSACTIONS, { 'source.name': 'KuCoin' }, null, { date: 1 }) as Transaction[]);
    const pages = paginationGrouping(docs.map(d => {
      if (d && d.source) {
        return new KucoinTransaction(d.source as KucoinApiLedgerEntry, { id: d.source.accountId } as any);
      } else {
        return null;
      }
    }));
    // console.debug('kc pagination...', pages);
    const accounts = await this.api.getAccounts();
    let allTxs: KucoinTransaction[] = [];
    for (const acct of accounts) {
      let txs = await this.api.getLedgers(pages[acct.id], acct) as KucoinTransaction[];
      if (txs.length === 0) {
        KucoinImport.beforeDones[acct.id] = true;
        const newPages = paginationGrouping(docs.map(d => {
          if (d && d.source) {
            return new KucoinTransaction(d.source as KucoinApiLedgerEntry, { id: d.source.accountId } as any);
          } else {
            return null;
          }
        }));
        const moretxs = await this.api.getLedgers(newPages[acct.id], acct) as KucoinTransaction[];
        txs = txs.concat(moretxs);
      }
      allTxs = allTxs.concat(txs);
    }
    const importResponse: ImportResponse = {
      new: 0,
      existing: 0,
      linked: 0
    }
    const matchingTxs: any[] = await dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(allTxs), null, null) as any[];
    importResponse.existing = matchingTxs.length;
    const newTxs = this.newTxs(allTxs, matchingTxs) as KucoinTransaction[];
    const trades = newTxs.filter(t => t.isTrade);
    const transfers = newTxs.filter(t => t.isTrsf);
    const others = newTxs.filter(t => trades.indexOf(t) === -1 && transfers.indexOf(t) === -1);
    const existingTrades = docs.map(d => (d.source ? new KucoinTransaction(d.source as KucoinApiLedgerEntry, { id: d.source.accountId } as any) : null)).filter(d => !!d && d.isTrade);
    const newTrades = await alignTrades(trades, existingTrades);
    importResponse.new = others.length + newTrades.length;
    importResponse.linked = (others.length + trades.length) - newTrades.length;
    const res = await dbInsert(DbTable.TRANSACTIONS, [...others, ...newTrades].map(t => TransactionFactory.toDB(t)));
    const linkRes = await linkWalletHashes(transfers)
    importResponse.new += linkRes.new;
    importResponse.linked += linkRes.linked;
    return importResponse;
  }
}
