import Decimal from "decimal.js-light";
import { BaseStoreState, StoreChart } from "./base-store-state";

export interface Income {
  date: Date;
  symbol: string;
  amount: Decimal;
  value: Decimal;
}

export interface IncomeStoreState extends BaseStoreState {
  incomes: Income[];
  chart: StoreChart;
}

export class IncomeDbTransformer {
  static toDb(income: Income): any {
    return {
      date: income.date,
      symbol: income.symbol,
      amount: income.amount.toNumber(),
      value: income.value.toDecimalPlaces(2).toNumber()
    }
  }

  static fromDb(income: any): Income {
    return {
      date: income.date,
      symbol: income.symbol,
      amount: new Decimal(income.amount),
      value: new Decimal(income.value)
    }
  }
}