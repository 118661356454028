import { ApiCredentials, BaseApi, OauthCredentials, TokenCredentials } from './base-api';
import CoinbaseApi from './coinbase/coinbase-api';
import CoinbaseProApi from './coinbasePro/coinbase-pro-api';
import env from './env';
import EtherscanApi from './etherscan/etherscan-api';
import KucoinApi from './kucoin/kucoin-api';
import KrakenApi from './kraken/kraken-api';
import GeminiApi from './gemini/gemini-api';
import GumroadApi from './gumroad/gumroad-api';
import { CoinstatsApi } from './coinstats/coinstats-api';
import { AvalancheApi } from './avalanche/avalanche-api';
import { FantomApi } from './fantom/fantom-api';
import SignatureService from './signature/signature-service';

export default class ServiceVerificationFactory {
  static create(service: string, creds: (ApiCredentials | OauthCredentials | TokenCredentials), ...args: any[]): BaseApi {
    // creds = Object.assign(creds, {
    //   client_id: (creds as OauthCredentials).client_id || env[`${service.toUpperCase()}_AUTH_CLIENT_ID`],
    //   client_secret: (creds as OauthCredentials).client_secret || env[`${service.toUpperCase()}_AUTH_SECRET`]
    // });
    switch (service) {
      case 'etherscan':
        return new EtherscanApi(creds, ...args);
      case 'coinbase':
        return new CoinbaseApi(creds);
      case 'coinbasePro':
        return new CoinbaseProApi(creds);
      case 'kucoin':
        return new KucoinApi(creds);
      case 'kraken':
        return new KrakenApi(creds);
      case 'gemini':
        return new GeminiApi(creds);
      case 'gumroad':
        return new GumroadApi(creds);
      case 'csv':
        return null;
      case 'coinstats':
        return new CoinstatsApi(creds);
      case 'avalanche':
        return new AvalancheApi(creds, ...args);
      case 'fantom':
        return new FantomApi(creds, ...args);
      case 'signature':
        return new SignatureService(creds);
      default:
        throw new Error('Invalid service')
    }
  }
}
