import { DateHelpers, YearMonth } from "@/helpers/general-helpers";
import { DbTable } from "@/helpers/DbTable";
import { StoreChart } from "@/store/interfaces/base-store-state";
import { Filters } from "@/store/interfaces/filters";
import { IncomeDbTransformer } from "@/store/interfaces/income-store-state";
import Decimal from "decimal.js-light";
import { dbFind } from "@/services/db/dbFind";

export function incomeChart(payload: any, filters: Filters): Promise<StoreChart> {
  return new Promise((resolve, reject) => {
    dbFind(null, DbTable.INCOMES, payload, null, { date: 1 }, filters)
      .then((docs: any[]) => {
        const income = docs.map(d => IncomeDbTransformer.fromDb(d));
        const buckets = {};
        const dates = income.map(i => i.date);
        if (dates.length === 0) { resolve({ labels: [], values: [] }); return; }
        const start = dates.reduce(function (a, b) { return a < b ? a : b; });
        const last = dates.reduce(function (a, b) { return a > b ? a : b; });
        const months = DateHelpers.getMonths(start, last);
        months.forEach((ym: YearMonth) => {
          buckets[`${ym.month + 1}/${ym.year}`] = new Decimal(0);
        });
        income.forEach(i => {
          const month = `${i.date.getMonth() + 1}/${i.date.getFullYear()}`;
          buckets[month] = buckets[month] || new Decimal(0);
          buckets[month] = buckets[month].add(i.value);
        });
        const labels = Object.keys(buckets);
        const values = Object.values(buckets).map((val: Decimal) => val.toSignificantDigits(2).toNumber());
        resolve({ labels, values });
      })
      .catch(err => reject(err));
  });
}
