import SymbolTaxDb from "@/services/db/db";

export function dbFindById(table: string, id: string): Promise<any> {
  return new Promise((resolve, reject) => {
    SymbolTaxDb.db[table].findOne({ _id: id }, function (err, doc) {
      if (err) {
        reject(err);
      } else {
        resolve(doc);
      }
    });
  });
}
