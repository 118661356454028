import { Transaction, TransactionFactory } from "@/models/transaction";
import { BaseImport, BaseImportService } from "../base-import";
import { ImportResponse } from "../import-response";
import parse from 'csv-parse';
import { DbTable } from "@/helpers/DbTable";
import { dbFind } from "../db/dbFind";
import { dbInsert } from "../db/dbInsert";
import Decimal from "decimal.js-light";
import md5 from "blueimp-md5";

export default class CsvImport extends BaseImportService {
  private _csv: string;

  constructor(csv?: string) {
    super('csv', null);
    this._csv = csv;
  }

  import(): Promise<ImportResponse> {
    return new Promise((resolve, reject) => {
      parse(this._csv.trim(), { columns: true }, (err, csvTxs: CsvImportTransaction[]) => {
        if (err) {
          reject(err)
        } else if (!isCsvImportTransaction(csvTxs[0])) {
          reject('Unsupported file!');
        } else {
          const txs = csvTxs.map(c => new CsvTransaction(c));
          const importResponse: ImportResponse = {
            new: 0,
            existing: 0,
            linked: 0
          }
          dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(txs), null, null)
            .then((matchingTxs: any[]) => {
              importResponse.existing = matchingTxs.length;
              const newTxs = this.newTxs(txs, matchingTxs);
              importResponse.new = newTxs.length;
              dbInsert(DbTable.TRANSACTIONS, newTxs.map(t => TransactionFactory.toDB(t)))
                .then(res => resolve(importResponse))
                .catch(err => reject(err))
            })
            .catch(err => reject(err))
        }
      });
    });
  }
}

export interface CsvImportTransaction {
  date: string;
  receivedSymbol: string;
  receivedAmount: string;
  sentSymbol: string;
  sentAmount: string;
  feeSymbol: string;
  fee: string;
  description: string;
}

export const isCsvImportTransaction = (object: unknown): object is CsvImportTransaction => {
  return Object.prototype.hasOwnProperty.call(object, 'date')
      && Object.prototype.hasOwnProperty.call(object, 'receivedSymbol')
      && Object.prototype.hasOwnProperty.call(object, 'receivedAmount')
      && Object.prototype.hasOwnProperty.call(object, 'sentSymbol')
      && Object.prototype.hasOwnProperty.call(object, 'sentAmount')
      && Object.prototype.hasOwnProperty.call(object, 'feeSymbol')
      && Object.prototype.hasOwnProperty.call(object, 'fee')
      && Object.prototype.hasOwnProperty.call(object, 'description');
}

export class CsvTransaction implements Transaction {
  _tx: CsvImportTransaction;

  constructor(tx: CsvImportTransaction) {
    this._tx = tx;
  }
  get date(): Date { return new Date(this._tx.date); }
  get receivedSymbol(): string { return this._tx.receivedSymbol || null; }
  get receivedAmount(): Decimal { return new Decimal(this._tx.receivedAmount || 0); }
  get sentSymbol(): string { return this._tx.sentSymbol || null; }
  get sentAmount(): Decimal { return new Decimal(this._tx.sentAmount || 0); }
  get price(): Decimal { return new Decimal(0); }
  get fee(): Decimal { return new Decimal(this._tx.fee || 0); }
  get feeSymbol(): string { return this._tx.feeSymbol || null; }
  get description(): string { return this._tx.description; }
  get source(): any { return { name: 'CSV', ...this._tx }; }
  get _id(): string { return md5(JSON.stringify(this.source)); }
  children: Transaction[] = [];
}