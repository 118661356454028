import { DbTable } from "@/helpers/DbTable";
import { BaseImport, BaseImportService } from "../base-import";
import { dbUpdate } from "../db/dbUpdate";
import { ImportResponse } from "../import-response";
import { CoinstatsApi } from "./coinstats-api";
import { CoinstatsDbCoin } from "./CoinstatsDbCoin";
import fiats from './fiats.json';
import { ApiCredentials, OauthCredentials, TokenCredentials } from '../base-api';

interface Fiat {
  name: string;
  rate: number;
  symbol: string;
  imageUrl: string;
}

const fiatToCoin = (fiat: Fiat): CoinstatsDbCoin => {
  return {
    _id: `stax-fiat-${fiat.name.toLocaleLowerCase()}`,
    name: fiat.name,
    symbol: fiat.name,
    rank: 1,
    totalSupply: 0,
    icon: fiat.imageUrl,
    websiteUrl: null,
    twitterUrl: null,
    redditUrl: null,
    contractAddress: null,
    decimals: 0,
    exp: []
  }
}

export class CoinstatsImport extends BaseImportService implements BaseImport {
  declare api: CoinstatsApi;
  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super('coinstats', creds);
  }

  async import(): Promise<ImportResponse> {
    const response: ImportResponse = {
      new: 0,
      existing: 0,
      linked: 0
    }
    let coins: CoinstatsDbCoin[];
    const fiatsAsCoins = (fiats as Fiat[]).map(f => fiatToCoin(f));
    for (const coin of fiatsAsCoins) {
      await dbUpdate(DbTable.COINS, { _id: coin._id }, coin, { upsert: true });
    }
    let skip = 0;
    while (!coins || coins.length) {
      coins = await this.api.getCoins(skip);
      for (const coin of coins) {
        await dbUpdate(DbTable.COINS, { _id: coin._id }, coin, { upsert: true });
      }
      skip = skip + 1000;
    }
    return response;
  }
}