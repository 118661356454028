import Decimal from "decimal.js-light";
import { stringify } from 'csv-stringify';
import { dateHelper } from "../../helpers/dateHelper";

export interface CsvOutput {
  filename: string;
  output: string;
}

export function csvifyGains(gains: { _id: any; description: any; cost: Decimal; proceeds: Decimal; boughtAt: any; soldAt: any; profit: Decimal; symbol: any; amount: Decimal }[], filePath: string, term: string): Promise<CsvOutput> {
  return new Promise((resolve, reject) => {
    stringify(
      gains.map(g => {
        const sold = dateHelper(g, 'soldAt');
        const bought = g.boughtAt ? dateHelper(g, 'boughtAt') : sold;
        return [g.description, bought, sold, g.proceeds.toFixed(2), g.cost.toFixed(2), g.profit.toFixed(2)];
      }),
      {
        header: true,
        columns: ['Description', 'Acquired', 'Sold', 'Proceeds', 'Cost', 'Profit'],
      },
      (err, res) => {
        if (err) {
          reject(err);
        } else {
          const filename = filePath;
          resolve({ filename, output: res });
        }
      });
  });
}
