









































































































import { Transaction, TransactionFactory } from '@/models/transaction';
import Decimal from 'decimal.js-light';
import { mapActions, mapState, mapMutations } from 'vuex';
import { TransactionStoreState } from '@/store/interfaces/transaction-store-state';
import { truncater } from '@/store/mixins';
import TransactionForm from './TransactionForm.vue';
import Helpers from '@/helpers/ipc-helpers';
import { DbTable } from '@/helpers/DbTable';

export default {
  components: { TransactionForm },
  data () {
    return {
      isBusy: false,
      editTx: {},
      childIdx: -1,
      fields: [
        {
          key: 'details',
          label: '',
          sortable: false,
          tdClass: 'details'
        },
        {
          key: 'date',
          sortable: true
        },
        {
          key: 'receivedSymbol',
          label: 'Received',
          sortable: true
        },
        {
          key: 'sentSymbol',
          label: 'Sent',
          sortable: true
        },
        {
          key: 'description',
          sortable: true
        },
        {
          key: 'actions',
          label: '',
          sortable: false
        }
      ]
    }
  },
  mounted () {
    this.refreshTable();
  },
  computed: {
    ...mapState('transactions', {
      loading: (state: TransactionStoreState) => state.loading,
      importing: (state: TransactionStoreState) => state.importing,
      noRows: (state: TransactionStoreState) => state.paginationFields.noRows,
      startDate: (state: TransactionStoreState) => state.filters.startDate,
      endDate: (state: TransactionStoreState) => state.filters.endDate,
      symbols: (state: TransactionStoreState) => state.filters.symbols,
      types: (state: TransactionStoreState) => state.filters.types
    }),
    currentPage: {
      get () {
        return this.$store.state.transactions.paginationFields.currentPage
      },
      set (val) {
        this.$store.commit('transactions/setCurrentPage', val)
      }
    },
    perPage: {
      get () {
        return this.$store.state.transactions.paginationFields.perPage
      },
      set (val) {
        this.$store.commit('transactions/setPerPage', val)
      }
    },
    sortBy: {
      get () {
        return this.$store.state.transactions.paginationFields.sortBy
      },
      set (val) {
        this.$store.commit('transactions/setSortBy', val)
      }
    },
    sortDesc: {
      get () {
        return this.$store.state.transactions.paginationFields.sortDesc
      },
      set (val) {
        this.$store.commit('transactions/setSortDesc', val)
      }
    },
    selected: {
      get () {
        return this.$store.state.transactions.selected
      },
      set (val) {
        this.setSelected(val)
      }
    },
    linkLineHeight() {
      if (this.selected.length < 2) { return 0; }
      const firstElRow = parseInt(document.getElementById(`transactions-table__row_${this.selected[0]._id}`).getAttribute('aria-rowindex'));
      const secondElRow = parseInt(document.getElementById(`transactions-table__row_${this.selected[this.selected.length-1]._id}`).getAttribute('aria-rowindex'));
      let height = 0;
      for (let i = firstElRow + 1; i <= secondElRow; i++) {
        height = height + document.querySelector(`[aria-rowindex="${i}"]`).clientHeight;
      }
      return height;
    }
  },
  methods: {
    ...mapActions(['dbError']),
    ...mapActions('transactions', [
        'fetchTransactionCount',
        'fetchLinkSuggestions',
        'fetchTransactions',
        'linkTransactions',
        'unlinkTransactions'
      ]
    ),
    ...mapMutations('transactions', ['setSelected']),
    refreshTable () {
      if (this.$refs.transactionsTable) { this.setSelected([]); this.$refs.transactionsTable.refresh() }
    },
    onRowSelected (items) {
      this.selected = items;
    },
    printifier (item) {
      const {_showDetails, ...newItem} = item
      return newItem
    },
    async handlePagination (ctx) {
      this.isBusy = true
      try {
        await this.fetchTransactionCount()
        await this.fetchLinkSuggestions()
        const trans = await this.fetchTransactions()
        this.isBusy = false
        return trans;
      } catch (e) {
        this.isBusy = false
        // console.debug(e);
        return [];
      }
    },
    async link (evt) {
      const result = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to link ${this.selected.length} transactions.`, {
        title: 'Are you sure?',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'outline-dark',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      if (!result) { throw new Error('Link cancelled'); }
      await this.linkTransactions({ ids: this.selected.map(t => t._id) });
      this.refreshTable()
    },
    async unlink (parentId, childId) {
      const result = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to unlink this transaction.`, {
        title: 'Are you sure?',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'outline-dark',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      if (!result) { throw new Error('Unlink cancelled'); }
      await this.unlinkTransactions({ ids: [parentId, childId] });
      this.refreshTable()
    },
    isSelected(item) {
      return !!this.selected.find(s => item._id === s._id);
    },
    isFirstSelected(item) {
      return this.selected[0] && this.selected[0]._id === item._id;
    },
    ...truncater(),
    symbolFormatter: (item: Transaction, type: 'sent'|'received'): { amount: Decimal; symbol: string; icon: string }[] => {
      const amountKey = `${type}Amount`;
      const symbolKey = `${type}Symbol`;
      const coinKey = `${type}Coin`;
      const values: { [symbol: string]: { amount: Decimal; symbol: string; icon: string } } = {};
      if (item[amountKey] && new Decimal(item[amountKey]).gt(0) && item[symbolKey]) {
        values[item[symbolKey]] = values[item[symbolKey]] || {
          amount: new Decimal(0),
          symbol: item[symbolKey],
          icon: item[coinKey] ? item[coinKey].icon : null
        }
        values[item[symbolKey]].amount = values[item[symbolKey]].amount.add(item[amountKey]);
      }
      if (item.children) {
        for (const child of item.children) {
          if (child[amountKey] && new Decimal(child[amountKey]).gt(0) && child[symbolKey]) {
            values[child[symbolKey]] = values[child[symbolKey]] || {
              amount: new Decimal(0),
              symbol: child[symbolKey],
              icon: child[coinKey] ? child[coinKey].icon : null
            }
            values[child[symbolKey]].amount = values[child[symbolKey]].amount.add(child[amountKey]);
          }
        }
      }
      return Object.values(values);
    },
    editTransaction (item, child) {
      // console.debug('editTransaction...', item, child);
      Helpers.dbFindById(DbTable.TRANSACTIONS, item._id)
        .then((doc) => {
          this.editTx = TransactionFactory.fromDB(doc);
          if (child) {
            const foundChild = this.editTx.children.find(f => f && f._id === child._id)
            if (foundChild) {
              this.childIdx = this.editTx.children.indexOf(foundChild);
            } else {
              this.childIdx = -1;
            }
          } else {
            this.childIdx = -1;
          }
          // console.debug(this.childIdx);
          this.$nextTick(() => {
            this.$bvModal.show('editTransaction')
          })
        })
        .catch(err => this.dbError(err))
    }
  },
  watch: {
    startDate () {
      this.refreshTable()
    },
    endDate () {
      this.refreshTable()
    },
    symbols () {
      this.refreshTable()
    },
    types () {
      this.refreshTable()
    }
  }
}
