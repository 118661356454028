import { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import { ApiCredentials, BaseApi, BaseApiService, OauthCredentials, TokenCredentials } from "../base-api";
import env from "../env";

export interface GumroadVerifyResponse {
  success: boolean;
  uses: number;
  message: string;
  purchase: any;
}

export default class GumroadApi extends BaseApiService implements BaseApi {
  static API_URL = env.GUMROAD_API_URL;

  constructor(credentials: ApiCredentials | OauthCredentials | TokenCredentials) {
    super(credentials);
  }

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const body = {
        product_permalink: 'KRRDTP',
        license_key: this._credentials.key
      }
      this._service.post('/v2/licenses/verify', qs.stringify(body))
      .then((res: AxiosResponse<GumroadVerifyResponse>) => {
        // console.debug(res.data);
        if (res.data.success) {
          resolve(true);
        } else {
          reject(res.data.message);
        }
      })
      .catch((err: AxiosError) => {
        // console.debug(err);
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      })
    })
  }
}