import Vue from 'vue'
import Vuex from 'vuex'
import transactions from './transactions'
import coins from './coins'
import prices from './prices'
import reports from './reports'
import incomes from './incomes'
import settings from './settings'
import md5 from 'blueimp-md5'
import { NODE_ENV, STAX_APP_ENV } from '@/environment/env.json'

Vue.use(Vuex)

const idify = (msg: any, type: string) => {
  // console.debug(msg);
  return {
    id: md5(new Date().toISOString() + msg.message ? msg.message : (msg ? msg.toString() : 'Something went wrong')),
    text: msg,
    type
  };
}

export default new Vuex.Store({
  state: {
    nimiqConsensus: null,
    nimiqVerificationCode: null,
    messages: [],
    dbReady: false,
    loadingInfo: '',
    purchaseInfo: null,
    productInfo: {
      state: 'productInfo',
      price: '$0.00',
      title: 'Symbol Tax'
    }
  },
  getters: {
    isMasApp: (state: any): boolean => {
      return STAX_APP_ENV === 'mas';
    },
    isWebApp: (state: any): boolean => {
      return STAX_APP_ENV === 'web';
    },
    trialDaysLeft: (state: any): number => {
      const installedAt: Date = state.settings.settings.installedAt;
      if (!installedAt) return 15;
      const today = Date.now();
      const diff = today - installedAt.getTime();
      const daysInTrial = 15;
      const daysUsed = Math.floor(diff / 86400000);
      const daysLeft = daysInTrial - daysUsed;
      return daysLeft < 0 ? 0 : daysLeft;
    },
    isLicensed: (state: any, getters: any): boolean => {
      // console.debug('isLicensed');
      // console.debug(state.settings.settings.apiKeys);
      return !!state.settings.settings.apiKeys.gumroad.key || !!state.settings.settings.apiKeys.nimiq.key || !!state.settings.settings.apiKeys.mas.key;
    },
    isTrial: (state: any, getters: any): boolean => {
      // console.debug('isTrial');
      // console.debug('licensed?...', getters.isLicensed);
      return state.settings.settings.installedAt && !getters.isLicensed && !getters.isMasApp;
    },
    isBasic: (state: any, getters: any): boolean => {
      return getters.isMasApp && !getters.isLicensed;
    },
    isPro: (state: any, getters: any): boolean => {
      return getters.isMasApp && getters.isLicensed;
    },
    isPurchasing: (state: any, getters: any): boolean => {
      return getters.isMasApp && !!state.purchaseInfo && state.purchaseInfo.state === 'purchasing';
    },
    trialExpired: (state: any, getters: any): boolean => {
      return state.settings.settings.installedAt && getters.trialDaysLeft <= 0;
    },
    allDbsReady: (state: any) => {
      return state.transactions.dbReady && state.coins.dbReady && state.prices.dbReady;
    },
    portfolioCoins: (state, getters) => {
      // TODO include not found symbols/contract addresses
      return Object.values(state.transactions.uniqueCoins);
    }
  },
  mutations: {
    dbError (state: any, msg: any) {
      state.messages.push(idify(msg, 'danger'));
    },
    dbWarning (state: any, msg: any) {
      state.messages.push(idify(msg, 'warning'));
    },
    dbSuccess (state: any, msg: any) {
      state.messages.push(idify(msg, 'success'));
    },
    dbInfo (state: any, msg: any) {
      state.messages.push(idify(msg, 'info'));
    },
    purchaseInfo (state: any, msg: any) {
      state.purchaseInfo = msg;
    },
    productInfo (state: any, msg: any) {
      console.debug('pinfo msg', msg);
      state.productInfo = msg;
    },
    dbReady (state: any) {
      state.dbReady = true;
    },
    deleteMessage (state: any, id: string) {
      const foundMessage = state.messages.find(msg => msg.id === id);
      if (foundMessage) {
        state.messages.splice(state.messages.indexOf(foundMessage), 1);
      }
    },
    setNimiqConsensus (state: any, val: string) {
      state.nimiqConsensus = val;
    },
    setNimiqVerificationCode (state: any, val: string) {
      state.nimiqVerificationCode = decodeURIComponent(val);
    },
    setLoadingInfo (state: any, val: string) {
      state.loadingInfo = val;
    }
  },
  actions: {
  },
  modules: {
    transactions,
    coins,
    prices,
    reports,
    settings,
    incomes
  }
})
