<template>
  <div id="volumeChart">
    <bar-chart :chartData="chartdata" :options="options" v-if="chartdata" chartId="volume-chart"></bar-chart>
    <p v-else>No Data... Check your filters.</p>
  </div>
</template>

<script>
import BarChart from '../shared/BarChart.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: { BarChart },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        height: 250,
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        }
      }
    }
  },
  mounted () {
  // console.debug('volume chart created')
    this.fetchGainLossChart()
  },
  computed: {
    ...mapState('reports', {
      proceeds: state => state.chart.proceeds,
      cost: state => state.chart.cost,
      startDate: state => state.filters.startDate,
      endDate: state => state.filters.endDate,
      symbols: state => state.filters.symbols
    }),
    chartdata () {
      const colorsOne = 'rgba(0, 255, 0, 1.0)'; // dataOne.map((value) => value < 0 ? 'rgba(255, 0, 0, 1.0)' : 'rgba(0, 255, 0, 1.0)');
      const bgColorsOne = colorsOne.replace('1.0)', '0.5)');

      const colorsTwo = 'rgba(255, 0, 0, 1.0)'; // dataTwo.map((value) => value < 0 ? 'rgba(255, 0, 0, 1.0)' : 'rgba(0, 255, 0, 1.0)');
      const bgColorsTwo = colorsTwo.replace('1.0)', '0.5)');
      return {
        labels: this.proceeds.labels,
        datasets: [
          {
            label: 'Proceeds',
            data: this.proceeds.values,
            borderColor: colorsOne,
            backgroundColor: bgColorsOne,
            fill: false
          },
          {
            label: 'Cost',
            data: this.cost.values,
            borderColor: colorsTwo,
            backgroundColor: bgColorsTwo,
            fill: false
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('reports', ['fetchGainLossChart'])
  },
  watch: {
    startDate (oldVal, newVal) {
      this.fetchGainLossChart()
    },
    endDate (oldVal, newVal) {
      this.fetchGainLossChart()
    },
    symbols (oldVal, newVal) {
      this.fetchGainLossChart()
    }
  }
}
</script>