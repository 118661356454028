import { DbTable } from "@/helpers/DbTable";
import { PriceData } from "@/models/gain-loss-entry";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { TransactionFilters } from "@/store/interfaces/filters";
import { Income, IncomeDbTransformer } from "@/store/interfaces/income-store-state";
import { sumUpChildrenForTransaction } from "@/store/reports";
import Decimal from "decimal.js-light";
import { dbInsert } from "@/services/db/dbInsert";
import { dbRemove } from "@/services/db/dbRemove";
import { dbFind } from "@/services/db/dbFind";

export function importCalc(payload: any, filters: TransactionFilters, portfolioCoins: any, prices: PriceData[]) {
  return new Promise((resolve, reject) => {
    dbRemove(DbTable.INCOMES, {}, { multi: true })
      .then(numRemoved => {
        payload = payload || {};
        payload['source.accountType'] = { $ne: 'MARGIN' }; // exclude margin...
        dbFind(
          { date: 1, children: 1, receivedSymbol: 1, receivedAmount: 1, receivedValue: 1 },
          DbTable.TRANSACTIONS,
          payload,
          null,
          { date: 1 },
          filters)
          .then((docs: any[]) => {
            const txs: Transaction[] = docs.map(d => TransactionFactory.fromDB(d));
            const incomes: Income[] = [];
            [...txs, ...txs.map(t => t.children)].flat().forEach((t: Transaction) => {
              // console.debug('t in income loop...', t);
              if (t && t.receivedAmount && t.receivedAmount.gt(0)) {
                const income: Income = {
                  date: t.date,
                  symbol: t.receivedSymbol,
                  amount: t.receivedAmount,
                  value: sumUpChildrenForTransaction(t)
                };
                incomes.push(income);
              }
            });
            dbInsert(DbTable.INCOMES, incomes.map(i => IncomeDbTransformer.toDb(i)))
              .then(docsInserted => resolve(docsInserted))
              .catch(err => reject(err));
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
  });
}
