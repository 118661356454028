import { DbTable } from "@/helpers/DbTable";
import { GainLossEntry } from "@/models/gain-loss-entry";
import { TransactionFactory } from "@/models/transaction";
import { dbInsert } from "@/services/db/dbInsert";

export function glCalc(payload: any[]): any {
  return new Promise((resolve, reject) => {
    GainLossEntry.calculator(payload.map(p => TransactionFactory.fromDB(p)))
      .then((gs: GainLossEntry[]) => {
        dbInsert(DbTable.GAINS, gs.map(g => g.toDb))
          .then(docsInserted => resolve(docsInserted))
          .catch(gainsInsertError => reject(gainsInsertError));
      })
      .catch(err => reject(err));
  });
}
