<script>
import Chart from 'chart.js'
import { mixins, Bar} from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    chartId: {
      type: String,
      default: 'my-bar-chart'
    }
  },
  created() {
    window.addEventListener("resize", this.updateChart);
  },
  destroyed() {
    window.removeEventListener("resize", this.udpateChart);
  },
  mounted () {
    this.updateChart()
  },
  // watch: {
  //   chartData () {
  //     this.updateChart()
  //   }
  // },
  methods: {
    updateChart (evt) {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>