import { DbTable } from "@/helpers/DbTable";
import { dbInsert } from "@/services/db/dbInsert";
import { dbFind } from "@/services/db/dbFind";
import { ImportResponse } from "@/services/import-response";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import { BaseImport, BaseImportService } from "../base-import";
import ServiceVerificationFactory from "../service-verification-factory";
import CoinbaseApi from "./coinbase-api";
import { CoinbaseApiTransactionResource, CoinbaseTransactionType } from "./coinbase-api-transaction-resource";
import { linkWalletHashes } from "../etherscan/etherscan-import";
import { LinkWalletsResponse } from "../link-wallets-response";
import { addChildren } from '../db/addChildren';

const typeGrouping = (txs: Transaction[]): { [key: string]: Transaction[] } => {
  return txs.reduce((a: { [key: string]: Transaction[] }, b: Transaction) => {
    if (!b.source.type) { return a; }
    a[b.source.type] = a[b.source.type] || [];
    a[b.source.type].push(b);
    return a;
  }, Object.create({}))
}

const tradeGrouping = (txs: Transaction[], type: CoinbaseTransactionType): { [key: string]: Transaction } => {
  return txs.reduce((a: { [key: string]: Transaction }, b: Transaction) => {
    if (!b.source[type]) { return a; }
    if (a[b.source[type].id]) {
      a[b.source[type].id].children.push(b);
    } else {
      a[b.source[type].id] = b;
    }
    return a;
  }, Object.create({}))
}

const advancedTradeGrouping = (txs: Transaction[]): { [key: string]: Transaction } => {
  return txs.reduce((a: { [key: string]: Transaction }, b: Transaction) => {
    if (!b.source.advanced_trade_fill) { return a; }
    if (a[b.source.advanced_trade_fill.order_id]) {
      a[b.source.advanced_trade_fill.order_id].children.push(b);
    } else {
      a[b.source.advanced_trade_fill.order_id] = b;
    }
    return a;
  }, Object.create({}))
}

const hashGrouping = (txs: Transaction[]): { [key: string]: Transaction } => {
  return txs.reduce((a: { [key: string]: Transaction }, b: Transaction) => {
    if (!b.source.network || (b.source.network && !b.source.network.hash)) { return a; }
    if (a[b.source.network.hash]) {
      a[b.source.network.hash].children.push(b)
    } else {
      a[b.source.network.hash] = b;
    }
    return a;
  }, Object.create({}));
}

const accountGrouping = (txs: Transaction[]): { starting_afters: { [key: string]: string } } => {
  return txs.reduce((a: { starting_afters: { [key: string]: string } }, b: Transaction) => {
    if (!b.source.account_id) { return a; }
    a.starting_afters[b.source.account_id] = b._id;
    return a;
  }, Object.create({ starting_afters: {} }));
}

export const linkOrders = (transactions: Transaction[]): Promise<LinkWalletsResponse>  => {
  return new Promise((resolve, reject) => {
    const walletResponse: LinkWalletsResponse = {
      new: 0,
      linked: 0
    }
    const orders = transactions.map(t => t.order_id).filter(order_id => !!order_id);
    dbFind(null, DbTable.TRANSACTIONS, { 'order_id': { $in: orders } }, null, null)
      .then((docs: Transaction[]) => {
        const matchingOrders: string[] = docs.map(d => d.order_id);
        const otherTransactions: Transaction[] = transactions.filter(tx => {
          return matchingOrders.indexOf(tx.order_id) === -1;
        });

        walletResponse.new = otherTransactions.length;
        Promise.all(docs.map(d => {
          return new Promise((resolve, reject) => {
            const children = transactions.filter(tx => {
                return tx.order_id && d.order_id && tx.order_id === d.order_id;
              });
            while (d.children.length > 0) {
              const nestedChild = d.children.shift();
              children.push(nestedChild);
            }
            walletResponse.linked += children.length;
            addChildren({ id: d._id, children: children.map(child => TransactionFactory.toDB(child)) })
              .then((addChildrenRes) => { resolve(addChildrenRes); })
              .catch((addChildrenErr) => { reject(addChildrenErr); });
          });
        })).then(linkAllDocsRes => {
            // console.debug('other transactions', linkAllDocsRes, otherTransactions.length);
            dbInsert(DbTable.TRANSACTIONS, otherTransactions.map(t => TransactionFactory.toDB(t)))
              .then(newDocs => {
                // console.debug('compare newDocs to estimated...', newDocs.length, walletResponse.new);
                resolve(walletResponse);
              })
              .catch(err => reject(err))
          })
          .catch(allDocsErr => reject(allDocsErr));
      })
      .catch(err => reject(err))
  });
}

export default class CoinbaseImport extends BaseImportService implements BaseImport {
  declare api: CoinbaseApi;
  static importedAccounts: string[] = [];
  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super('coinbase', creds);
  }
  import(): Promise<ImportResponse> {
    return new Promise((resolve, reject) => {
      dbFind(null, DbTable.TRANSACTIONS, { 'source.name': 'Coinbase' }, null, { date: 1 })
        .then((docs: any)  => {
          const acctGroup = accountGrouping(docs.map(d => TransactionFactory.fromDB(d)));
          const importResponse: ImportResponse = {
            new: 0,
            existing: 0,
            linked: 0
          };
          // find latest id for each currency transaction...
          (this.api as CoinbaseApi).fetchTransactions(acctGroup)
            .then(txs => {
              // console.debug('txs...', txs);
              dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(txs), null, { date: 1 })
                .then((matching: any) => {
                  importResponse.existing = matching.length;
                  const newTxs = this.newTxs(txs, matching);


    
                  const typeGroupingTxs = typeGrouping(newTxs);
                  
    
                  const trades = typeGroupingTxs[CoinbaseTransactionType.TRADE] || [];    
                  const linkedTrades = Object.values(tradeGrouping(trades, CoinbaseTransactionType.TRADE));

                  const buys = typeGroupingTxs[CoinbaseTransactionType.BUY] || [];    
                  const linkedBuys = Object.values(tradeGrouping(buys, CoinbaseTransactionType.BUY));

                  const sells = typeGroupingTxs[CoinbaseTransactionType.SELL] || [];
                  const linkedSells = Object.values(tradeGrouping(sells, CoinbaseTransactionType.SELL));


                  const advancedTrades = typeGroupingTxs[CoinbaseTransactionType.ADVANCED_TRADE_FILL] || [];
                  const linkedAdvancedTrades = Object.values(advancedTradeGrouping(advancedTrades));

                  const sends = typeGroupingTxs[CoinbaseTransactionType.SEND] || [];
                  const linkedSends = Object.values(hashGrouping(sends));

                  const rewards = typeGroupingTxs[CoinbaseTransactionType.REWARD] || [];

                  const orders = [...linkedBuys, ...linkedSells, ...linkedTrades, ...linkedAdvancedTrades];
                  
                  const total = sends.length + trades.length + buys.length + sells.length + advancedTrades.length;
                  const linkTotals = linkedSends.length + orders.length;
                  importResponse.linked += (total - linkTotals);

                  linkWalletHashes(sends)
                    .then((linkHashRes: LinkWalletsResponse) => {
                      importResponse.linked += linkHashRes.linked;
                      importResponse.new += linkHashRes.new;

                      linkOrders(orders)
                        .then((linkOrderRes: LinkWalletsResponse) => {
                            importResponse.linked += linkOrderRes.linked;
                            importResponse.new += linkOrderRes.new;
                            dbInsert(DbTable.TRANSACTIONS, rewards.map(t => TransactionFactory.toDB(t)))
                            .then((docs) => {
                              importResponse.new += docs.length
                              resolve(importResponse);
                            })
                            .catch(err => reject(err))
                        })
                        .catch(reject)
                    })
                    .catch(err => reject(err))
                })
                .catch(err => reject(err))
            })
            .catch(err => reject(err))
        })
        .catch(err => reject(err))
    })
  }
}