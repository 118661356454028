import { DbTable } from "@/helpers/DbTable";
import { dbFind } from "@/services/db/dbFind";
import { EtherscanApiTokenTransaction, EtherscanTokenTransaction } from "@/services/etherscan/etherscan-api-token-transaction";
import { Transaction } from "@/models/transaction";
import { CoinstatsDbCoin } from "../coinstats/CoinstatsDbCoin";
import { EtherscanApiBaseTransaction } from "../etherscan/etherscan-api-base-transaction";
import { EtherscanSuperTransaction } from "../etherscan/etherscan-super-transaction";
import { dbUpdate } from "./dbUpdate";


export async function updateCoinsForContracts(): Promise<void> {
  const contractQuery = {
    $or: [
      {
        'source.contractAddress': { $exists: true }
      },
      {
        $where: function() {
          return this.children.map(child => child.source.contractAddress).filter(ca => !!ca).length > 0;
        }
      }
    ]
  }
  const txsWithContractsResult = await dbFind(null, DbTable.TRANSACTIONS, contractQuery, null, null)
  const txsWithContracts = [...txsWithContractsResult, ...(txsWithContractsResult.map(t => t.children))].flat().filter(t => t.source && !!t.source.contractAddress);
  const txContractAddresses = txsWithContracts.map(t => t.source.contractAddress.toLocaleLowerCase());
  // console.debug(txsWithContracts);
  const txAvalancheBridgedSymbols = txsWithContracts.filter(t => t.source.name.startsWith('Avalanche'))
    .map(t => new EtherscanApiTokenTransaction(t.source as EtherscanTokenTransaction, t.source.myAddress, 'Avalanche'))
    .map(t => t._tx.tokenSymbol)
    .filter(sym => !!sym && sym.endsWith('.e'))
    .map(sym => sym.replace('.e', ''));

  const query = {
    $or: [
      {
        symbol: { $in: txAvalancheBridgedSymbols }
      },
      {
        contractAddress: { $in: txContractAddresses }
      },
      {
        $where: function () {
          return (this as CoinstatsDbCoin).exp.map(e => {
            const paths = e.split('/');
            const address = paths[paths.length - 1].toLocaleLowerCase();
            return txContractAddresses.includes(address);
          }).indexOf(true) > -1;
        }
      }
    ]
  };

  const foundCoins: CoinstatsDbCoin[] = (await dbFind(null, DbTable.COINS, query, null, { rank: 1 })) as CoinstatsDbCoin[];
  const foundCoinsMap = foundCoins.reduce((prev, curr) => {
    prev[curr.contractAddress] = curr;
    for (const explorers of curr.exp) {
      const paths = explorers.split('/');
      if (paths[paths.length - 1].startsWith('0x')) {
        prev[paths[paths.length - 1].toLocaleLowerCase()] = curr;
      }
    }
    prev[curr.symbol] = curr;
    return prev;
  }, {});

  // console.debug('found coins...', foundCoinsMap);
  for (const tx of txsWithContracts) {
    // for (const tx of [t].concat(t.children)) {
      const ethTx = new EtherscanApiBaseTransaction(tx.source as EtherscanSuperTransaction, tx.source.myAddress);
      const foundCoin = foundCoinsMap[ethTx._tx.contractAddress.toLocaleLowerCase()];
      if (foundCoin) {
        if (ethTx.isSentFromMe) {
          tx.sentCoinId = foundCoin._id;
          tx.sentCoin = foundCoin;
          tx.sentSymbol = foundCoin.symbol;
        }
        if (ethTx.isSentToMe) {
          tx.receivedCoinId = foundCoin._id;
          tx.receivedCoin = foundCoin;
          tx.receivedSymbol = foundCoin.symbol;
        }
      }
  
      if (tx.source.name.startsWith('Avalanche') && tx.source.tokenSymbol.endsWith('.e')) {
        const foundBridgedSent = foundCoinsMap[tx.sentSymbol];
        const foundBridgedReceived = foundCoinsMap[tx.receivedSymbol];
        if (ethTx.isSentFromMe && foundBridgedSent) {
          tx.sentCoinId = foundBridgedSent._id;
          tx.sentCoin = foundBridgedSent;
          tx.sentSymbol = foundBridgedSent.symbol;
        }
        if (ethTx.isSentToMe && foundBridgedReceived) {
          tx.receivedCoinId = foundBridgedReceived._id;
          tx.receivedCoin = foundBridgedReceived;
          tx.receivedSymbol = foundBridgedReceived.symbol;
        }
      }
    // }
  }

  // for (const t of txsWithContracts)
  await Promise.all(txsWithContractsResult.map(t => dbUpdate(DbTable.TRANSACTIONS, { _id: t._id }, t, null)));
}
