import SymbolTaxDb from "@/services/db/db";
import { GainLossEntry } from "@/models/gain-loss-entry";
import { sumUp } from "../../helpers/sumUp";

// returns object with totals, ex.
// {
//   'BTC': {
//     profit: 45,
//     proceeds: 145,
//     coist: 100
//   }  
// }
export function glCalcTotal(payload: any): any {
  return new Promise((resolve, reject) => {
    SymbolTaxDb.db.gains.find(payload, function (err, dbGains) {
      if (err) {
        reject(err);
      } else {
        const gains = dbGains.map(g => GainLossEntry.fromDb(g));
        const result = {
          all: {
            proceeds: sumUp('proceeds', gains, 'all'),
            cost: sumUp('cost', gains, 'all'),
            profit: sumUp('profit', gains, 'all')
          }
        };
        const uniqueSymbols = [...new Set(gains.map(g => g.symbol))];
        uniqueSymbols.forEach((sym: string) => {
          result[sym] = {
            proceeds: sumUp('proceeds', gains, sym),
            cost: sumUp('cost', gains, sym),
            profit: sumUp('profit', gains, sym)
          };
        });
        resolve(result);
      }
    });
  });
}
