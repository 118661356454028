







































import Decimal from 'decimal.js-light';
import { mapActions, mapGetters, mapState } from 'vuex'
import { Income, IncomeStoreState } from '@/store/interfaces/income-store-state';
import { ReportStoreState } from '@/store/interfaces/report-store-state';
import { truncater } from '@/store/mixins';
export default {
  data () {
    return {
      isBusy: false,
      fields: [
        {
          key: 'date',
          label: 'Date',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Received',
          sortable: true,
        },
        {
          key: 'symbol',
          label: 'Symbol',
          sortable: true,
        },
        {
          key: 'value',
          label: 'Value',
          sortable: true
        }
      ]
    }
  },
  mounted () {
    this.fetchIncome()
  },
  computed: {
    ...mapState('incomes', {
      income: (state: IncomeStoreState) => state.incomes,
      noRows: (state: IncomeStoreState) => state.paginationFields.noRows
    }),
    ...mapState('reports', {
      startDate: (state: ReportStoreState) => state.filters.startDate,
      endDate: (state: ReportStoreState) => state.filters.endDate,
      symbols: (state: ReportStoreState) => state.filters.symbols
    }),
    currentPage: {
      get () {
        return this.$store.state.incomes.paginationFields.currentPage
      },
      set (val) {
        this.$store.commit('incomes/setCurrentPage', val)
      }
    },
    perPage: {
      get () {
        return this.$store.state.incomes.paginationFields.perPage
      },
      set (val) {
        this.$store.commit('incomes/setPerPage', val)
      }
    },
    sortBy: {
      get () {
        return this.$store.state.incomes.paginationFields.sortBy
      },
      set (val) {
        this.$store.commit('incomes/setSortBy', val)
      }
    },
    sortDesc: {
      get () {
        return this.$store.state.incomes.paginationFields.sortDesc
      },
      set (val) {
        this.$store.commit('incomes/setSortDesc', val)
      }
    }
  },
  methods: {
    ...mapActions('incomes', ['fetchIncome', 'fetchIncomeCount']),
    refreshTable () {
      if (this.$refs.incomesTable) { this.$refs.incomesTable.refresh() }
    },
    async handlePagination (ctx) {
      this.isBusy = true
      try {
        await this.fetchIncomeCount()
        const incomes = await this.fetchIncome()
        this.isBusy = false
        return incomes;
      } catch {
        this.isBusy = false
        return [];
      }
    },
    ...truncater()
  },
  watch: {
    startDate (oldVal, newVal) {
      this.refreshTable()
    },
    endDate (oldVal, newVal) {
      this.refreshTable()
    },
    symbols (oldVal, newVal) {
      this.refreshTable()
    }
  }
}
