import { dbFind } from '../dbFind';
import { DbTable } from '@/helpers/DbTable';
import { dbUpdate } from '../dbUpdate';
import { dbFindById } from '../dbFindById';
import { dbInsert } from '../dbInsert';
const migrationId = '20230405';

export async function run(): Promise<void> {
  console.info(`running ${migrationId} migration`);
  const migrationExists = await dbFindById(DbTable.MIGRATIONS, migrationId);
  if (migrationExists) {
    console.info(`skipping, ${migrationId} exists...`);
    return;
  }
  const docs = await dbFind({}, DbTable.TRANSACTIONS, { 'source.name': 'Coinbase' }, null, null);
  for (const doc of docs) {
    let oidKey = 'id';
    if (doc.source.type === 'advanced_trade_fill')
      oidKey = 'order_id';
    const oid = doc.source[doc.source.type] ? doc.source[doc.source.type][oidKey] : null;
    if (oid)
      await dbUpdate(DbTable.TRANSACTIONS, { _id: doc._id }, { $set: { order_id: oid }}, {});
  }
  await dbInsert(DbTable.MIGRATIONS, { _id: migrationId });
  console.info(`${migrationId} complete...`);
}