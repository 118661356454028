<script>
import { mixins, generateChart, Line } from 'vue-chartjs'
const { reactiveProp } = mixins


// Chart.defaults.LineWithLine = Chart.defaults.line;
// Chart.controllers.LineWithLine = Chart.controllers.line.extend({
//    draw: function(ease) {
//       Chart.controllers.line.prototype.draw.call(this, ease);

//       if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
//          const activePoint = this.chart.tooltip._active[0],
//              ctx = this.chart.ctx,
//              x = activePoint.tooltipPosition().x,
//              topY = this.chart.legend.bottom,
//              bottomY = this.chart.chartArea.bottom;

//          // draw line
//          ctx.save();
//          ctx.beginPath();
//          ctx.moveTo(x, topY);
//          ctx.lineTo(x, bottomY);
//          ctx.lineWidth = 2;
//          ctx.strokeStyle = '#07C';
//          ctx.stroke();
//          ctx.restore();
//       }
//    }
// });

// const LineWithLine = generateChart('line-with-line', 'LineWithLine')

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    chartId: {
      type: String,
      default: 'my-line-chart'
    }
  },
  created() {
    window.addEventListener("resize", this.updateChart);
  },
  destroyed() {
    window.removeEventListener("resize", this.udpateChart);
  },
  mounted () {
    this.updateChart()
  },
  // watch: {
  //   chartData () {
  //     this.updateChart()
  //   }
  // },
  methods: {
    updateChart (evt) {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>