import { PriceData } from "@/models/gain-loss-entry";
import Decimal from "decimal.js-light";


export function priceAt(price: PriceData, target: Date): Decimal {
  if (!price || !target)
    return new Decimal(0);
  const chart = price.chart;
  if (!chart || chart.length === 0)
    return new Decimal(0);
  const last = chart[chart.length - 1].x;
  const targetTime = target.getTime();
  const diff: number = last.getTime() - targetTime;
  let idx = chart.length - Math.round(diff / 86400000) - 1;
  let targetFound = false;
  while (chart[idx] && chart[idx - 1] && chart[idx].x && chart[idx - 1].x && !targetFound) {
    const currentDay = chart[idx].x.getTime();
    const prevDAy = chart[idx - 1].x.getTime();
    // console.debug('target, prev, current...', targetTime, prevDAy, currentDay);
    if (targetTime <= currentDay && targetTime >= prevDAy) {
      targetFound = true;
    } else if (targetTime < prevDAy && targetTime < currentDay) {
      idx--;
    } else if (targetTime > currentDay && targetTime > prevDAy) {
      idx++;
    }
    if (idx >= chart.length - 1) {
      idx = chart.length - 1;
      targetFound = true;
    }
    if (idx <= 0) {
      idx = 0;
      targetFound = true;
    }
  }

  if (chart[idx] && chart[idx].y) {
    return new Decimal(chart[idx].y);
  } else {
    return new Decimal(0);
  }
}
