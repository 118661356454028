





































































































































































































































































































































































import env from '@/services/env'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Helpers from '@/helpers/ipc-helpers'
import { ApiCredentials } from '@/services/base-api'
import HubApi, { SignedMessage, SignMessageRequest } from '@nimiq/hub-api'
import { BehaviorType, PopupRequestBehavior, RequestBehavior } from '@nimiq/hub-api/dist/src/client/RequestBehavior'
import faker from 'faker'
import FirebaseService from '@/services/firebase/firebase-service'

interface SettingsData {
  currentTab: 0;
  verificationMessage: string;
  verificationCode: string;
  themeOptions: any;
  calcOptions: any;
  forms: {
    [key: string]: ApiCredentials;
  };
  state: any;
}

const defaultCreds: ApiCredentials = {
  key: null,
  secret: null,
  passphrase: null
}

export default {
  data (): SettingsData {
    return {
      currentTab: 0,
      verificationMessage: null,
      verificationCode: null,
      themeOptions: [
        { text: 'System', value: 'system' },
        { text: 'Dark', value: 'dark' },
        { text: 'Light', value: 'light' }
      ],
      calcOptions: [
        { text: 'FIFO', value: 'fifo' },
        { text: 'LIFO', value: 'lifo' }
      ],
      forms: {
        license: { ...defaultCreds },
        gumroad: { ...defaultCreds },
        etherscan: { ...defaultCreds },
        avalanche: { ...defaultCreds },
        fantom: { ...defaultCreds },
        coinbasePro: { ...defaultCreds },
        coinbase: { ...defaultCreds },
        kucoin: { ...defaultCreds },
        gemini: { ...defaultCreds },
        kraken: { ...defaultCreds },
        nimiq: { ...defaultCreds },
        coinstats: { ...defaultCreds }
      },
      state: {
        gumroad: null,
        etherscan: null,
        avalanche: null,
        fantom: null,
        coinbase: null,
        coinbasePro: null,
        kucoin: null,
        gemini: null,
        kraken: null,
        nimiq: null,
        coinstats: null
      }
    }
  },
  mounted () {
    // console.debug(this.$route.query);
    console.debug('MAS???', this.isMasApp);
    this.fetchSettings();
  },
  computed: {
    ...mapState('settings', ['settings', 'loading']),
    ...mapState(['nimiqVerificationCode', 'productInfo']),
    ...mapGetters(['isWebApp', 'isMasApp', 'isPro', 'isBasic', 'isPurchasing']),
    cbAuthUrl: () => {
      const url = new URL('https://www.coinbase.com/oauth/authorize')
      url.searchParams.append('client_id', env.COINBASE_AUTH_CLIENT_ID)
      url.searchParams.append('redirect_uri', env.COINBASE_AUTH_REDIRECT_URI)
      url.searchParams.append('response_type', 'code')
      url.searchParams.append('scope', 'wallet:user:read,wallet:accounts:read,wallet:transactions:read')
      url.searchParams.append('account', 'all')
      url.searchParams.append('state', JSON.stringify({ provider: 'coinbase' }))
      return url;
    },
    nimiqHubUrl: () => env.NIMIQ_HUB_URL,
    symbolTaxUrl: () => env.SYMBOL_TAX_URL,
    productDetail () {
      return `${this.productInfo.title} (${this.productInfo.price}) *`;
    }
  },
  methods: {
    ...mapMutations(['dbError', 'dbSuccess']),
    ...mapActions('settings', ['updateSetting', 'fetchSettings', 'tokenVerify']),
    updateTheme (val) {
      this.updateSetting({ theme: val })
        .then(res => {
          this.$nextTick(() => {
            Helpers.ipcTheme(this.settings.theme)
          })
        })
        .catch(err => console.error(err))
    },
    updateCalcMethod (val) {
      this.updateSetting({ calcMethod: val })
        .then(res => {
          // console.debug('update setting')
        })
        .catch(err => console.error(err))
    },
    openSymbolTax () {
      Helpers.openLink(this.symbolTaxUrl);
    },
    purchase (evt) {
      Helpers.purchase().then(console.debug).catch(console.error)
    },
    restore (evt) {
      Helpers.restore().then(console.debug).catch(console.error)
    },
    updateLicense () {
      const hashRegexp = new RegExp(/^[A-Fa-f0-9]{64}$/);
      try {
        JSON.parse(Buffer.from(this.forms.license.key, 'base64').toString('utf8'));
        this.updateApiCreds(this.forms.license, 'nimiq', 'Symbol Tax');
      } catch (e) {
        console.error(e);
        if (hashRegexp.test(this.forms.license.key)) {
          const messageId = faker.datatype.uuid();
          this.verificationMessage = encodeURIComponent(`Symbol Tax Verification Message ID: ${messageId}`);
          Helpers.openLink(`${this.symbolTaxUrl}/nimiq/verify/${this.verificationMessage}`);
          this.$refs['nimverify'].show();
        } else {
          this.updateApiCreds(this.forms.license, 'gumroad', 'Symbol Tax');
        }
      }
    },
    verifyNimiq () {
      if (this.verificationMessage) {
        this.forms.license.secret = this.forms.license.key; // hash
        this.forms.license.key = this.verificationCode; // signed message
        this.forms.license.passphrase = this.verificationMessage; // message signed
      } else {
        this.forms.license.key = this.verificationCode;
        this.forms.license.secret = null;
        this.forms.license.passphrase = null;
      }
      // console.debug('forms license', this.forms.license);
      this.updateApiCreds(this.forms.license, 'nimiq', 'Nimiq');
    },
    updateEtherscanCreds () {
      this.updateApiCreds(this.forms.etherscan, 'etherscan', 'Etherscan');
    },
    updateAvalancheCreds () {
      this.updateApiCreds(this.forms.avalanche, 'avalanche', 'snowtrace.io');
    },
    updateFantomCreds () {
      this.updateApiCreds(this.forms.fantom, 'fantom', 'ftmscan.com');
    },
    updatecoinbaseApiCreds (evt) {
      this.updateApiCreds(this.forms.coinbase, 'coinbase', 'Coinbase');
      // this.tokenVerify('coinbase')
      //   .then((res) => { this.state.coinbase = true; console.debug(res); })
      //   .catch((err) => { this.state.coinbase = false; console.error(err); })
    },
    updatecoinbaseProApiCreds () {
      this.updateApiCreds(this.forms.coinbasePro, 'coinbasePro', 'Coinbase pro');
    },
    updateKucoinApiCreds () {
      this.updateApiCreds(this.forms.kucoin, 'kucoin', 'Kucoin');
    },
    updatekrakenApiCreds (evt) {
      this.updateApiCreds(this.forms.kraken, 'kraken', 'Kraken');
    },
    updategeminiApiCreds () {
      this.updateApiCreds(this.forms.gemini, 'gemini', 'Gemini');
    },
    updateCoinstatsApiCreds (evt) {
      this.updateApiCreds(this.forms.coinstats, 'coinstats', 'CoinStats');
    },
    updateApiCreds (creds: ApiCredentials, which: string, title: string) {
      Helpers.serviceVerify(which, creds)
        .then(valid => {
          this.state[which] = valid;
          if (valid === true) {
            const setting = {}
            setting[`apiKeys.${which}`] = this.forms[which === 'nimiq' || which === 'gumroad' ? 'license' : which];
            this.updateSetting(setting)
              .then(() => this.$store.commit('dbSuccess', `${title} ${which === 'gumroad' || which === 'nimiq' ? 'license' : 'credentials'} saved!`))
              .catch(err => this.$store.commit('dbError', err));
          }
        })
        .catch(err => {
          const ipcError = err.toString().split("'service:verify': ");
          this.$store.commit('dbError', ipcError[1] ? ipcError[1] : ipcError[0]);
          this.state[which] = false;        
        });
    },
    updateForm () {
      this.forms.license.key = this.settings.apiKeys.nimiq.key ? this.settings.apiKeys.nimiq.key : this.settings.apiKeys.gumroad.key;
      this.state.gumroad = !!this.settings.apiKeys.gumroad.key;
      this.state.nimiq = !!this.settings.apiKeys.nimiq.key;
      Object.keys(this.forms).forEach(key => {
        if (key !== 'license') {
          this.forms[key] = { ...this.settings.apiKeys[key] };
        }
      });
    },
    resetForms () {
      Object.keys(this.state).forEach(key => {
        this.state[key] = null;
      })
    },
    oauthResponseHandler (msg: any) {
    // console.debug('oath response...', msg);
      const success = msg.success;
      const provider = msg.provider;
      const message = msg.message;
      this.state[provider] = success;
      if (provider === 'coinbase') {
        this.currentTab = 1
      }
      this.fetchSettings()
      if (success) {
        this.$store.commit('dbSuccess', message)
      } else {
        this.$store.commit('dbError', message)
      }
    },
    storePurchase(val) {
      const setting = {}
      setting[`apiKeys.mas`] = {
        key: val,
        secret: '',
        passphrase: ''
      }
      this.updateSetting(setting)
        .then(console.debug)
        .catch(console.error)
    },
    openLink(url) {
      Helpers.openLink(url)
    },
    verifyReceipt() {
      FirebaseService.create()
        .then(service => {
          service.verify()
            .then(res => {
              this.dbSuccess('Purchase is Valid!')
            })
            .catch(err => {
              console.error(err)
              this.storePurchase(null)
            })
        })
        .catch(err => {
          console.error(err)
          this.storePurchase(null)
        })
    }
  },
  watch: {
    settings () {
      this.updateForm();
    },
    nimiqVerificationCode () {
      // console.debug('code watched...', this.nimiqVerificationCode);
      this.verificationCode = this.nimiqVerificationCode.toString();
      this.verifyNimiq();
      setTimeout(() => {
        this.$refs['nimverify'].hide();
        this.verificationMessage = null;
        this.verificationCode = null;
      }, 1000);
    }
  }
}
