import { DbTable } from "@/helpers/DbTable";
import { TransactionFactory } from "@/models/transaction";
import { Transaction } from "@/models/transaction";
import { CoinstatsDbCoin } from "../coinstats/CoinstatsDbCoin";
import { dbFind } from "./dbFind";
import { dbUpdate } from "./dbUpdate";
import { updateCoinsForContracts } from "./update-coins-for-contracts";
import { updateValues } from "./update-values";

const withoutCoins = (tx: Transaction): boolean => {
  return ((!tx.receivedCoin && tx.receivedSymbol) ||
  (!tx.feeCoin && tx.feeSymbol) ||
  (!tx.sentCoin && tx.sentSymbol)) &&
  !tx.source.contractAddress;
}

export const updateNotFoundCoins = async (): Promise<void> => {
  const docs = await dbFind(null, DbTable.TRANSACTIONS, {
      $where: function () {
        return [this, ...this.children].map(t => withoutCoins(t)).includes(true);
      }
    }, null, null);

  const symbolPromises = docs.map(d => TransactionFactory.fromDB(d))
    .map((tx: Transaction) => {
      return updateBySymbols(tx);
    });
  await Promise.all(symbolPromises);
  await updateCoinsForContracts();
  await updateValues();
}

async function updateBySymbols(tx: Transaction): Promise<void> {
  const symbols = [tx, ...tx.children]
    .map(t => [t.feeSymbol, t.sentSymbol, t.receivedSymbol])
    .flat()
    .filter(sym => !!sym);
  // console.debug(symbols)
  const coins: CoinstatsDbCoin[] = await dbFind(null, DbTable.COINS, { symbol: { $in: symbols } }, null, { rank: 1 })
  for (const t of [tx, ...tx.children]) {
    for (const type of ['received', 'sent', 'fee']) {
      if (t[`${type}Symbol`] && !t[`${type}Coin`]) {
        const foundCoin = coins.find(c => c.symbol === t[`${type}Symbol`]);
        if (foundCoin) {
          t[`${type}Coin`] = foundCoin;
          t[`${type}CoinId`] = foundCoin._id;
          t[`${type}Symbol`] = foundCoin.symbol;
        } else {
          t[`${type}CoinId`] = t[`${type}Symbol`];
          t[`${type}Coin`] = { _id: t[`${type}Symbol`], symbol: t[`${type}Symbol`] };
        }
      }
    }
  }
  await dbUpdate(DbTable.TRANSACTIONS, { _id: tx._id }, TransactionFactory.toDB(tx), null);
}
