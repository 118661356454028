<template>
  <b-list-group class="mini-coin-list">
    <b-list-group-item button :active="coinSuggestions.indexOf(suggestion) === selectedCoinIndex" v-for="suggestion in coinSuggestions" :key="suggestion._id" @click="select(suggestion)" :id="idPrefix + suggestion._id">
      {{ suggestion.name }}&nbsp;
      <img :src="suggestion.icon" v-if="suggestion.icon" height="24" class="float-right">
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  props: {
    coinSuggestions: Array,
    idPrefix: String,
    selectedCoinIndex: Number
  },
  methods: {
    select (coin) {
      this.$emit('coinSelected', coin);
    }
  }
}
</script>

<style scoped>
.mini-coin-list {
  position: absolute;
  z-index: 99999;
}
</style>