import { DbTable } from "@/helpers/DbTable";


export function processFilters(filters: any, table: DbTable, payload: any) {
  if (table === DbTable.TRANSACTIONS) {
    payload = payload || {};
    // payload['source.accountType'] = { $ne: 'MARGIN'} // exclude margin...
  }
  if (filters && table === DbTable.TRANSACTIONS) {
    payload.$and = [{
      $where: function () {
        if (filters.types.length === 0 && filters.symbols.length === 0) {
          return true;
        }
        const sentSymbols = [this, ...this.children].map(t => t.sentSymbol).filter(sym => !!sym);
        const receivedSymbols = [this, ...this.children].map(t => t.receivedSymbol).filter(sym => !!sym);
        const isSymbol = (filters.symbols.length === 0 || filters.symbols.filter(sym => [...sentSymbols, ...receivedSymbols].map(sym => sym.split('-#')[0]).includes(sym)).length > 0);
        if (filters.types.length === 0) { return isSymbol; }
        return (isSymbol && filters.types.indexOf('Trades') > -1 && sentSymbols.length > 0 && receivedSymbols.length > 0) ||
          (isSymbol && filters.types.indexOf('Withdrawals') > -1 && sentSymbols.length > 0 && receivedSymbols.length === 0) ||
          (isSymbol && filters.types.indexOf('Deposits') > -1 && sentSymbols.length === 0 && receivedSymbols.length > 0) ||
          (isSymbol && filters.types.indexOf('Fees') > -1 && sentSymbols.length === 0 && receivedSymbols.length === 0);
      }
    }];
  } else if (filters && (table === DbTable.GAINS || table === DbTable.INCOMES)) {
    payload.$or = [{
      $where: function () {
        if (filters.symbols.length === 0) {
          return true;
        } else {
          return filters.symbols.indexOf(this.symbol) > -1;
        }
      }
    }];
  }
}
