import Vue from 'vue'
import App from '../App.vue'
import router from '../router'
import store from '../store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import SymbolTaxDb from '@/services/db/db'
import { dbUpdate } from '@/services/db/dbUpdate'
import './ipc-invoker'
import '../directives'
import { NODE_ENV, STAX_VERSION } from '@/environment/env.json'

declare global {
  interface Window {
    app: any;
    ipcRenderer: any;
    worker: any;
    $vm: any;
    __static: any;
    Nimiq: any;
  }
}
// window.__static = __static;
// console.debug('__static', (window.__static as any));
// console.debug('worker?...', (window as Window).worker);
// console.debug('before store....');
// console.debug('store...', store);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.prototype.$nimiq = window.Nimiq;

SymbolTaxDb.load(Promise.resolve('.'))
  .then(() => {
    dbUpdate('settings', { _id: 'settings' }, { $set: { 'version': STAX_VERSION } }, {})
    .then(res => {
      const vm = new Vue({
        router,
        store,
        render: h => h(App)
      });
      if (NODE_ENV === 'development' || NODE_ENV === 'test') {
        (window as Window).$vm = vm;
      }      
      vm.$mount('#app');
      store.commit('dbReady');
    })
    .catch(err => console.error(err));
  })
  .catch(console.error);

