import Helpers from "@/helpers/ipc-helpers";
import { EtherscanApiInternalTransaction } from "@/services/etherscan/etherscan-api-internal-transaction";
import { EtherscanApiNFTTransaction, EtherscanApiTokenTransaction, EtherscanInternalTransaction, EtherscanNFTTransaction, EtherscanResponse, EtherscanTokenTransaction, EtherscanTransaction } from "@/services/etherscan/etherscan-api-token-transaction";
import { EtherscanApiTransaction } from "@/services/etherscan/etherscan-api-transaction";
import { Transaction } from "@/models/transaction";
import axios, { AxiosResponse } from "axios";
import { ApiCredentials, BaseApi, BaseApiService, OauthCredentials, TokenCredentials } from "../base-api";
import env from '../env'

interface EtherscanParams {
  module: string;
  action: string;
  address: string;
  sort: string;
  page: number;
  offset: number;
  apikey: string;
  startblock: number;
  endblock: number;
}

export interface EtherscanApiResult {
  nextBlock: number;
  results: any[];
}

export default class EtherscanApi extends BaseApiService implements BaseApi {
  static API_URL = env.ETHERSCAN_API_URL;
  address: string;
  name = 'Etherscan';

  constructor(credentials: ApiCredentials | OauthCredentials | TokenCredentials, address?: string, altService?: string) {
    super(credentials);
    this.address = address ? address.toLocaleLowerCase() : null;
    this.timeout = this.apikey === 'YourApiKeyToken' ? 5000 : 300;
    this.name = altService;
  }

  private get apikey(): string {
    return this._credentials.key ? this._credentials.key : 'YourApiKeyToken';
  }

  timeout = 5000;

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const params = {
        module: 'account',
        action: 'balance',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        tag: 'latest',
        apikey: this.apikey
      }
      this._service.get('/api', { params })
      .then(res => {
        const invalidTxt = 'Invalid API Key'
        if (res.data.message.includes(invalidTxt) || res.data.result.includes(invalidTxt) || res.data.status === '0') {
          reject(res.data.result)
        } else {
          resolve(true)
        }
      })
      .catch(err => reject(err))
    })
  }

  async fetchTransactions(block = 0, internalBlock = 0, tokenBlock = 0, nftBlock = 0): Promise<Transaction[]> {
    const results: { [key: string]: Transaction } = {};
    const txList = await this.getTxList(block);
    this.pushTxIfNotExist(txList, results, 'tx');
    const txListInternal = await this.getTxListInternal(internalBlock);
    this.pushTxIfNotExist(txListInternal, results, 'internal');
    const txTokenList = await this.getTxTokenList(tokenBlock);
    this.pushTxIfNotExist(txTokenList, results, 'token');
    const txNFTList = await this.getTxNFTList(nftBlock);
    this.pushTxIfNotExist(txNFTList, results, 'nft');
    return Object.values(results);
  }

  private pushTxIfNotExist(txList: EtherscanTransaction[]|EtherscanInternalTransaction[]|EtherscanTokenTransaction[]|EtherscanApiNFTTransaction[], results: { [key: string]: Transaction }, kind: string) {
    txList.forEach(t => {
      let tInstance: Transaction;
      if (kind === 'internal') {
        tInstance = new EtherscanApiInternalTransaction(t, this.address, this.name);
      } else if (kind === 'token') {
        tInstance = new EtherscanApiTokenTransaction(t, this.address, this.name);
      } else if (kind === 'nft') {
        tInstance = new EtherscanApiNFTTransaction(t, this.address, this.name);
      } else {
        tInstance = new EtherscanApiTransaction(t, this.address, this.name);
      }
      results[tInstance._id] = tInstance;
    });
  }

  private async getTxListInternal(block = 0): Promise<EtherscanInternalTransaction[]> {
    const params = {
      module: 'account',
      action: 'txlistinternal',
      address: this.address,
      sort: 'asc',
      page: 1,
      offset: 10000,
      apikey: this.apikey,
      startblock: block,
      endblock: 9999999999
    }
    const result: EtherscanInternalTransaction[] = [];
    return (await this.apiGet(params)).result as EtherscanInternalTransaction[];
  }

  private async getTxList(block = 0): Promise<EtherscanTransaction[]> {
    const params = {
      module: 'account',
      action: 'txlist',
      address: this.address,
      sort: 'asc',
      page: 1,
      offset: 10000,
      apikey: this.apikey,
      startblock: block,
      endblock: 9999999999
    }
    const result: EtherscanTransaction[] = [];
    return (await this.apiGet(params)).result as EtherscanTransaction[];
  }

  private async getTxNFTList(block = 0): Promise<EtherscanNFTTransaction[]> {
    const params = {
      module: 'account',
      action: 'tokennfttx',
      address: this.address,
      sort: 'asc',
      page: 1,
      offset: 10000,
      startblock: block,
      endblock: 9999999999,
      apikey: this.apikey
    }
    // const result: EtherscanTokenTransaction[] = [];
    return (await this.apiGet(params)).result as EtherscanNFTTransaction[];
  }

  private async getTxTokenList(block = 0): Promise<EtherscanTokenTransaction[]> {
    const params = {
      module: 'account',
      action: 'tokentx',
      address: this.address,
      sort: 'asc',
      page: 1,
      offset: 10000,
      startblock: block,
      endblock: 9999999999,
      apikey: this.apikey
    }
    // const result: EtherscanTokenTransaction[] = [];
    return (await this.apiGet(params)).result as EtherscanTokenTransaction[];
  }

  private apiGet(params: EtherscanParams): Promise<EtherscanResponse> {
    return new Promise((resolve, reject) => {
      this._service.get('/api', { params })
        .then((res: AxiosResponse<EtherscanResponse>) => {
          this.handleError(res, reject);
          resolve(res.data);
        })
        .catch(err => reject(err.message ? err.message : err.toString()));
    });
  }

  private handleError(res: any, reject: (reason?: any) => void) {
    if (res.data.status === '0' && !(res.data.result instanceof Array)) {
      reject(res.data.message);
    }
  }
}