'use strict';
import Datastore from 'nedb';
import { initDb } from './initDb';

export class SymbolTaxDb {
  static db: any = {};
  static async load(dataDirProm: Promise<any>): Promise<void> {
    const dataDir = await dataDirProm;
    this.db.transactions = new Datastore({ filename: `${dataDir}/databases/transactions.db` });
    this.db.coins = new Datastore({ filename: `${dataDir}/databases/coins.db` });
    this.db.prices = new Datastore({ filename: `${dataDir}/databases/prices.db` });
    // possible cache for
    this.db.reports = new Datastore({ filename: `${dataDir}/databases/reports.db` });
    this.db.gains = new Datastore({ filename: `${dataDir}/databases/gains.db` });
    this.db.settings = new Datastore({ filename: `${dataDir}/databases/settings.db` });
    this.db.incomes = new Datastore({ filename: `${dataDir}/databases/incomes.db` });
    this.db.migrations = new Datastore({ filename: `${dataDir}/databases/migrations.db` });
    
    this.db.transactions.persistence.setAutocompactionInterval(300000);
    this.db.gains.persistence.setAutocompactionInterval(350000);
    this.db.coins.persistence.setAutocompactionInterval(400000);
    this.db.prices.persistence.setAutocompactionInterval(450000);
    this.db.reports.persistence.setAutocompactionInterval(500000);
    this.db.incomes.persistence.setAutocompactionInterval(550000);
    this.db.settings.persistence.setAutocompactionInterval(30000);
    console.debug('****#### DB INIT *****#####', dataDir);
    await initDb();
  }
  
  static async clear() {
    await this.db.transactions.remove({}, { multi: true });
    await this.db.gains.remove({}, { multi: true });
    await this.db.coins.remove({}, { multi: true });
    await this.db.prices.remove({}, { multi: true });
    await this.db.reports.remove({}, { multi: true });
    await this.db.incomes.remove({}, { multi: true });
    await this.db.settings.remove({}, { multi: true });
    await this.db.migrations.remove({}, { multi: true });
  }
}

export default SymbolTaxDb;