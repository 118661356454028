import { ApiCredentials, TokenCredentials } from "@/services/base-api";
import { CalcMethod } from "./CalcMethod";

export enum ThemeSetting {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system'
}

export interface SettingsStoreState {
  dbReady: boolean;
  loading: boolean;
  settings: {
    _id: string;
    theme: ThemeSetting;
    currency: string;
    apiKeys: {
      gumroad: ApiCredentials;
      coinbase: ApiCredentials;
      kucoin: ApiCredentials;
      coinbasePro: ApiCredentials;
      etherscan: ApiCredentials;
      avalanche: ApiCredentials;
      fantom: ApiCredentials;
      gemini: ApiCredentials;
      kraken: ApiCredentials;
      nimiq: ApiCredentials;
      mas: ApiCredentials;
      coinstats: ApiCredentials;
    };
    installedAt: Date;
    version: string;
    calcMethod: CalcMethod;
  };
}