<template>
  <div>
    <b-table
      hover
      sticky-header="400px"
      head-variant="light"
      no-border-collapse
      small
      id="gains-table"
      ref="gainsTable"
      :items="handlePagination"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      primary-key="_id">

      <!-- <template #cell(details)="row">
        <b-button size="sm" variant="outline">
          <b-icon :icon="row.detailsShowing ? 'caret-down-fill' : 'caret-right-fill'" @click="row.toggleDetails"></b-icon>
        </b-button>
      </template> -->

      <template #row-details="row">
        <pre>{{ JSON.stringify(row.item, null, 2) }}</pre>
      </template>

      <template #cell(description)="data">
        {{ truncateText(data.value, 30) }}
      </template>

      <template #cell(boughtAt)="data">
        {{ data.value ? data.value.toISOString().split('T')[0] : '' }}
        <b-icon-exclamation-triangle-fill class="text-warning" title="Original purchase not found! Review transactions" v-if="!data.value"></b-icon-exclamation-triangle-fill>
      </template>

      <template #cell(soldAt)="data">
        {{ data.value ? data.value.toISOString().split('T')[0] : '' }}
      </template>

      <template #cell(profit)="data">
        <span class="text-success" v-if="data.value > 0">{{ data.value }}</span>
        <span class="text-danger" v-else-if="data.value < 0">({{ Math.abs(data.value) }})</span>
        <span class="text-muted" v-else>{{ data.value }}</span>
      </template>

      <template #cell(cost)="data">
        {{ data.value }} <b-icon-exclamation-triangle-fill class="text-warning" title="Amount sold is greater than orignally bought, verify orignal buy" v-if="data.item._buy && data.item._sell.sentAmount > data.item._buy.receivedAmount"></b-icon-exclamation-triangle-fill>
      </template>
    </b-table>

    <b-pagination
      size="sm"
      v-model="currentPage"
      :total-rows="noRows"
      :per-page="perPage"
      v-if="noRows > perPage"
      active-class="bg-light text-primary"
      align="center"
      aria-controls="gains-table"
    ></b-pagination>
  </div>
</template>

<script>
import { truncater } from '@/store/mixins'
import { mapActions, mapState } from 'vuex'
// TODO mixin/extend...
export default {
  data () {
    return  {
      isBusy: false,
      fields: [
        {
          key: 'details',
          label: '',
          sortable: false
        },
        {
          key: 'description',
          sortable: false
        },
        {
          key: 'boughtAt',
          label: 'Acquired',
          sortable: true
        },
        {
          key: 'soldAt',
          label: 'Sold',
          sortable: true
        },
        {
          key: 'proceeds',
          sortable: true
        },
        {
          key: 'cost',
          label: 'Cost Basis',
          sortable: true
        },
        {
          key: 'profit',
          label: 'Gain/Loss',
          sortable: true
        }
      ]
    }
  },
  computed: {
    ...mapState('reports', {
      startDate: state => state.filters.startDate,
      endDate: state => state.filters.endDate,
      symbols: state => state.filters.symbols,
      noRows: state => state.paginationFields.noRows
    }),
    // TODO create mixins...
    currentPage: {
      get () {
        return this.$store.state.reports.paginationFields.currentPage
      },
      set (val) {
        this.$store.commit('reports/setCurrentPage', val)
      }
    },
    perPage: {
      get () {
        return this.$store.state.reports.paginationFields.perPage
      },
      set (val) {
        this.$store.commit('reports/setPerPage', val)
      }
    },
    sortBy: {
      get () {
        return this.$store.state.reports.paginationFields.sortBy
      },
      set (val) {
        this.$store.commit('reports/setSortBy', val)
      }
    },
    sortDesc: {
      get () {
        return this.$store.state.reports.paginationFields.sortDesc
      },
      set (val) {
        this.$store.commit('reports/setSortDesc', val)
      }
    }
  },
  methods: {
    ...mapActions('reports', ['fetchReports', 'fetchReportsCount']),
    refreshTable () {
      if (this.$refs.gainsTable) { this.$refs.gainsTable.refresh() }
    },
    async handlePagination (ctx) {
      this.isBusy = true
      try {
        await this.fetchReportsCount()
        const reports = await this.fetchReports()
        this.isBusy = false
        return reports;
      } catch {
        this.isBusy = false
        return [];
      }
    },
    ...truncater()
  },
  watch: {
    startDate () {
      this.refreshTable()
    },
    endDate () {
      this.refreshTable()
    },
    symbols () {
      this.refreshTable()
    }
  }
}
</script>