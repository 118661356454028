import { Transaction } from "@/models/transaction";
import { AxiosResponse, AxiosError } from "axios";
import md5 from "blueimp-md5";
import Decimal from "decimal.js-light";
import { ApiCredentials, BaseApi, OauthCredentials, TokenCredentials } from "../base-api";
import CoinbaseApi from "../coinbase/coinbase-api";
import env from "../env";

export interface CoinbaseProPaginationRequest {
  afters?: { [product: string]: number };
  befores?: { [product: string]: number };
}

export default class CoinbaseProApi extends CoinbaseApi implements BaseApi {
  static API_URL = env.COINBASE_PRO_API_URL;
  static API_VERSION = 'v2';
  timeout = 100;
  constructor(credentials: ApiCredentials | OauthCredentials | TokenCredentials) {
    super(credentials)
  }
  
  headers (timestamp, method, path, body, query): any {
    return {
      ...super.headers(timestamp, method, path, body, true, query),
      'CB-ACCESS-PASSPHRASE': this._credentials.passphrase
    }
  }
  verify(): Promise<boolean> {
    const path = `/accounts`;
    return new Promise((resolve, reject) => {
      const timestamp = Date.now();
      this._service.get(path, {
        headers: this.headers(timestamp, 'GET', path, '', {})
      })
        .then((res: AxiosResponse<any>) => {
          return resolve(true);
        })
        .catch((err: AxiosError|any) => reject(this.handleError(err)))
    });
  }

  async fetchTransactions(pagination: CoinbaseProPaginationRequest = {}): Promise<Transaction[]> {
    const products: CoinbaseProApiProduct[] = await this.getProducts();
    let results: Transaction[] = []
    for (const product of products) {
      const fills: CoinbaseProApiFill[] = await this.getFills(product, pagination);
    // console.debug('fillls...', fills);
      results = results.concat(fills.map(f => new CoinbaseProFill(f)));
    }
    return results;
  }

  getFills(product: CoinbaseProApiProduct, pagination: CoinbaseProPaginationRequest = {}): Promise<CoinbaseProApiFill[]> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      searchParams.append('product_id', product.id);
      if (pagination.befores && pagination.befores[product.id]) {
        searchParams.append('before', pagination.befores[product.id].toString());
      } else if (pagination.afters && pagination.afters[product.id]) {
        searchParams.append('after', pagination.afters[product.id].toString());
      }
      const path = `/fills?${searchParams}`;
      this._service.get(path, {
        headers: this.headers(Date.now(), 'GET', path, '', {})
      })
        .then((res: AxiosResponse) => {
          resolve(res.data.map(f => {
            return {
              product,
              ...f
            }
          }));
        })
        .catch((err: AxiosError) => reject(this.handleError(err)))
    });
  }

  getProducts(): Promise<CoinbaseProApiProduct[]> {
    return new Promise((resolve, reject) => {
      const path = '/products';
      this._service.get(path, {
        headers: this.headers(Date.now(), 'GET', path, '', {})
      })
        .then((res: AxiosResponse) => {
          resolve(res.data.map(p => {
            return {
              id: p.id,
              base_currency: p.base_currency,
              quote_currency: p.quote_currency
            }
          }));
        })
        .catch((err: AxiosError) => reject(this.handleError(err)))
    });
  }

}

export interface CoinbaseProApiProduct {
  id: string;
  base_currency: string;
  quote_currency: string;
}

export interface CoinbaseProApiFill {
  product: CoinbaseProApiProduct;
  created_at: string;
  trade_id: number;
  product_id: string;
  order_id: string;
  user_id: string;
  profile_id: string;
  liquidity: string;
  price: string;
  size: string;
  fee: string;
  side: string;
  settled: boolean;
  usd_volume: string;
}

export class CoinbaseProFill implements Transaction {
  private _fill: CoinbaseProApiFill;
  constructor(fill: CoinbaseProApiFill) {
    this._fill = fill
  }
  // TODO
  get date(): Date { return new Date(this._fill.created_at); }

  get receivedSymbol(): string {
    if (this._fill.side === 'buy') {
      return this._fill.product.base_currency;
    } else {
      return this._fill.product.quote_currency;
    }
  }

  get amount(): Decimal {
    return new Decimal(this._fill.size);
  }

  get receivedAmount(): Decimal {
    if (this._fill.side === 'buy') {
      return this.amount;
    } else {
      return this.amount.mul(this._fill.price);
    }
  }
  get sentSymbol(): string {
    if (this._fill.side === 'buy') {
      return this._fill.product.quote_currency;
    } else {
      return this._fill.product.base_currency;
    }
  }
  get sentAmount(): Decimal {
    if (this._fill.side === 'buy') {
      return this.amount.mul(this._fill.price);
    } else {
      return this.amount;
    }
  }
  get price(): Decimal { return new Decimal(this._fill.price) }
  get fee(): Decimal { return new Decimal(this._fill.fee) }
  get feeSymbol(): string { return this._fill.product.quote_currency }
  get description(): string { return `Traded ${this.sentAmount} ${this.sentSymbol} for ${this.receivedAmount} ${this.receivedSymbol} on Coinbase Pro`; }
  get source(): any {
    return {
      name: 'Coinbase Pro Fill',
      ...this._fill
    }
  }
  get _id(): string { return md5(JSON.stringify(this.source)); }
  get children(): Transaction[] { return []; }

  get asTransaction(): Transaction {
    return {
      _id: this._id,
      date: this.date,
      receivedSymbol: this.receivedSymbol,
      receivedAmount: this.receivedAmount,
      sentSymbol: this.sentSymbol,
      sentAmount: this.sentAmount,
      fee: this.fee,
      feeSymbol: this._fill.product.quote_currency,
      price: this.price,
      description: this.description,
      children: [],
      source: this.source
    }
  }
}