import { Transaction } from "@/models/transaction";
import md5 from "blueimp-md5";
import Decimal from "decimal.js-light";
import { CoinstatsDbCoin } from "../coinstats/CoinstatsDbCoin";
import { EtherscanSuperTransaction } from "./etherscan-super-transaction";

export const blockchains = {
  'Etherscan': 'ETH',
  'Avalanche': 'AVAX',
  'Fantom': 'FTM'
}

const coins = {
  Etherscan: {
    _id:'ethereum',
    icon: 'https://static.coinstats.app/coins/EthereumOCjgD.png',
    name: 'Ethereum',
    symbol: 'ETH',
    rank: 2,
    totalSupply: 0,
    websiteUrl: 'https://www.ethereum.org/',
    twitterUrl: 'https://twitter.com/ethereum',
    contractAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    decimals: 18,
    exp: [
      'https://etherscan.io/',
      'https://ethplorer.io/',
      'https://blockchair.com/ethereum',
      'https://eth.tokenview.com/',
      'https://moonriver.moonscan.io/token/0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c'
    ]
  },
  Avalanche: {
    _id: 'avalanche-2',
    icon: 'https://static.coinstats.app/coins/avalanche-2IQm.png',
    name: 'Avalanche',
    symbol: 'AVAX',
    rank: 11,
    totalSupply: 377752194.4695483,
    websiteUrl: 'https://www.avax.network/',
    twitterUrl: 'https://twitter.com/avalancheavax',
    contractAddress: '0xd26649b3eb22eb275326a8cb052d2f4736c863cf',
    decimals: 18,
    exp: [
      'https://avascan.info/',
      'https://explorer.avax.network/',
      'https://moonriver.moonscan.io/token/0x14a0243c333a5b238143068dc3a7323ba4c30ecb',
      'https://snowtrace.io/token/FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z'
    ]
  },
  Fantom: {
    _id: 'fantom',
    icon: 'https://static.coinstats.app/coins/FantomQxjPf.png',
    name: 'Fantom',
    symbol: 'FTM',
    rank: 29,
    totalSupply: 3175000000,
    websiteUrl: 'http://fantom.foundation',
    twitterUrl: 'https://twitter.com/FantomFDN',
    redditUrl: 'https://www.reddit.com/r/FantomFoundation',
    contractAddress: '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    decimals: 18,
    exp: [
      'http://ftmscan.com/',
      'https://etherscan.io/token/0x4e15361fd6b4bb609fa63c81a2be19d873717870',
      'https://ethplorer.io/address/0x4e15361fd6b4bb609fa63c81a2be19d873717870',
      'https://explorer.fantom.network/',
      'https://bscscan.com/token/0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
      'https://moonriver.moonscan.io/token/0xad12dab5959f30b9ff3c2d6709f53c335dc39908'
    ]
  }
}

export class EtherscanApiBaseTransaction implements Transaction {
  _tx: EtherscanSuperTransaction;
  _myAddress: string;
  children: Transaction[] = [];
  public static coins = coins;

  name: string;
  get currencySymbol(): string { return blockchains[this.name]; }
  get receivedCoin(): CoinstatsDbCoin { return this.isSentToMe ? EtherscanApiBaseTransaction.coins[this.name] : null; }
  get receivedCoinId(): string { return this.receivedCoin ? this.receivedCoin._id : null; }
  get sentCoin(): CoinstatsDbCoin { return this.isSentFromMe ? EtherscanApiBaseTransaction.coins[this.name] : null; }
  get sentCoinId(): string { return this.sentCoin ? this.sentCoin._id : null; }
  get feeCoin(): CoinstatsDbCoin { return EtherscanApiBaseTransaction.coins[this.name]; }
  get feeCoinId(): string { return this.feeCoin ? this.feeCoin._id : null; }

  constructor(tx: EtherscanSuperTransaction, myAddress: string, altService?: string) {
    this._tx = tx;
    this._myAddress = myAddress.toLocaleLowerCase();
    this.name = altService ? altService : 'Etherscan';
  }

  get isSentToMe(): boolean {
    return this._tx.to && this._tx.to.toLowerCase() === this._myAddress;
  }

  get isSentFromMe(): boolean {
    return this._tx.from && this._tx.from.toLowerCase() === this._myAddress;
  }

  get date(): Date {
    return new Date(Number(this._tx.timeStamp + '000'));
  }

  get description(): string {
    return `${this.currencySymbol} tx ${this._tx.hash.substring(0, 8)}... from ${this.name}`;
  }

  get hash(): string {
    return this._tx.hash.startsWith('0x') ? this._tx.hash.substring(2, this._tx.hash.length) : this._tx.hash;
  }

  get asTransaction(): Transaction {
    return {
      _id: this._id,
      children: this.children,
      date: this.date,
      receivedSymbol: this.receivedSymbol,
      receivedAmount: this.receivedAmount,
      receivedCoinId: this.receivedCoinId,
      receivedCoin: this.receivedCoin,
      sentSymbol: this.sentSymbol,
      sentAmount: this.sentAmount,
      sentCoinId: this.sentCoinId,
      sentCoin: this.sentCoin,
      price: this.price,
      fee: this.fee,
      feeSymbol: this.feeSymbol,
      feeCoinId: this.feeCoinId,
      feeCoin: this.feeCoin,
      description: this.description,
      source: this.source,
      hash: this.hash
    }
  }

  get _id(): string {
    const { confirmations, ...newSource } = this.source;
    return md5(JSON.stringify(newSource));
  }

  get receivedSymbol(): string {
    if (this.receivedAmount && this.receivedAmount.gt(0)) {
      return this.currencySymbol;
    } else {
      return null;
    }
  }

  get receivedAmount(): Decimal {
    if (this.isSentToMe) {
      return this.amount;
    } else {
      return new Decimal(0);
    }
  }

  get sentSymbol(): string {
    if (this.sentAmount && this.sentAmount.gt(0)) {
      return this.currencySymbol;
    } else {
      return null;
    }
  }

  get sentAmount(): Decimal {
    if (this.isSentFromMe) {
      return this.amount;
    } else {
      return new Decimal(0);
    }
  }

  get amount(): Decimal {
    return new Decimal(this._tx.value).mul(new Decimal(1e-18));
  }

  get price(): Decimal {
    return new Decimal(0);
  }

  get fee(): Decimal {
    return new Decimal(0);
  }

  get feeSymbol(): string {
    return this.currencySymbol;
  }

  get source(): any {
    return {
      name: `${this.name} Tx`,
      ...this._tx,
      myAddress: this._myAddress
    }
  }
}