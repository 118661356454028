import { AxiosError, AxiosResponse } from "axios";
import { BaseApi, BaseApiService, ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import env from "../env";
import { CoinstatsDbCoin } from "./CoinstatsDbCoin";

const convertCoinToDb = (coin: CoinstatsApiCoin): CoinstatsDbCoin => {
  return {
    _id: coin.id,
    icon: coin.icon,
    name: coin.name,
    symbol: coin.symbol,
    rank: coin.rank,
    totalSupply: coin.totalSupply,
    websiteUrl: coin.websiteUrl,
    twitterUrl: coin.twitterUrl,
    redditUrl: coin.redditUrl,
    contractAddress: coin.contractAddress ? coin.contractAddress.toLocaleLowerCase() : null,
    decimals: coin.decimals,
    exp: coin.explorers
  }
}

/**
 *  {
      "id": "binance-coin",
      "icon": "https://static.coinstats.app/coins/1666608145347.png",
      "name": "BNB",
      "symbol": "BNB",
      "rank": 4,
      "price": 227.79750268483863,
      "priceBtc": 0.006114351385270075,
      "volume": 946862327.2760727,
      "marketCap": 35048046742.70393,
      "availableSupply": 153856150,
      "totalSupply": 153856150,
      "priceChange1h": 0.22,
      "priceChange1d": -2.02,
      "priceChange1w": -12.56,
      "redditUrl": "https://www.reddit.com/r/binance",
      "websiteUrl": "https://www.binance.com",
      "twitterUrl": "https://twitter.com/binance",
      "contractAddress": "BNB",
      "decimals": 18,
      "explorers": [
        "https://bscscan.com",
        "https://explorer.binance.org/",
        "https://binance.mintscan.io/",
        "https://etherscan.io/token/0xb8c77482e45f1f44de1745f52c74426c631bdd52",
        "https://ethplorer.io/address/0xb8c77482e45f1f44de1745f52c74426c631bdd52",
        "https://www.oklink.com/bsc",
        "https://3xpl.com/bnb",
        "https://explorer.energi.network/token/0xc3c19ee91cf3c1f7fbf3716a09d21dc35de0bd6d",
        "https://etherscan.io/token/0xB8c77482e45F1F44dE1745F52C74426C631bDD52"
      ]
    }
 */
export interface CoinstatsApiCoin {
  id: string;
  icon: string;
  name: string;
  symbol: string;
  rank: number;
  price: number;
  priceBtc: number;
  volume: number;
  marketCap: number;
  availableSupply: number;
  totalSupply: number;
  priceChange1h: number;
  priceChange1d: number;
  priceChange1w: number;
  websiteUrl: string;
  twitterUrl: string;
  redditUrl: string;
  contractAddress: string;
  decimals: number;
  explorers: string[];
}

export interface CoinstatsApiCoinResponse {
  result: CoinstatsApiCoin[];
}

export class CoinstatsApi extends BaseApiService implements BaseApi {
  static API_URL = env.COINSTATS_API_URL;
  timeout = 200;

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super(creds);
  }

  private get headers() {
    return {
      'X-API-Key': this._credentials.key
    }
  }

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getCoins(0,1).then((res)=> resolve(true))
        .catch((err) => reject(err))
    })
  }

  getCoins(skip = 0, limit = 1000): Promise<CoinstatsDbCoin[]> {
    return new Promise((resolve, reject) => {
      this._service.get('/coins', { headers: this.headers, params: {
        page: skip + 1,
        limit,
        currency: 'USD'
      }}).then((res: AxiosResponse<CoinstatsApiCoinResponse>) => resolve(res.data.result.map(c => convertCoinToDb(c))))
        .catch((err: AxiosError) => reject(this.handleError(err)));
    })
  }

  getPrices(coin: CoinstatsDbCoin) {
    // TODO
  }

  private handleError(err: AxiosError) {
    if (err.response && err.response.data.message) {
      return err.response.data.message;
    } else if (err.message) {
      return err.message;
    } else {
      return err.toString();
    }
  }
}