import { ApiCredentials, BaseApiService, OauthCredentials, TokenCredentials } from "../base-api";
import crypto from 'crypto'

export default class SignatureService extends BaseApiService {
    static publicKey(): any {
      return crypto.createPublicKey(`
-----BEGIN RSA PUBLIC KEY-----
MIICCgKCAgEAthR/dsfiEm7UWRnqZCIqtot8bjtgfQF5TaukeiG262OcAgPEY98q
cnYURnyMTg//LcTwMmp/VW4xZYw+uXhBB7Yuotdc65rPbUeIsWRENf5r6tRwlgFS
GVzLhC8v8SixuJIwDTjTgYaG8HTGj3gSJ1/nsY7LnvnXVXjmXFUoomXqcXdzGFfJ
gwtLVblqs8scN4lrzzAFX5cIR039fiYS52LwdPWeauw7cSX+l/QW4Ekw2hJu4QbI
jKZrwwVqyW5FAo4thAq0wQsiqb/a+dqY06dHt7Vz5LpDFTDQ/AbgzVgRKtL8lxGN
mMEQ9d0bstuqW1RJapU1SOuQ2dZ4EHldLjAW0AgLJ2TNgqWHurUIo7S0C1HY+hdr
fGxM8+lUOBQSozgs4cxNMiJ7wtLWGe7SChOyGuQvacmLiF2c6ltVZGEuCr18CDUV
CSumWNB/Ss/GgNQwqbR1LU3n0ViQN47haJEdhmhwIE+e+fVEB0rVfRB/+pSI4X4b
CZ1gW61z2xkjBkYoAAsR8jo8jYf8viXwsnRLqplJvH/rpVd2rnWz7MhWopS5JbcI
1unT5QU/cXUpr88nnLstsBc74oU6f+EKOmYXXF53KHNo7ji9YYjk7fzE7pwiZ4iM
9CSO+UyTP9BgvWJe+MR8Rpndm1GjuZZBMjYZyl1TDE+cjuhnFUTlukkCAwEAAQ==
-----END RSA PUBLIC KEY-----
      `);
    }

    constructor(credentials: ApiCredentials | OauthCredentials | TokenCredentials) {
      super(credentials);
    }

    verify(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        try {
          resolve(crypto.verify(
            "sha256",
            Buffer.from(this._credentials.key, 'base64'), // data
            {
              key: SignatureService.publicKey(),
              padding: crypto.constants.RSA_PKCS1_PSS_PADDING,
            },
            Buffer.from(this._credentials.secret, 'base64') // signature
          ));
        } catch(e) {
          reject(e)
        }
      })
    }
}