<template>
  <div id="volumeChart">
    <bar-chart :chartData="chartdata" :options="options" v-if="chartdata" chartId="volume-chart"></bar-chart>
    <p v-else>No Data... Check your filters.</p>
  </div>
</template>

<script>
import BarChart from '../shared/BarChart.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: { BarChart },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        height: 250,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        }
      }
    }
  },
  mounted () {
    this.fetchIncomeChart()
  },
  computed: {
    ...mapState('incomes', ['buckets', 'chart']),
    ...mapState('reports', {
      startDate: state => state.filters.startDate,
      endDate: state => state.filters.endDate,
      symbols: state => state.filters.symbols
    }),
    chartdata () {
      const { labels, values } = this.chart;
      const colorsOne = 'rgba(0, 255, 0, 1.0)';
      const bgColorsOne = colorsOne.replace('1.0)', '0.5)');
      return {
        labels,
        datasets: [
          {
            data: values,
            borderColor: colorsOne,
            backgroundColor: bgColorsOne,
            fill: false
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('incomes', ['fetchIncomeChart'])
  },
  watch: {
    startDate (oldVal, newVal) {
      this.fetchIncomeChart()
    },
    endDate (oldVal, newVal) {
      this.fetchIncomeChart()
    },
    symbols (oldVal, newVal) {
      this.fetchIncomeChart()
    }
  }
}
</script>