import { BaseApiService, BaseApi, ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import env from "../env";
import qs from 'qs';
import crypto from 'crypto';
import { Transaction } from "@/models/transaction";
import { AxiosResponse } from "axios";
import { KrakenTransaction } from "./kraken-transaction";
import AdmZip from 'adm-zip';
import parse from 'csv-parse';

export interface KrakenApiTransaction {
  refid: string;
  time: number;
  type: string;
  subtype: string;
  aclass: string;
  asset: string;
  amount: string;
  fee: string;
  balance: string;
}

export interface KrakenCsvTransaction extends KrakenApiTransaction {
  txid: string;
}

export interface KrakenApiTransactionWithId extends KrakenApiTransaction {
  id: string;
}

export interface KrakenApiLedger {
  ledger: {
    [id: string]: KrakenApiTransaction;
  };
}

export interface KrakenApiResponse {
  error: string[];
  result: any;
}

export interface KrakenApiLedgerResponse extends KrakenApiResponse {
  result: KrakenApiLedger;
}

export default class KrakenApi extends BaseApiService implements BaseApi {
  public static API_URL = env.KRAKEN_API_URL;
  timeout = 334;

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super(creds)
  }

  private signature(path, body) {
    if (!body.nonce) {
      throw new Error('No nonce set!');
    }
    const secret = this._credentials.secret;
    const nonce = body.nonce.toString();
    const message = qs.stringify(body);
    const secret_buffer = Buffer.from(secret, 'base64');
    // @ts-ignore
    const hash_digest = crypto.createHash('sha256').update(nonce + message).digest('binary');
    const hmac_digest = crypto.createHmac('sha512', secret_buffer).update(path + hash_digest, 'binary').digest('base64');
    return hmac_digest;
  }

  private headers(path, body) {
    return {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      'API-Key': this._credentials.key,
      'API-Sign': this.signature(path, body)
    }
  }

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const path = '/0/private/Ledgers';
      const body = {
        nonce: Date.now()
      };
      this._service.post(path, qs.stringify(body), {
          headers: this.headers(path, body)
        })
        .then((res: AxiosResponse<KrakenApiLedgerResponse>) => {
          if (res.data.error && res.data.error.length > 0) {
            reject(res.data.error[0]);
          } else {
            resolve(true)
          }
        })
        .catch(err => reject(err))
    })
  }

  private parseCsv(csv: string): Promise<KrakenCsvTransaction[]> {
    return new Promise((resolve, reject) => {
      parse(csv.trim(), { columns: true }, (err, csvTxs: KrakenCsvTransaction[]) => {
        if (err) {
          reject(err);
        } else {
          resolve(csvTxs);
        }
      });
    })
  }

  async fetchTransactions(starttm = 1): Promise<Transaction[]> {
    this.timeout = 3000;
    const exportId = await this.requestExport(starttm);
    let status = false;
    while (!status) {
      status = await this.exportStatus(exportId);
    }
    this.timeout = 334;
    const csv = await this.getExport(exportId);
    const csvTxs = await this.parseCsv(csv);
    await this.deleteExport(exportId);
    return csvTxs.filter(t => !!t.txid).map(t => new KrakenTransaction(t, t.txid));
  }

  private requestExport(starttm: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const path = '/0/private/AddExport';
      const body = {
        nonce: Date.now(),
        report: 'ledgers',
        description: 'Symbol Tax export',
        starttm
      };
      this._service.post(path, qs.stringify(body), {
          headers: this.headers(path, body)
        })
        .then((res: AxiosResponse<KrakenApiResponse>) => {
          if (res.data.error && res.data.error.length > 0) {
            reject(res.data.error[0]);
          } else {
            resolve(res.data.result.id);
          }
        })
        .catch(err => reject(err));
    });
  }

  private exportStatus(id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const path = '/0/private/ExportStatus';
      const body = {
        nonce: Date.now(),
        report: 'ledgers'
      };
      this._service.post(path, qs.stringify(body), {
          headers: this.headers(path, body)
        })
        .then((res: AxiosResponse<KrakenApiResponse>) => {
          if (res.data.error && res.data.error.length > 0) {
            reject(res.data.error[0]);
          } else {
            resolve(res.data.result.find(report => report.id === id).status === 'Processed');
          }
        })
        .catch(err => reject(err));
    });
  }

  private getExport(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const path = '/0/private/RetrieveExport';
      const body = {
        nonce: Date.now(),
        id: id
      };
      this._service.post(path, qs.stringify(body), {
          headers: this.headers(path, body),
          responseType: 'arraybuffer'
        })
        .then((res: AxiosResponse<ArrayBuffer>) => {
          try {
            const zip = new AdmZip(Buffer.from(res.data));
            const entry = zip.getEntry('ledgers.csv');
            resolve(entry.getData().toString('utf8'));
          } catch (e) {
            reject(e);
          }
        })
        .catch(err => reject(err))
    });
  }

  private deleteExport(id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const path = '/0/private/RemoveExport';
      const body = {
        nonce: Date.now(),
        id: id,
        type: 'delete'
      };
      this._service.post(path, qs.stringify(body), {
          headers: this.headers(path, body),
        })
        .then((res: AxiosResponse<KrakenApiResponse>) => {
          try {
            resolve(res.data.result.delete)
          } catch (e) {
            reject(e);
          }
        })
        .catch(err => reject(err))
    });
  }
}
