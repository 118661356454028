import SymbolTaxDb from "@/services/db/db";
import { DbTable } from "@/helpers/DbTable";
import { processFilters } from "@/services/db/processFilters";

export function dbCount(table: DbTable, payload: any, filters: any): any {
  return new Promise((resolve, reject) => {
    if (filters) {
      processFilters(filters, table, payload);
    }
    SymbolTaxDb.db[table].count(payload || {}, function (err, res) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}
