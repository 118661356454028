import { DbTable } from "@/helpers/DbTable";
import { GainLossEntry } from "@/models/gain-loss-entry";
import { dbFind } from "@/services/db/dbFind";
import { csvifyGains } from "./csvifyGains";

export function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function glExportWeb(year: string, filters: any, payload: any): Promise<any> {
  // console.debug(year, filters, payload);
  return new Promise((resolve, reject) => {
    const filename = `SymbolTaxGainsReport${year ? '-' + year : ''}`;
    dbFind(null, DbTable.GAINS, payload, null, { soldAt: 1 }, filters)
      .then((docs: any[]) => {
        const gains = docs.map(g => GainLossEntry.fromDb(g));
        const longs = gains.filter(g => g.boughtAt && g.soldAt.getTime() - g.boughtAt.getTime() > 31536000000);
        const shorts = gains.filter(g => longs.indexOf(g) === -1);
        // console.debug('???? ', gains, longs, shorts);
        // @ts-ignore
        csvifyGains(shorts, filename, 'Short')
          .then(shortsRes => {
            download(shortsRes.filename, shortsRes.output);
            if (longs.length > 0) {
              csvifyGains(longs, filename, 'Long')
                .then(longsRes => {
                  download(longsRes.filename, longsRes.output);
                  resolve(true);
                })
                .catch(err => reject(err));
            } else {
              resolve(true);
            }
          })
          .catch(err => reject(err));
      })
      .catch(err => reject(err));
    });
}
