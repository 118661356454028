<template>
  <b-container fluid>
    <h3>Summary <b-spinner v-if="loading || !ready" size="sm"></b-spinner></h3>
    <!-- <p class="text-muted">Price data is used to calculate cost basis for capital gains/loss. Showing all time price data on a logarithmic scale courtesy of average coin prices from <a href="https://www.coinstats.app" target="_blank">CoinStats</a>.</p> -->
    <hr>
    <b-jumbotron v-if="ready && portfolioCoinsAll && portfolioCoinsAll.length === 0 && !loading" class="bg-light text-center">
      <div class="d-flex justify-content-around">
        <div class="jumbo" :class="{static: gifLoaded}"></div>
      </div>
      <hr>
      <p>No transactions yet... Get started.</p>
      <b-button variant="outline-dark" class="mr-3" to="/transactions">Start Importing</b-button>
      <b-button variant="outline-dark" to="/docs">Read the Docs</b-button>
    </b-jumbotron>
    <template v-if="ready">
      <b-row v-for="slice in slices" :key="slice[0]">
        <b-col v-for="coin in portfolioCoins.slice(slice[0], slice[1])" :key="coin ? coin.id : $index">
          <b-card
          border-variant="shadow"
          text-variant="dark"
          :img-src="coin.icon"
          img-end
          class="mt-3 mb-3"
          style="height: 183px;"
          v-if="coin._id">
            <b-card-title>
              {{ coin.symbol }}
            </b-card-title>
            <b-card-sub-title sub-title-text-variant="success" v-if="profitFor(coin.symbol) > 0">${{ profitFor(coin.symbol) + ' total gain' }}</b-card-sub-title>
            <b-card-sub-title sub-title-text-variant="danger" v-else-if="profitFor(coin.symbol) < 0">${{ Math.abs(Number(profitFor(coin.symbol))) + ' total loss' }}</b-card-sub-title>
            <!-- <b-card-sub-title sub-title-text-variant="muted" v-else>$0.00 gains</b-card-sub-title> -->
            <b-card-text>
              {{ coin.transactionCount }} Transaction{{ coin.transactionCount > 1 ? 's' : '' }}
            </b-card-text>
            <price-chart :coin="coin"/>
          </b-card>
          <b-card
          border-variant="shadow"
          text-variant="dark"
          :title="coin.symbol"
          :sub-title="coin.transactionCount + ' Transaction' + (coin.transactionCount > 1 ? 's' : '')"
          class="mt-3 mb-3"
          style="height: 183px;"
          v-if="!coin._id">
          <b-card-body>Coin details not found...</b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
    <b-row v-if="ready && portfolioCoinsAll && portfolioCoinsAll.length">
      <b-col div class="d-flex justify-content-around">
        <b-pagination-nav :link-gen="linkGen" :number-of-pages="totalPages" use-router></b-pagination-nav>
      </b-col>
    </b-row>
  </b-container>  
</template>

<script>
import PriceChart from '@/components/PriceChart.vue'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    PriceChart
  },
  data () {
    return {
      gifLoaded: false,
      perPage: 6
    }
  },
  created () {
    this.fetchPrices();
    this.calcReportsTotals();
  },
  mounted () {
    setTimeout(() => {
      this.gifLoaded = true;
    }, 5000);
  },
  computed: {
    ...mapState('coins', {
      dbReady: state => state.dbReady
    }),
    ...mapState('prices', {
      loading: state => state.loading
    }),
    ...mapState('reports', ['symbolTotals']),
    ...mapState('transactions', {
      portfolioCoinsAll: (state) => Object.values(state.uniqueCoins).filter(c => c.transactionCount > 0),
    }),
    ...mapState({
      ready: state => state.loadingInfo === 'loaded'
    }),
    slices () {
      const slices = [];
      for (let i = 0; i < this.portfolioCoins.length; i = i + 3) {
      // for (let i = 0; i < 6; i = i + 3) {
        slices.push([i, i+3]);
      }
      return slices;
    },
    totalPages () {
      return Math.ceil(this.portfolioCoinsAll.length / this.perPage);
    },
    currentPage () {
      return this.$route.query && this.$route.query.page ? this.$route.query.page : 1;
    },
    portfolioCoins () {
      return this.portfolioCoinsAll.slice(this.startingIndex, this.endingIndex);
    },
    startingIndex () {
      return (this.currentPage - 1) * this.perPage;
    },
    endingIndex () {
      return this.startingIndex + this.perPage;
    }
  },
  methods: {
    ...mapActions('prices', ['fetchPrices']),
    ...mapActions('reports', ['calcReportsTotals']),
    profitFor(sym) {
      return this.symbolTotals[sym] ? this.symbolTotals[sym].profit.toFixed(2): '--';
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    }
  }
}
</script>

<style lang="scss" scoped>
  img.card-img-right {
    width: 50px;
    height: 50px;
    margin: 1rem;
  }
</style>