import { BaseApiService, BaseApi, ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import env from "../env";
import crypto from 'crypto';
import { Transaction } from "@/models/transaction";
import { AxiosResponse } from "axios";
import Decimal from "decimal.js-light";
import md5 from "blueimp-md5";
import symbolMap from './gemini-symbols';

export default class GeminiApi extends BaseApiService implements BaseApi {
  static symbolMap: { [sym: string]: GeminiSymbol } = symbolMap;
  static API_URL = env.GEMINI_API_URL;
  static API_VERSION = 'v1';
  timeout = 200;

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super(creds)
  }

  private signature(b64) {
    return crypto.createHmac('sha384', this._credentials.secret).update(b64).digest('hex');
  }

  private headers(body) {
    const b64 = Buffer.from(JSON.stringify(body), 'binary').toString('base64');
    return {
      'Content-Type': 'text/plain',
      'Content-Length': '0',
      'X-GEMINI-APIKEY': this._credentials.key,
      'X-GEMINI-PAYLOAD': b64,
      'X-GEMINI-SIGNATURE': this.signature(b64),
      'Cache-Control': 'no-cache'
    }
  }

  verify(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAccounts()
        .then(res => resolve(true))
        .catch(err => reject(this.handleError(err)))
    })
  }

  async fetchTransactions(accountTimestamps: GeminiAccountTimestamp = {}): Promise<Transaction[]> {
    const accounts = await this.getAccounts();
    let results: Transaction[] = [];
    this.timeout = 1000;
    const symbols = await this.getStringSymbols();
    for (const sym of symbols) {
      // console.debug(GeminiApi.symbolMap[sym]);
      if (GeminiApi.symbolMap[sym]) { continue; }
      const gSym = await this.getSymbol(sym);
      GeminiApi.symbolMap[sym] = gSym;
    }
    this.timeout = 200;
    for (const acct of accounts) {
      const trades = await this.getTrades(acct, accountTimestamps);
      const transfers = await this.getTranfers(acct, accountTimestamps);
      results = results.concat(trades.map(t => GeminiTransaction.fromTx(t)));
      results = results.concat(transfers.map(t => GeminiTransaction.fromTrf(t)));
    }
    return results;
  }

  getAccounts(): Promise<GeminiAccount[]> {
    return new Promise((resolve, reject) => {
      const path = `/${GeminiApi.API_VERSION}/account/list`;
      const body = {
        request: path,
        nonce: Date.now()
      } 
      this._service.post(path, null, {
        headers: this.headers(body)
      })
      .then((res: AxiosResponse<GeminiAccount[]>) => resolve(res.data))
      .catch(err => reject(this.handleError(err)))
    })
  }

  getTranfers(acct: GeminiAccount, accountTimestamps: GeminiAccountTimestamp = {}): Promise<GeminiTransfer[]> {
    return new Promise((resolve, reject) => {
      const path = `/${GeminiApi.API_VERSION}/transfers`;
      const body = {
        request: path,
        nonce: Date.now(),
        account: acct.account,
        limit_transfers: 50,
        timestamp: accountTimestamps[acct.account].transfer || 0
      } 
      this._service.post(path, null, {
        headers: this.headers(body)
      })
      .then((res: AxiosResponse<GeminiTransfer[]>) => {
        return resolve(res.data.map(g => {
          return { ...g, account: acct };
        }))
      })
      .catch(err => reject(this.handleError(err)))
    })
  }

  getTrades(acct: GeminiAccount, accountTimestamps: GeminiAccountTimestamp = {}): Promise<GeminiTrade[]> {
    return new Promise((resolve, reject) => {
      const path = `/${GeminiApi.API_VERSION}/mytrades`;
      const body = {
        request: path,
        nonce: Date.now(),
        account: acct.account,
        limit_trades: 500,
        timestamp: accountTimestamps[acct.account].trade || 0
      } 
      this._service.post(path, null, {
        headers: this.headers(body)
      })
      .then((res: AxiosResponse<GeminiTrade[]>) => {
        return resolve(res.data.map(g => {
          return { ...g, symbolDetails: GeminiApi.symbolMap[g.symbol.toLocaleLowerCase()], account: acct };
        }))
      })
      .catch(err => reject(this.handleError(err)))
    })
  }

  getStringSymbols(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const path = `/${GeminiApi.API_VERSION}/symbols`;
      this._service.get(path)
        .then((res: AxiosResponse<string[]>) => resolve(res.data))
        .catch(err => reject(this.handleError(err)))
    })
  }

  getSymbol(sym: string): Promise<GeminiSymbol> {
    return new Promise((resolve, reject) => {
      const path = `/${GeminiApi.API_VERSION}/symbols/details/${sym}`;
      this._service.get(path)
        .then((res: AxiosResponse<GeminiSymbol>) => resolve(res.data))
        .catch(err => reject(this.handleError(err)))
    })
  }

  handleError(err) {
    if (err.response && err.response.data.message) {
      return err.response.data.message;
    } else if (err.message) {
      return err.message;
    } else {
      return err.toString();
    }
  }
}

export interface GeminiAccountTimestamp {
  [acct: string]: {
    trade: number;
    transfer: number;
  };
}

export interface GeminiAccount {
  name: string;
  account: string;
  type: string;
  counterparty_id: string;
  created: number;
}

export interface GeminiTrade {
  account: GeminiAccount;
  price: string;
  amount: string;
  timestamp: number;
  timestampms: number;
  type: string;
  aggressor: boolean;
  fee_currency: string;
  fee_amount: string;
  tid: number;
  order_id: string;
  exchange: string;
  is_auction_fill: boolean;
  symbol: string;
  symbolDetails: GeminiSymbol;
}

export interface GeminiSymbol {
  symbol: string; // BTCUSD
  base_currency: string; // BTC
  quote_currency: string; // USD
  tick_size: number;
  quote_increment: number;
  min_order_size: string;
  status: string;
}

export interface GeminiTransfer {
  account: GeminiAccount;
  type: string;
  status: string;
  timestampms: number;
  eid: number;
  currency: string;
  amount: string;
  txHash?: string;
}

export enum GeminiTransactionType {
  TRADE = 'trade',
  TRANSFER = 'transfer'
}

export class GeminiTransaction implements Transaction {
  type: GeminiTransactionType;
  _tx: GeminiTrade;
  _trf: GeminiTransfer;
  children: Transaction[] = [];

  get isTrade(): boolean {
    return this.type === GeminiTransactionType.TRADE && !!this._tx;
  }

  get isTrsf(): boolean {
    return this.type === GeminiTransactionType.TRANSFER && !!this._trf;
  }

  get date(): Date {
    if (this.isTrade) {
      return new Date(this._tx.timestampms);
    } else {
      return new Date(this._trf.timestampms);
    }
  }

  get amount(): Decimal {
    if (this.isTrade) {
      return new Decimal(this._tx.amount);
    } else {
      return new Decimal(this._trf.amount);
    }
  }
  
  get receivedSymbol(): string {
    if (this.isTrade) {
      if (this._tx.type === 'Buy') {
        return this._tx.symbolDetails.base_currency;
      } else {
        return this._tx.symbolDetails.quote_currency;
      }
    } else {
      return this._trf.type === 'Deposit' ? this._trf.currency : null;
    }
  }

  get receivedAmount(): Decimal {
    if (this.isTrade) {
      if (this._tx.type === 'Buy') {
        return this.amount;
      } else {
        return this.amount.mul(this._tx.price);
      }
    } else {
      return this._trf.type === 'Deposit' ? this.amount : new Decimal(0);
    }
  }

  get sentSymbol(): string {
    if (this.isTrade) {
      if (this._tx.type === 'Sell') {
        return this._tx.symbolDetails.base_currency;
      } else {
        return this._tx.symbolDetails.quote_currency;
      }
    } else {
      return this._trf.type === 'Withdrawal' ? this._trf.currency : null;
    }
  }

  get sentAmount(): Decimal {
    if (this.isTrade) {
      if (this._tx.type === 'Sell') {
        return this.amount;
      } else {
        return this.amount.mul(this._tx.price);
      }
    } else {
      return this._trf.type === 'Withdrawal' ? this.amount : new Decimal(0);
    }
  }

  get price(): Decimal {
    if (this.isTrade) {
      return new Decimal(this._tx.price);
    } else {
      return new Decimal(0);
    }
  }

  get fee(): Decimal {
    if (this.isTrade) {
      return new Decimal(this._tx.fee_amount);
    } else {
      return new Decimal(0);
    }
  }

  get feeSymbol(): string {
    if (this.isTrade) {
      return this._tx.fee_currency;
    } else {
      return null;
    }
  }

  get description(): string { return this.source.name; }

  get hash(): string {
    return this.isTrade ? null : (this._trf.txHash.startsWith('0x') ? this._trf.txHash.substring(2, this._trf.txHash.length) : this._trf.txHash);
  }

  get source(): any {
    return this.isTrade ? { name: 'Gemini Trade', ...this._tx } : { name: 'Gemini Transfer', ...this._trf };
  }

  get _id(): string {
    const { symbolDetails, ...lessDetails } = this.source;
    return md5(JSON.stringify(lessDetails));
  }

  static fromTx(trade: GeminiTrade): GeminiTransaction {
    const tx = new GeminiTransaction();
    tx.type = GeminiTransactionType.TRADE;
    tx._tx = trade;
    return tx;
  }

  static fromTrf(transfer: GeminiTransfer): GeminiTransaction {
    const tx = new GeminiTransaction();
    tx.type = GeminiTransactionType.TRANSFER;
    tx._trf = transfer;
    return tx;
  }
}