import { priceById } from "@/ipc/priceById";
import { addChildren } from "@/services/db/addChildren";
import { dbCount } from "@/services/db/dbCount";
import { dbFind } from "@/services/db/dbFind"
import { dbFindById } from "@/services/db/dbFindById";
import { dbInsert } from "@/services/db/dbInsert";
import { dbLinkSuggestions } from "@/services/db/dbLinkSuggestions";
import { dbRemove } from "@/services/db/dbRemove";
import { dbUpdate } from "@/services/db/dbUpdate";
import { updateNotFoundCoins } from "@/services/db/update-not-found";
import EtherscanImport from "@/services/etherscan/etherscan-import";
import { glCalc } from "@/services/gl/glCalc";
import { glCalcTotal } from "@/services/gl/glCalcTotal";
import { glChart } from "@/services/gl/glChart";
import { glExportWeb } from "@/services/gl/glExportWeb";
import { glInit } from "@/services/gl/glInit";
import { importCalc } from "@/services/income/importCalc";
import { incomeChart } from "@/services/income/incomeChart";
import { incomeExportWeb } from "@/services/income/incomeExportWeb";
import ServiceImportFactory from "@/services/service-import-factory";
import ServiceVerificationFactory from "@/services/service-verification-factory";
import { runMigrations } from '@/services/db/migrations/run';

window.ipcRenderer = {
  invoke: (channel: string, ...args) => {
    switch(channel) {
      case 'db:find':
        return dbFind(args[2], args[0], args[1], args[3], args[4], args[5]);
      case 'db:find:id':
        return dbFindById(args[0], args[1]);
      case 'db:insert':
        return dbInsert(args[0], args[1]);
      case 'db:count':
        return dbCount(args[0], args[1], args[2]);
      case 'db:remove':
        return dbRemove(args[0], args[1], args[2], args[3]);
      case 'db:update':
        return dbUpdate(args[0], args[1], args[2], args[3]);
      case 'db:link:suggestions':
        return dbLinkSuggestions();
      case 'db:add:children':
        return addChildren(args[0]);
      case 'db:migrations':
        return runMigrations();
      case 'gl:init':
        return glInit(args[0], args[1], args[2]);
      case 'gl:calc':
        return glCalc(args[0]) 
      case 'gl:chart':
        return glChart(args[1], args[0]);
      case 'gl:export':
        return glExportWeb(args[0], args[2], args[1]);
      case 'gl:calc:total':
        return glCalcTotal(args[0]);
      case 'income:calc':
        return importCalc(args[0], args[1], args[2], args[3]);
      case 'income:chart':
        return incomeChart(args[0], args[1]);   
      case 'income:export':
        return incomeExportWeb(args[0], args[2], args[1]);
      case 'service:verify': // , (event: IpcMainInvokeEvent, service: string, creds: ApiCredentials | TokenCredentials): Promise<boolean> => {
        return ServiceVerificationFactory.create(args[0], args[1]).verify();
      case 'service:refresh': // , (event: IpcMainInvokeEvent, service: string, creds: TokenCredentials): Promise<TokenCredentials> => {
        return ServiceVerificationFactory.create(args[0], args[1]).refresh();
      case 'service:import': // , (event: IpcMainInvokeEvent, service: string, creds: ApiCredentials | TokenCredentials, ...args: any[]): Promise<ImportResponse> => {
        return ServiceImportFactory.create(args[0], args[1], args[2]).import();
      case 'etherscan:import': // , (event: IpcMainInvokeEvent, address: string, creds: ApiCredentials): Promise<ImportResponse> => {
        return new EtherscanImport(args[1], args[0]).import();
      case 'link:open': // , (event: IpcMainInvokeEvent, link: string): void => {
        followLink(args);
        break;
      case 'db:update:not:found':
        return updateNotFoundCoins();
      case 'price:at':
        return priceById(args[0], args[1]);
      case 'process:platform':
        return Promise.resolve('web');
      case 'appReady':
        return Promise.resolve(true);
      default:
        return Promise.reject('invoker not defined... ' + channel);
    }
  },
  receive: (channel: string, func) => {
    // do nothing;
  }
}

function followLink(args: any[]) {
  const el = document.createElement('a');
  el.href = args[0];
  el.style.display = 'none';
  el.setAttribute('target', '_blank');
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
}
