import SymbolTaxDb from "@/services/db/db";


export function dbIndex(table: string, options: any): Promise<void> {
  return new Promise((resolve, reject) => {
    SymbolTaxDb.db[table].ensureIndex(options, function (err) {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });
}
