<template>
  <b-container id="oauth" fluid class="d-flex flex-grow justify-content-center mt-5">
    <h3>Authorizing <b-spinner type="grow" class="ml-1"></b-spinner></h3>
  </b-container>
</template>

<script>
import Helpers from '@/helpers/ipc-helpers'
export default {
  mounted() {
    Helpers.oauthCallback(this.$route.query.code, JSON.parse(this.$route.query.state))
      .then(res => {
        window.close()
      })
      .catch(err => {
        window.close()
      })
  }
}
</script>