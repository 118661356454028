import { ApiCredentials, BaseApi, OauthCredentials, TokenCredentials } from "./base-api";
import { BaseImport, BaseImportService } from "./base-import";
import CoinbaseImport from "./coinbase/coinbase-import";
import CoinbaseProImport from "./coinbasePro/coinbase-pro-import";
import env from "./env";
import EtherscanImport from "./etherscan/etherscan-import";
import KrakenImport from "./kraken/kraken-import";
import KucoinImport from "./kucoin/kucoin-import";
import CsvImport from './csv/csv-import';
import { GeminiImport } from "./gemini/gemini-import";
import { CoinstatsImport } from "./coinstats/coinstats-import";
import { AvalancheImport } from "./avalanche/avalanche-import";
import { FantomApi } from "./fantom/fantom-api";
import { FantomImport } from "./fantom/fantom-import";

export default class ServiceImportFactory {
  static create(service: string, creds: (ApiCredentials | OauthCredentials | TokenCredentials), ...args): BaseImportService {
    // creds = Object.assign(creds, {
    //   client_id: (creds as OauthCredentials).client_id || env[`${service.toUpperCase()}_AUTH_CLIENT_ID`],
    //   client_secret: (creds as OauthCredentials).client_secret || env[`${service.toUpperCase()}_AUTH_SECRET`]
    // });
    switch (service) {
      case 'etherscan':
        return new EtherscanImport(creds, ...args);
      case 'coinbase':
        return new CoinbaseImport(creds);
      case 'coinbasePro':
        return new CoinbaseProImport(creds);
      case 'kucoin':
        return new KucoinImport(creds);
      case 'kraken':
        return new KrakenImport(creds);
      case 'gemini':
        return new GeminiImport(creds);
      case 'csv':
        return new CsvImport(...args);
      case 'coinstats':
        return new CoinstatsImport(creds);
      case 'avalanche':
        return new AvalancheImport(creds, ...args);
      case 'fantom':
        return new FantomImport(creds, ...args);
      default:
        throw new Error('Invalid service')
    }
  }
}
