import Helpers from '@/helpers/ipc-helpers'
import { DbTable } from "@/helpers/DbTable";
import { ApiCredentials, TokenCredentials } from '@/services/base-api';
import { SettingsStoreState, ThemeSetting } from '../interfaces/settings-store-state';
import { CalcMethod } from "../interfaces/CalcMethod";
import { commonMutations, toastActions } from '../mixins';

/* eslint-disable @typescript-eslint/camelcase */
const defaultTokenCreds = (): TokenCredentials => {
  return {
    access_token: null,
    refresh_token: null,
    created_at: null,
    token_type: null,
    expires_in: null,
    scope: null
  }
}

const defaultApiCreds = (): ApiCredentials => {
  return {
    key: null,
    secret: null,
    passphrase: null
  }
}

export default {
  namespaced: true,
  state: (): SettingsStoreState => ({
    dbReady: false,
    settings: {
      _id: 'settings',
      theme: ThemeSetting.SYSTEM,
      currency: 'USD',
      apiKeys: {
        gumroad: defaultApiCreds(),
        coinbase: defaultApiCreds(),
        etherscan: defaultApiCreds(),
        avalanche: defaultApiCreds(),
        fantom: defaultApiCreds(),
        kucoin: defaultApiCreds(),
        coinbasePro: defaultApiCreds(),
        gemini: defaultApiCreds(),
        kraken: defaultApiCreds(),
        nimiq: defaultApiCreds(),
        mas: defaultApiCreds(),
        coinstats: defaultApiCreds()
      },
      installedAt: null,
      version: null,
      calcMethod: CalcMethod.FIFO
    },
    loading: false
  }),
  getters: {
    calcMethod: (state: SettingsStoreState): CalcMethod => {
      return state.settings.calcMethod;
    }
  },
  actions: {
    ...toastActions(),
    tokenVerify ({ commit, dispatch, state }, service: string): Promise<boolean> {
      return new Promise((resolve, reject) => {
        const creds = state.settings.apiTokens[service];
        Helpers.serviceVerify(service, creds)
          .then(res => {
            dispatch('dbSuccess', 'Credentials are valid!')
            resolve(res);
          })
          .catch(err => {
          // console.debug('service verify error...', err, err.toString())
            if (err.message.indexOf("'service:verify': expired") > -1 || err.toString().indexOf("'service:verify': expired") > -1) {
              Helpers.serviceRefresh(service, creds)
                .then((newCreds) => {
                // console.debug('new creds retrieved from refresh')
                  const newSetting = {};
                  newSetting[`apiTokens.${service}`] = newCreds;
                  dispatch('updateSetting', newSetting)
                    .then(updateRes => {
                      dispatch('dbSuccess', 'Credentials have been refreshed and saved!')
                      resolve(true)
                    })
                    .catch(err => {
                      dispatch('dbError', err)
                      reject(err)
                    })
                })
                .catch(err => {
                  dispatch('dbError', err)
                  reject(err)
                })
            } else {
              dispatch('dbError', err)
              reject(err)
            }
          })
      });
    },
    updateSetting ({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
      // console.debug('about to call settings update...')
        Helpers.dbUpdate(DbTable.SETTINGS, { _id: 'settings' }, { $set: payload })
          .then((updateRes) => {
          // console.debug('settings updateRes...', updateRes)
            commit('updateSetting', payload)
            commit('loaded')
            resolve(updateRes)
          })
          .catch(err => { dispatch('dbError', err); reject(err); })
      });
    },
    fetchSettings ({ commit, dispatch, state }, refresh: false) {
      return new Promise((resolve, reject) => {
        if (state.loading) {
          resolve(state.settings);
        } else {
          commit('loading')
          Helpers.dbFindById(DbTable.SETTINGS, 'settings')
            .then(val => {
              if (val) {
                commit('setSettings', val);
                commit('dbReady');
                commit('loaded');
                resolve(val);
              } else {
                commit('setInstalledAt');
                Helpers.dbInsert(DbTable.SETTINGS, state.settings)
                  .then(docInserted => { commit('setSettings', docInserted); commit('loaded'); resolve(docInserted); })
                  .catch(err => { dispatch('dbError', err); reject(err); })
              }
            })
            .catch(err => { dispatch('dbError', err); reject(err); })
        }
      })
    },
  },
  mutations: {
    ...commonMutations(),
    setSettings (state: SettingsStoreState, doc: any) {
      state.settings = doc;
      // set defaults
      state.settings.apiKeys = state.settings.apiKeys || {} as any
      state.settings.apiKeys.gumroad = state.settings.apiKeys.gumroad || defaultApiCreds()
      state.settings.apiKeys.coinbasePro = state.settings.apiKeys.coinbasePro || defaultApiCreds()
      state.settings.apiKeys.kucoin = state.settings.apiKeys.kucoin || defaultApiCreds()
      state.settings.apiKeys.etherscan = state.settings.apiKeys.etherscan || defaultApiCreds()
      state.settings.apiKeys.avalanche = state.settings.apiKeys.avalanche || defaultApiCreds()
      state.settings.apiKeys.fantom = state.settings.apiKeys.fantom || defaultApiCreds()
      state.settings.apiKeys.coinbase = state.settings.apiKeys.coinbase || defaultApiCreds()
      state.settings.apiKeys.kraken = state.settings.apiKeys.kraken || defaultApiCreds()
      state.settings.apiKeys.gemini = state.settings.apiKeys.gemini || defaultApiCreds()
      state.settings.apiKeys.nimiq = state.settings.apiKeys.nimiq || defaultApiCreds()
      state.settings.apiKeys.mas = state.settings.apiKeys.mas || defaultApiCreds()
      state.settings.apiKeys.coinstats = state.settings.apiKeys.coinstats || defaultApiCreds()
    },
    updateSetting (state: SettingsStoreState, payload: any) {
      let key = Object.keys(payload)[0];
      const val = Object.values(payload)[0];
      if (key.startsWith('apiKeys.')) {
        key = key.replace('apiKeys.', '');
        state.settings.apiKeys[key] = val;
      } else if (key.startsWith('apiTokens.')) {
        key = key.replace('apiTokens.', '');
        // state.settings.apiTokens[key] = val;
      } else {
        state.settings[key] = val;
      }
    },
    setInstalledAt (state: SettingsStoreState) {
      state.settings.installedAt = new Date();
    }
  }
}