import md5 from "blueimp-md5";
import Decimal from "decimal.js-light";
import { Transaction } from "../../models/transaction";
import { CoinstatsDbCoin } from "../coinstats/CoinstatsDbCoin";
import { EtherscanApiBaseTransaction } from "./etherscan-api-base-transaction";
import { EtherscanSuperTransaction } from "./etherscan-super-transaction";

export interface EtherscanBaseTransaction extends EtherscanSuperTransaction {
  nonce: string;
  blockHash: string;
  transactionIndex: string;
  gasPrice: string;
  cumulativeGasUsed: string;
  confirmations: string;
}

export interface EtherscanInternalTransaction extends EtherscanSuperTransaction {
  type: string;
  traceId: string;
  isError: string;
  errCode: string;
}

export interface EtherscanTransaction extends EtherscanBaseTransaction {
  isError: string;
  txreceipt_status: string;
}

export interface EtherscanTokenTransaction extends EtherscanBaseTransaction {
  tokenName: string;
  tokenSymbol: string;
  tokenDecimal: string;
}

export interface EtherscanNFTTransaction extends EtherscanTokenTransaction {
  tokenID: string; 
}

export interface EtherscanResponse {
  status: string;
  message: string;
  result: EtherscanTokenTransaction[]|EtherscanTransaction[]|EtherscanInternalTransaction[]|string;
}

// Txhash	UnixTimestamp	DateTime	From	To	Value	ContractAddress	TokenName	TokenSymbol
export class EtherscanApiTokenTransaction extends EtherscanApiBaseTransaction implements Transaction {
  declare _tx: EtherscanTokenTransaction;
  children: Transaction[] = [];
  _receivedCoinId: string;
  _receivedCoin: CoinstatsDbCoin;
  _sentCoinId: string;
  _sentCoin: CoinstatsDbCoin;
  get receivedCoinId(): string {
    return this._receivedCoinId;    
  }
  get receivedCoin(): CoinstatsDbCoin {
    return this._receivedCoin;
  }
  get sentCoinId(): string {
    return this._sentCoinId;    
  }
  get sentCoin(): CoinstatsDbCoin {
    return this._sentCoin;
  }

  constructor(tx: EtherscanTokenTransaction, myAddress: string = null, altService?: string) {
    super(tx, myAddress, altService);
  }

  get receivedSymbol(): string {
    if (this.receivedAmount && this.receivedAmount.gt(0)) {
      return this._tx.tokenSymbol.replace('.e', '');
    } else {
      return null;
    }
  }

  get amount(): Decimal {
    return new Decimal(this._tx.value).dividedBy(Number(`1e${Number(this._tx.tokenDecimal)}`));
  }

  get sentSymbol(): string {
    if (this.sentAmount && this.sentAmount.gt(0)) {
      return this._tx.tokenSymbol.replace('.e', '');
    } else {
      return null;
    }
  }

  get price(): Decimal {
    return new Decimal(0);
  }

  get fee(): Decimal {
    return new Decimal(0);
  }

  get source(): any {
    return {
      name: `${this.name} Token Tx`,
      ...this._tx,
      myAddress: this._myAddress
    }
  }
}

export class EtherscanApiNFTTransaction extends EtherscanApiTokenTransaction {
  declare _tx: EtherscanNFTTransaction;
  constructor(tx: EtherscanNFTTransaction, myAddress: string = null, altService?: string) {
    super(tx, myAddress, altService);
  }

  get amount(): Decimal {
    return new Decimal(1);
  }

  get tokenSymbolWithId(): string {
    return `${this._tx.tokenSymbol}-#${this._tx.tokenID}`;
  }

  get receivedSymbol(): string {
    if (this.receivedAmount && this.receivedAmount.gt(0)) {
      return this.tokenSymbolWithId;
    } else {
      return null;
    }
  }

  get sentSymbol(): string {
    if (this.sentAmount && this.sentAmount.gt(0)) {
      return this.tokenSymbolWithId;
    } else {
      return null;
    }
  }

  get source(): any {
    return {
      name: `${this.name} NFT Tx`,
      ...this._tx,
      myAddress: this._myAddress
    }
  }
}