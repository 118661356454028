import md5 from "blueimp-md5";
import Decimal from "decimal.js-light";
import { EtherscanTransaction } from "./etherscan-api-token-transaction";
import { Transaction } from "../../models/transaction";
import { EtherscanApiBaseTransaction } from "./etherscan-api-base-transaction";

export class EtherscanApiTransaction extends EtherscanApiBaseTransaction implements Transaction {
  declare _tx: EtherscanTransaction; 
  children: Transaction[] = [];

  constructor(tx: EtherscanTransaction, myAddress: string = null, altService?: string) {
    super(tx, myAddress, altService);
  }

  get amount(): Decimal {
    return this._tx.isError === "1" ? new Decimal(0) : new Decimal(this._tx.value).mul(1e-18);
  }

  get fee(): Decimal {
    if (this.isSentFromMe) {
      return new Decimal(this._tx.gasPrice).mul(new Decimal(this._tx.gas)).div(1e18);
    } else {
      return new Decimal(0);
    }
  }
}