import axios, { AxiosResponse } from 'axios';
import Helpers from '@/helpers/ipc-helpers';
import { DbTable } from "@/helpers/DbTable";
import { FiatCurrency, PriceStoreState } from '../interfaces/price-store-state';
import { PriceData } from '@/models/gain-loss-entry';
import { commonMutations, toastActions } from '../mixins';
import env from '@/services/env';

export default {
  namespaced: true,
  state: (): PriceStoreState => ({
    dbReady: false,
    prices: [],
    fiats: [],
    loading: true,
    paginationFields: {
      sortBy: 'date',
      sortDesc: false,
      currentPage: 1,
      perPage: 50,
      noRows: 0
    }
  }),
  getters: {
    pricesGetter: (state: PriceStoreState) => {
      return state.prices;
    }
  },
  actions: {
    ...toastActions(),
    ensurePriceData ({ commit, dispatch, rootGetters }) {
      const coinIds = rootGetters.portfolioCoins.map((c: any) => c._id);
      /**
       * 
       * @param p {Promise}
       * stackoverflow help
       */
      const reflect = (p: Promise<any>) => p.then(v => ({v, status: "fulfilled" }),
                            e => ({e, status: "rejected" }));
      const promises = coinIds.map(id => dispatch('fetchPrice', id))
      return Promise.all(promises.map(reflect));
    },
    fetchPrices ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        Helpers.dbFind(DbTable.PRICES, {})
          .then(docs => {
            commit('loaded');
            // console.debug(docs.filter(d => d._id === 'ethereum'));
            // console.debug('uni prices...', docs.filter(d => d._id === 'uniswap'));
            commit('setAllPrices', docs);
            resolve(docs);
          })
          .catch(err => {
            commit('loaded');
            console.error(err);
            dispatch('dbError', err);
            reject(err);
          });
      });
    },
    // TODO
    fetchFiats ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios.get('https://api.coinstats.app/public/v1/fiats')
          .then((res: AxiosResponse<FiatCurrency[]>) => {
            commit('setFiats', res.data);
            resolve(res.data);
          })
          .catch(err => reject(err))
      });
    },
    fetchPrice ({ commit, dispatch, rootState }, id, refresh = false) {
      return new Promise((resolve, reject) => {
        const newDoc: PriceData = {
          _id: id,
          chart: []
        }
        if (!id || id.startsWith('stax-fiat')) {
          // dispatch('dbError', 'No id found');
          resolve(newDoc);
          return;
        }
        Helpers.dbFindById(DbTable.PRICES, id)
          .then((doc: PriceData) => {
            const dates = doc ? doc.chart.map(p => p.x) : [];
            const last = dates.length === 0 ? new Date('10/31/2008') : dates[dates.length-1];
            const now = new Date();
          // console.debug('last... now', last, now);
            const diff = now.getTime() - last.getTime();
            // TODO move to backend
            if (doc === null || diff > 86400000) {
              axios.get(`${env.COINSTATS_API_URL}/coins/${id.trim()}/charts?period=all`, { headers: {'X-API-KEY': rootState.settings.settings.apiKeys.coinstats.key}})
                .then(res => {
                  newDoc.chart = res.data.map(prices => {
                    return {
                      x: new Date(prices[0] * 1000),
                      y: prices[1]
                    }
                  });
                  if (doc === null) {
                    Helpers.dbInsert(DbTable.PRICES, newDoc)
                      .then((doc: PriceData) => {
                        commit('setPrices', doc);
                        resolve(doc);
                      })
                      .catch(err => {
                        // dispatch('dbError', err);
                        reject(err);
                      })
                  } else {
                    Helpers.dbUpdate(DbTable.PRICES, { _id: newDoc._id }, { $set: { chart: newDoc.chart }})
                      .then((result: any) => {
                        commit('setPrices', newDoc);
                        resolve(newDoc);
                      })
                      .catch(err => {
                        // dispatch('dbError', err);
                        reject(err);
                      })
                  }
  
                })
                .catch(err => {
                  // dispatch('dbInfo', 'Update your CoinStats API Key in settings to get accurate historical coin prices.');
                  reject(err);
                })
            } else {
            // console.debug('price doc fetched', doc);
              // check if need to fetch new data

              commit('setPrices', doc);
              resolve(doc);
            }
          })
          .catch(err => {
            dispatch('dbError', err);
            reject(err);
          })
      });
    }
  },
  mutations: {
    ...commonMutations(),
    setPrices (state: PriceStoreState, price: PriceData) {
      const currentPrice = state.prices.find(p => p._id === price._id);
      if (currentPrice) {
        state.prices.splice(state.prices.indexOf(currentPrice), 1, price);
      } else {
        state.prices.push(price);
      }
    },
    setAllPrices (state: PriceStoreState, prices: PriceData[]) {
      state.prices = prices;
    },
    setFiats (state: PriceStoreState, fiats: FiatCurrency[]) {
      state.fiats = fiats;
    }
  }
}