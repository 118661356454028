import { DbTable } from "@/helpers/DbTable";
import { IncomeDbTransformer } from "@/store/interfaces/income-store-state";
import { BrowserWindow, dialog, IpcMainInvokeEvent } from "electron";
import { dbFind } from "@/services/db/dbFind";
import { saveIncomeFile } from "./saveIncomeFile";
import { csvifyIncome } from "./csvifyIncome";
import { download } from "../gl/glExportWeb";

export function incomeExportWeb(year: string, filters: any, payload: any): any {
  return new Promise((resolve, reject) => {
    dbFind(null, DbTable.INCOMES, payload, null, { soldAt: 1 }, filters)
      .then((docs: any[]) => {
        const incomes = docs.map(i => IncomeDbTransformer.fromDb(i));
        csvifyIncome(incomes, `SymbolTaxIncomeReport${year ? '-' + year : ''}.csv`)
          .then(res => {
            download(res.filename, res.output);
            resolve(true);
          })
          .catch(reject)
      })
      .catch(err => reject(err));
  });
}
