import { DbTable } from "@/helpers/DbTable";
import SymbolTaxDb from "@/services/db/db";


export function dbInsert(table: DbTable, payload: any): any {
  return new Promise((resolve, reject) => {
    SymbolTaxDb.db[table].insert(payload, function (err, docs) {
      if (err) {
        return reject(err);
      } else {
        return resolve(docs);
      }
    });
  });
}
