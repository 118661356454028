import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Transactions from '@/views/Transactions.vue'
import Prices from '@/views/Prices.vue'
import Reports from '@/views/Reports.vue'
import Settings from '@/views/Settings.vue'
import Docs from '@/views/Docs.vue'
import OauthRedirect from '@/views/OauthRedirect.vue'
import DocsTransactions from '@/views/docs/Transactions.vue'
import DocsApiKeys from '@/views/docs/ApiKeys.vue'
import DocsReports from '@/views/docs/Reports.vue'
import DocsSupport from '@/views/docs/Support.vue'
import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/index.html', redirect: '/' },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions
  },
  {
    path: '/',
    name: 'Summary',
    component: Prices
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/docs',
    name: 'Docs',
    component: Docs,
    children: [
      {
        path: '',
        redirect: '/docs/transactions'
      },
      {
        path: '/docs/transactions',
        name: 'Transactions',
        component: DocsTransactions
      },
      {
        path: '/docs/api-keys',
        name: 'API Keys',
        component: DocsApiKeys
      },
      {
        path: '/docs/reports',
        name: 'Generating Reports',
        component: DocsReports
      },
      {
        path: '/docs/support',
        name: 'Support',
        component: DocsSupport
      }
    ]
  }
]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.settings.theme) {
    store.dispatch('settings/fetchSettings')
      .then(res => {
        checkTrial(to, next)
      })
      .catch(err => {
        console.error(err);
        next()
      })
  } else {
    checkTrial(to, next);
  }
})

export default router
function checkTrial(to, next) {
  // console.debug('beforeEach route checker...', to, store);
  if (to.name !== 'Settings' && store.getters.isTrial && store.getters.trialExpired)
    next({ name: 'Settings' })
  else
    next()
}

