<template>
  <div>
    <line-chart :chartData="chartdata" :options="options" :styles="myStyles" :chartId="`price-chart_${coin._id}`"></line-chart>
  </div>
</template>

<script>
import LineChart from '@/components/shared/LineChart'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    LineChart
  },
  props: {
    coin: Object
  },
  data () {
    return {
      myStyles: {
        width: '100%',
        height: '120px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        'border-radius': '0.25 rem'
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        height: 150,
        tooltips: {
          intersect: false,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          callbacks: {
            label: (tooltipItem, data) => {
              // data for manipulation
              //return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              const val = Number(tooltipItem.yLabel)
              if (val < 2) {
                return '$' + Number(tooltipItem.yLabel).toFixed(3)
              } else {
                return '$' + Number(tooltipItem.yLabel).toFixed(2)
              }
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            type: 'time',
            time: {
              unit: 'day',
              tooltipFormat: 'll'
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: false,
            type: 'logarithmic'
          }]
        }
      }
    }
  },
  mounted () {
    // console.debug('price ref ...', this.$refs.priceElement);
    const lastPrice = this.chart[this.chart.length-1];
    if (!lastPrice) {
      this.fetchPrice(this.coin._id);
    } else if (lastPrice.x < Date.now() - 86400) {
      this.fetchPrice(this.coin._id);
    }
  },
  computed: {
    ...mapState('prices', {
      prices: state => state.prices,
      loaded: state => state.loaded
    }),
    price () {
      return this.prices.find(p => p._id === this.coin._id)
    },
    chart () {
      return this.price ? this.price.chart : []
    },
    theme () {
      return window.getComputedStyle(document.body).backgroundColor === 'rgb(0, 0, 0)' ? 'dark' : 'light';
    },
    tenDayGapChart () {
      if (this.chart.length > 10) {
        const newChart = [];
        for (let i = 0; i < this.chart.length; i = i + 10) {
          newChart.push(this.chart[i]);
        }
        newChart.push(this.chart[this.chart.length - 1]);
        return newChart;
      } else {
        return this.chart;
      }
    },
    chartdata () {
      return {
        datasets: [
          {
            color: this.theme === 'light' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
            backgroundColor: this.theme === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.3)',
            data: this.tenDayGapChart,
            lineTension: 0,
            pointRadius: 0
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('prices', [
      'fetchPrice'
    ])
  }
}
</script>

<style lang="scss">

</style>