import SymbolTaxDb from "@/services/db/db";
import { Transaction, TransactionFactory } from "@/models/transaction";

export function dbLinkSuggestions(): any {
  return new Promise((resolve, reject) => {
    const suggestions: any[] = [];
    const orQuery = [
      {
        receivedSymbol: ''
      },
      {
        receivedSymbol: null
      },
      {
        sentSymbol: ''
      },
      {
        sentSymbol: null
      },
      {
        $where: function () {
          return this.children.length === 1;
        }
      }
    ];
    const timeThreshold = 300000; // 5 minutes
    const amountThreshold = 0.01; // 1%
    SymbolTaxDb.db.transactions.find({ $or: orQuery })
      .sort({ date: 1 })
      .exec((err, docs) => {
        // console.debug('docs in link suggestions...', docs);
        if (err) {
          reject(err);
        } else {
          try {
            for (let i = 0; i < docs.length; i++) {
              const tx = TransactionFactory.fromDB(docs[i]);
              let j = i + 1;
              let nextTx: Transaction;
              while (j < docs.length) {
                nextTx = TransactionFactory.fromDB(docs[j]);
                if (nextTx.source && nextTx.source.accountType === 'MARGIN') { j++; continue; }
                // if (nextTx.source.name === tx.source.name) { break; }
                const txSentSymbol = tx.children.length > 0 ? tx.children[0].sentSymbol : tx.sentSymbol;
                const txSentAmount = tx.children.length > 0 ? tx.children[0].sentAmount : tx.sentAmount;
                const nextSentSymbol = nextTx.children.length > 0 ? nextTx.children[0].sentSymbol : nextTx.sentSymbol;
                const nextSentAmount = nextTx.children.length > 0 ? nextTx.children[0].sentAmount : nextTx.sentAmount;
                const txReceivedSymbol = tx.children.length > 0 ? tx.children[0].receivedSymbol : tx.receivedSymbol;
                const txReceivedAmount = tx.children.length > 0 ? tx.children[0].receivedAmount : tx.receivedAmount;
                const nextReceivedSymbol = nextTx.children.length > 0 ? nextTx.children[0].receivedSymbol : nextTx.receivedSymbol;
                const nextReceivedAmount = nextTx.children.length > 0 ? nextTx.children[0].receivedAmount : nextTx.receivedAmount;

                const dateDiff: number = nextTx.date.getTime() - tx.date.getTime();
                if (dateDiff > timeThreshold) { break; }
                if (txSentSymbol && txSentSymbol === nextReceivedSymbol && txSentAmount.gt(0)) {
                  const amountDiff = txSentAmount.minus(nextReceivedAmount).abs();
                  if (amountDiff.div(txSentAmount).lt(amountThreshold)) {
                    suggestions.push(
                      {
                        sent: docs[i],
                        received: docs[j]
                      }
                    );
                    break;
                  }
                }
                if (txReceivedSymbol && txReceivedSymbol === nextSentSymbol && txReceivedAmount.gt(0)) {
                  const amountDiff = txReceivedAmount.minus(nextSentAmount).abs();
                  if (amountDiff.div(txReceivedAmount).lt(amountThreshold)) {
                    suggestions.push(
                      {
                        sent: docs[j],
                        received: docs[i]
                      }
                    );
                    break;
                  }
                }
                j++;
              }
            }
            resolve(suggestions);
          } catch (e) {
            reject(e);
          }
        }
      });
  });
}
