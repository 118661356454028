import SymbolTaxDb from "@/services/db/db";


export function dbUpdate(table: string, query: any, update: any, options: any): any {
  return new Promise((resolve, reject) => {
    // console.debug('query, update, options', query, update, options);
    SymbolTaxDb.db[table].update(query, update, options || {}, function (err, numAffected, affectedDocs, upsert) {
      if (err) {
        reject(err);
      } else {
        resolve({ numAffected, affectedDocs, upsert });
      }
    });
  });
}
