<template>
  <b-container fluid>
    <h3>Reports</h3>
    <hr>
    <b-spinner class="text-center" v-if="loading"></b-spinner>
    <div id="reportBody" v-if="!loading">
      <b-row class="pt-1 pb-2 sticky-top bg-white">
        <b-col class="d-flex justify-content-between">
          <b-button-group size="sm">
            <b-button variant="outline-success" @click="calc"><b-icon-calculator></b-icon-calculator></b-button>
          </b-button-group>
          <b-form @sumbit.stop.prevent inline v-if="uniqueYears && uniqueYears.length > 0">
            <b-form-datepicker
              size="sm"
              style="width: 120px;"
              id="datepicker-dateformat1"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="Start Date"
              reset-button
              reset-button-variant="outline-dark"
              calendar-width="100%"
              today-button
              locale="en"
              class="mr-1"
              v-model="startDate"
              :value-as-date="true"
              :max="endDate"
            ></b-form-datepicker>

            <b-form-datepicker
              id="datepicker-dateformat2"
              placeholder="End Date"
              size="sm"
              style="width: 120px;"
              reset-button
              reset-button-variant="outline-dark"
              today-button
              calendar-width="100%"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
              class="mr-1"
              v-model="endDate"
              :value-as-date="true"
              :min="startDate"
            ></b-form-datepicker>

            <b-button-group>
              <b-dropdown variant="outline-dark" :text="currentYear ? currentYear : 'Year'" right size="sm">
                <b-dropdown-item v-for="year in uniqueYears" :key="year" @click="startDate = new Date('01/01/' + year); endDate = new Date('12/31/' + year + ' 23:59:59');">{{ year }}</b-dropdown-item>
              </b-dropdown>
            </b-button-group>

            <b-dropdown variant="outline-dark" no-caret size="sm" class="ml-1" dropleft>
              <template #button-content>
                <b-icon-filter-square></b-icon-filter-square>
              </template>
              <b-dropdown-text>
                <h5>Filters</h5>
              </b-dropdown-text>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-form style="width: 200px;">
                <b-form-group label="Symbols" style="width: 150px;">
                  <b-form-select multiple :options="uniqueSymbols" v-model="selectedSymbols" style="min-width: 150px;" id="symbolFilter"></b-form-select>
                </b-form-group>
              </b-dropdown-form>
            </b-dropdown>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-card no-body>
            <b-tabs content-class="mt-3" v-model="currentTab" card>
              <b-tab title="Summary">
                <template v-if="currentTab === 0">
                  <report-coin-list></report-coin-list>
                </template>
              </b-tab>
              <b-tab title="Gains/Losses">
                <template v-if="currentTab === 1">
                  <div class="d-flex justify-content-between">
                    <b-button-group class="mb-3" size="sm">
                      <b-button :active="!showGLChart" variant="outline-dark" @click="showGLChart=false;" active-class="dark">
                        <b-icon-table></b-icon-table>
                      </b-button>
                      <b-button :active="showGLChart" variant="outline-dark" @click="showGLChart=true;" active-class="dark">
                        <b-icon-graph-up></b-icon-graph-up>
                      </b-button>
                      <b-button variant="outline-dark" @click="exportGainLoss">
                        <b-icon-download></b-icon-download>
                      </b-button>
                    </b-button-group>
                    <symbol-badges table="reports"></symbol-badges>
                  </div>
                  <gain-loss-chart v-if="showGLChart"></gain-loss-chart>
                  <gain-loss-table v-else></gain-loss-table>
                </template>
              </b-tab>
              <b-tab title="Income">
                <template v-if="currentTab === 2">
                  <div class="d-flex justify-content-between">
                    <b-button-group class="mb-3" size="sm">
                      <b-button :active="!showIChart" variant="outline-dark" @click="showIChart=false;" active-class="dark">
                        <b-icon-table></b-icon-table>
                      </b-button>
                      <b-button :active="showIChart" variant="outline-dark" @click="showIChart=true;" active-class="dark">
                        <b-icon-graph-up></b-icon-graph-up>
                      </b-button>
                      <b-button variant="outline-dark" @click="exportIncome">
                        <b-icon-download></b-icon-download>
                      </b-button>
                    </b-button-group>
                    <symbol-badges table="reports"></symbol-badges>
                  </div>
                  <income-chart v-if="showIChart"></income-chart>
                  <income-table v-else></income-table>
                </template>
              </b-tab>
              <b-tab title="Volumes">
                <template v-if="currentTab === 3">
                  <div class="d-flex justify-content-end">
                    <symbol-badges table="reports"></symbol-badges>
                  </div>

                  <volume-chart></volume-chart>
                </template>
              </b-tab>
              <b-tab disabled> 
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import GainLossChart from '@/components/reports/GainLossChart.vue'
import { GainLossEntry } from '@/models/gain-loss-entry'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Decimal from 'decimal.js-light'
import VolumeChart from '@/components/reports/VolumeChart.vue'
import IncomeChart from '@/components/reports/IncomeChart.vue'
import GainLossTable from '@/components/reports/GainLossTable.vue'
import IncomeTable from '@/components/reports/IncomeTable.vue'
import Helpers from '@/helpers/ipc-helpers'
import SymbolBadges from '@/components/shared/SymbolBadges.vue'
import ReportCoinList from '@/components/reports/ReportCoinList.vue'

export default {
  components: { GainLossChart, VolumeChart, IncomeChart, GainLossTable, IncomeTable, SymbolBadges, ReportCoinList },
  data () {
    return {
      currentTab: 0,
      showGLChart: false,
      showIChart: false
    }
  },
  mounted () {
    this.ensurePriceData()
      .then(res => {
        // console.debug('price data ensured')
        Helpers.dbUpdateNotFound()
          .then(() => {
            console.debug('updated not found...')
          })
          .catch(console.error)
      })
      .catch(err => console.error(err));
  },
  computed: {
    ...mapState('reports', {
      txProcessed: state => state.txProcessed,
      txCount: state => state.txTotal,
      loading: state => state.loading,
      filters: state => state.filters
    }),
    ...mapState('transactions', {
      uniqueYears: state => state.uniqueYears
    }),
    ...mapGetters('reports', ['dateFilter', 'currentYear']),
    ...mapGetters('transactions', ['uniqueSymbols']),
    ...mapGetters(['isBasic']),
    startDate: {
      get () {
        return this.$store.state.reports.filters.startDate
      },
      set (val) {
        this.$store.commit('reports/setStartDate', val)
      }
    },
    endDate: {
      get () {
        return this.$store.state.reports.filters.endDate
      },
      set (val) {
        this.$store.commit('reports/setEndDate', val)
      }
    },
    selectedSymbols: {
      get () {
        return this.filters.symbols
      },
      set (val) {
        this.$store.commit('reports/setSymbolFilters', val)
      }
    }
  },
  methods: {
    ...mapActions('reports', ['calcGains', 'calcReportsTotals']),
    ...mapActions('prices', ['ensurePriceData']),
    ...mapMutations(['dbSuccess', 'dbWarning']),
    calc() {
      if (this.loading) { return; }
      this.calcGains()
        .then((gainsRes) => {
        // console.debug('gains calced....', gainsRes);
        })
        .catch((gainsErr) => {
          console.error(gainsErr);
        })
    },
    exportGainLoss() {
      if (this.isBasic) {
        this.dbWarning('Report exports are not allowed in the basic version of Symbol Tax. Upgrade to Pro on the settings page.')
      } else {
        Helpers.glExport(this.currentYear, { soldAt: this.dateFilter }, this.filters)
          .then(file => this.dbSuccess('Gains Report processed!'))
          .catch(err => console.error(err));
      }
    },
    exportIncome() {
      if (this.isBasic) {
        this.dbWarning('Report exports are not allowed in the basic version of Symbol Tax. Upgrade to Pro on the settings page.')
      } else {
        Helpers.incomeExport(this.currentYear, { date: this.dateFilter }, this.filters)
          .then(file => this.dbSuccess('Income Report processed!'))
          .catch(err => console.error(err));
      }
    }
  },
  watch: {
    startDate () {
      this.calcReportsTotals();
    },
    endDate () {
      this.calcReportsTotals();
    }
  } 
}
</script>