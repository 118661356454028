import { GainLossEntry, PriceData } from "@/models/gain-loss-entry"
import Helpers from "@/helpers/ipc-helpers";
import { DbTable } from "@/helpers/DbTable";
import Decimal from "decimal.js-light";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { DateHelpers, YearMonth } from "@/helpers/general-helpers";
import { GainLossChart, ReportStoreState } from "../interfaces/report-store-state";
import { commonMutations, currentYearGetter, dateFilterGetter, filtersMutations, paginationMutations, sortingGetteres, toastActions } from "../mixins";

export function sumUpChildrenForTransaction(t: Transaction): Decimal {
  let result = new Decimal(0);
  [t, ...t.children].forEach(tx => {
    result = result.add(t.receivedValue);
  });
  return result;
}

export default {
  namespaced: true,
  state: (): ReportStoreState => ({
    dbReady: false,
    gains: [],
    chart: {
      proceeds: {
        labels: [],
        values: []
      },
      cost: {
        labels: [],
        values: []
      },
      profit: {
        labels: [],
        values: []
      }
    },
    loading: false,
    calced: false,
    txProcessed: 0,
    txTotal: 0,
    symbolTotals: {},
    filters: {
      startDate: null,
      endDate: null,
      symbols: []
    },
    paginationFields: {
      sortBy: 'soldAt',
      sortDesc: false,
      currentPage: 1,
      perPage: 100,
      noRows: 0
    }
  }),
  getters: {
    ...dateFilterGetter(),
    ...sortingGetteres(),
    ...currentYearGetter()
  },
  actions: {
    ...toastActions(),
    fetchGainLossChart ({ state, commit, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        Helpers.glChart({ soldAt: getters.dateFilter }, state.filters)
          .then((chart: GainLossChart) => {
            commit('setGainsForChart', chart)
            resolve(chart)
          })
          .catch(err => {
            dispatch('dbError', err)
            reject(err)
          });
      });
    },
    fetchReportsCount ({ commit, getters, dispatch, state }) {
      Helpers.dbCount(DbTable.GAINS, { soldAt: getters.dateFilter }, state.filters)
        .then(count => commit('setNoRows', count))
        .catch(err => dispatch('dbError', err));
    },
    fetchReports ({ commit, state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        Helpers.dbFind(
          DbTable.GAINS,
          { soldAt: getters.dateFilter },
          null,
          state.paginationFields,
          getters.sorting,
          state.filters)
          .then(docs => {
            commit('setGains', docs);
            resolve(docs.map(d => GainLossEntry.fromDb(d)));
          })
          .catch(err => {
            dispatch('dbError', err);
            reject(err);
          })
      });
    },
    fetchTransactionBatch ({ dispatch }, pagination) {
      const newPagination = {
        currentPage: pagination.page + 1,
        perPage: pagination.perPage
      }
      return new Promise((resolve, reject) => {
        Helpers.dbFind(DbTable.TRANSACTIONS, {}, null, newPagination, { date: 1 })
          .then(txs => resolve(txs))
          .catch(err => {
            dispatch('dbError', err);
            reject(err);
          })
      });
    },
    calcReportsTotals ({ commit, dispatch, getters }) {
      return new Promise((resolve, reject) => {
        Helpers.glCalcTotal({ soldAt: getters.dateFilter })
        .then((totals: any) => { commit('setSymbolTotals', totals); resolve(totals); })
        .catch(err => { dispatch('dbError', err); reject(err); })
      })
    },
    calcGains ({ commit, dispatch, rootGetters, state }) {
      return new Promise((resolve, reject) => {
        GainLossEntry.clear();
        commit('loading')
        commit('resetTxProcessed');
      // console.debug('about to call ensure price data...');
        dispatch('prices/ensurePriceData', null, { root: true })
          .then((prices: PriceData[]) => {
          // console.debug('got price data in calc', prices);
            // can be run asynchronously since its not dependent on gains
            dispatch('incomes/calcIncome', null, { root: true })
              .then((res) => console.debug('income calc...', res))
              .catch((err) => dispatch('dbError', err))
            Helpers.glInit(rootGetters['prices/pricesGetter'], rootGetters.portfolioCoins, rootGetters['settings/calcMethod'])
            .then(clearResult => {
              Helpers.dbRemove(DbTable.GAINS, {}, {multi: true})
              .then(numRemoved => {
              // console.debug('gains numRemoved...', numRemoved);
                Helpers.dbCount(DbTable.TRANSACTIONS)
                  .then(count => {
                    if (count !== state.paginationFields.noRows) {
                      commit('resetPagination')
                    }
                    commit('setNoTransactions', count);
                    const perPage = 1000;
                    const noPages = Math.ceil(count / perPage);
                    const batches: any[] = [];
                    for (let page = 0; page < noPages; page++) {
                      batches.push({ page, perPage });
                    }
                    const gains: GainLossEntry[] = [];
                    Promise.all(batches.map(batch => {
                      return new Promise((allResolve, allReject) => {
                        dispatch('fetchTransactionBatch', batch)
                          .then((txs: any[]) => {
                            Helpers.glCalc(txs)
                              .then(result => {
                                commit('incrementTxProcessed', txs.length);
                                allResolve(result);
                              })
                              .catch(calcErr => {
                                dispatch('dbError', calcErr);
                                allReject(calcErr);
                              });
                          })
                          .catch((batchError) => {
                            dispatch('dbError', batchError);
                            allReject(batchError);
                          });
                        });
                      }))
                      .then(allResult => {
                        // console.debug('promise all gains respoinse...', gains);
                        // const flatGains = gains.flat();
                        // TODO insert into DB...
                        dispatch('fetchReportsCount');
                        dispatch('fetchReports');
                        setTimeout(() => {
                          commit('loaded');
                        }, 2000);
                        resolve(true);
                      })
                      .catch((allError) => {
                        dispatch('dbError', allError);
                        reject(allError);
                      });
                  })
                  .catch(err => {
                    dispatch('dbError', err);
                    reject(err);
                  })
              })
              .catch(removeErr => {
                dispatch('dbError', removeErr);
                reject(removeErr);          
              })
            })
            .catch(clearErr => {
              dispatch('dbError', 'Could not initialize calculations');
              reject(clearErr);  
            })
          })
          .catch(ensureError => { dispatch('dbError', ensureError); reject(ensureError); })
      });
    }
  },
  mutations: {
    ...commonMutations(),
    ...paginationMutations(),
    ...filtersMutations(),
    setGains (state, gains) {
      state.gains = gains.map(d => GainLossEntry.fromDb(d));
    },
    setNoTransactions (state: ReportStoreState, val: number) {
      state.txTotal = val;
    },
    incrementTxProcessed (state: ReportStoreState, val = 1) {
      state.txProcessed += val;
    },
    resetTxProcessed (state: ReportStoreState) {
      state.txProcessed = 0;
    },
    setSymbolTotals (state: ReportStoreState, totals: any) {
      state.symbolTotals = totals;
    },
    setGainsForChart (state: ReportStoreState, chart: GainLossChart) {
      state.chart = chart;
    },
    resetPagination (state: ReportStoreState) {
      state.paginationFields.currentPage = 1;
    }
  }
}
