import { BaseFilterStoreState, BaseStoreState } from "./interfaces/base-store-state"

export const dateFilterGetter = () => {
  return {
    dateFilter (state: BaseFilterStoreState) {
      const start = new Date('10/31/2008');
      const dateFilter: any = {
        $gt: state.filters.startDate === null ? start : state.filters.startDate
      };
      if (state.filters.endDate && state.filters.endDate.toLocaleString() !== new Date(null).toLocaleString()) {
        dateFilter.$lt = state.filters.endDate;
      }
      return dateFilter;
    }
  }
}

export const commonMutations = () => {
  return {
    dbReady (state: BaseStoreState) {
      state.dbReady = true;
    },
    loading (state: BaseStoreState) {
      state.loading = true;
    },
    loaded (state: BaseStoreState) {
      state.loading = false;
    },
  }
}

export const paginationMutations = () => {
  return {
    setSortBy (state: BaseStoreState, val: string) {
      state.paginationFields.sortBy = val;
    },
    setSortDesc (state: BaseStoreState, val: boolean) {
      state.paginationFields.sortDesc = val;
    },
    setPerPage (state: BaseStoreState, val: number) {
      state.paginationFields.perPage = val;
    },
    setCurrentPage (state: BaseStoreState, val: number) {
      state.paginationFields.currentPage = val;
    },
    setNoRows (state: BaseStoreState, val: number) {
      state.paginationFields.noRows = val;
    }
  }
}

export const toastActions = () => {
  return {
    dbError ({ commit }, err: any) {
      commit('dbError', err, { root: true });
      commit('loaded')
    },
    dbSuccess ({ commit }, msg: any) {
      commit('dbSuccess', msg, { root: true });
      commit('loaded')
    },
    dbWarning ({ commit }, msg: any) {
      commit('dbWarning', msg, { root: true });
      commit('loaded')
    }
  }
}

export const filtersMutations = () => {
  return {
    setStartDate (state: BaseFilterStoreState, startDate: Date) {
      state.filters.startDate = startDate;
    },
    setEndDate (state: BaseFilterStoreState, endDate: Date) {
      state.filters.endDate = endDate;
    },
    setSymbolFilters (state: BaseFilterStoreState, val: string[]) {
      state.filters.symbols = val;
    },
    deselectSymbol (state: BaseFilterStoreState, sym: string) {
      const existingSym = state.filters.symbols.find(symbol => sym === symbol);
      if (existingSym) {
        state.filters.symbols.splice(state.filters.symbols.indexOf(existingSym), 1);
      }
    }
  }
}

export const sortingGetteres = () => {
  return {
    sorting (state: BaseStoreState) {
      const sorting = {};
      sorting[state.paginationFields.sortBy] = state.paginationFields.sortDesc ? -1 : 1;
      return sorting;
    }
  }
}

export const currentYearGetter = () => {
  return {
    currentYear (state: BaseFilterStoreState) {
      return state.filters.startDate && state.filters.endDate && state.filters.endDate.getFullYear() === state.filters.startDate.getFullYear()
      && state.filters.startDate.getMonth() === 0 && state.filters.endDate.getMonth() === 11
      && state.filters.startDate.getDate() === 1 && state.filters.endDate.getDate() === 31 ? state.filters.startDate.getFullYear().toString() : null;
    }
  }
}

export const truncater = () => {
  return {
    truncateText (input: string, maxLength: number) {
      return input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;
    }
  }
}
