import { DbTable } from "@/helpers/DbTable";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ImportResponse } from "@/services/import-response";
import { ApiCredentials, OauthCredentials, TokenCredentials } from "../base-api";
import { BaseImport, BaseImportService } from "../base-import";
import { dbFind } from "../db/dbFind";
import { dbInsert } from "../db/dbInsert";
import KrakenApi from "./kraken-api";
import { KrakenTransaction } from "./kraken-transaction";

const tradeGrouping = (txs: Transaction[]): { [key: string]: Transaction } => {
  return txs.reduce((a: { [key: string]: Transaction }, b: Transaction) => {
    if (!b.source.refid) { return a; }
    if (a[b.source.refid]) {
      a[b.source.refid].children.push(b);
    } else {
      a[b.source.refid] = b;
    }
    return a;
  }, Object.create({}))
}

export default class KrakenImport extends BaseImportService implements BaseImport {
  declare api: KrakenApi;

  constructor(creds: ApiCredentials | OauthCredentials | TokenCredentials) {
    super('kraken', creds);
  }

  import(): Promise<ImportResponse> {
    return new Promise((resolve, reject) => {
      dbFind({ date: 1 }, DbTable.TRANSACTIONS, { 'source.name': 'Kraken' }, null, { date: -1 })
        .then(docs => {
          let starttm = 1;
          if (docs[0]) {
            starttm = parseFloat(docs[0].date.getTime() + 1) / 1000.0;
          }
          (this.api as KrakenApi).fetchTransactions(starttm)
          .then((txs: KrakenTransaction[]) => {
            const importResponse: ImportResponse = {
              new: 0,
              existing: 0,
              linked: 0
            }
            dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(txs), null, null)
              .then((matchingTxs: any[]) => {
                importResponse.existing = matchingTxs.length;
                const newTxs = this.newTxs(txs, matchingTxs);
                const grouped = Object.values(tradeGrouping(newTxs));
                importResponse.new = grouped.length;
                importResponse.linked = newTxs.length - grouped.length;
                dbInsert(DbTable.TRANSACTIONS, grouped.map(t => TransactionFactory.toDB(t)))
                  .then(res => resolve(importResponse))
                  .catch(err => reject(err))
              })
              .catch(err => reject(err))
          })
          .catch(err => reject(err))
        })
        .catch(err => reject(err));
    })
  }
}