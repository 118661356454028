import { Transaction } from "./transaction";
// import store from '../store'
import Decimal from "decimal.js-light";
import { CalcMethod } from "@/store/interfaces/CalcMethod";
import { CoinstatsDbCoin } from "@/services/coinstats/CoinstatsDbCoin";

export interface PricePoint {
  x: Date;
  y: number;
}

export interface PriceData {
  _id: string;
  chart: PricePoint[];
}

interface ConsolidatedTx {
  date: Date;
  fee: Decimal;
  description: string;
  source: any;
  sent: {
    [sym: string]: Decimal;
  };
  received: {
    [sym: string]: Decimal;
  };
  sentValue: {
    [sym: string]: Decimal;
  };
  receivedValue: {
    [sym: string]: Decimal;
  };
}

export class GainLossEntry {
  static coinMap: { [id: string]: CoinstatsDbCoin } = {};
  static balances: { [id: string]: Decimal } = {};
  static buys: { [id: string]: Transaction[] } = {};
  static marginBuys: { [id: string]: Transaction[] } = {};
  static borrows: { [id: string]: Transaction[] } = {};
  static borrowedTotals: { [id: string]: Decimal } = {};
  static gains: GainLossEntry[] = [];
  public currentBalance: any = {
    sent: new Decimal(0),
    received: new Decimal(0)
  };
  private _buy: Transaction;
  private _sell: Transaction;
  static prices: PriceData[];
  static calcMethod: CalcMethod = CalcMethod.FIFO;
  interestApplied = false;

  public get description(): string {
    return `${this._sell.sentAmount} ${this._sell.sentSymbol}`;
  }

  toString(): string {
    return `###### TRADE ######
${this.description}
proceeds: ${this.proceeds}
cost: ${this.cost}
profit: ${this.profit}
${JSON.stringify(this.currentBalance)}
buysentv: ${this.buySentValue}
buyrecv: ${this.buyReceivedValue}
buyfee: ${this.buyFeeValue}
sellsentv: ${this.sellSentValue}
sellrecv: ${this.sellReceivedValue}
sellfee: ${this.sellFeeValue}
${JSON.stringify(this._buy, null, 2)}
${JSON.stringify(this._sell, null, 2)}
#################`;
  }

  public get proceeds(): Decimal {
    if (this.sellReceivedValue.eq(0) && this.sellSentValue.gt(0)) {
      return this.sellSentValue.toDecimalPlaces(2);
    } else {
      return this.sellReceivedValue.toDecimalPlaces(2);
    }
  }

  get fee(): Decimal {
    let f = new Decimal(0);
    f = f.add(this.buyFeeValue);
    f = f.add(this.sellFeeValue);
    return f;
  }


  public get cost(): Decimal {
    const basis = this._buy && this._buy.sentAmount && this._buy.sentSymbol && this.buySentValue.gt(0) ? this.buySentValue : this.buyReceivedValue;
    return basis.add(this.fee).toDecimalPlaces(2);
  }

  public get boughtAt(): Date {
    return this._buy ? this._buy.date : null;
  }

  buySentValue: Decimal;
  buyReceivedValue: Decimal;
  buyFeeValue: Decimal;
  sellSentValue: Decimal;
  sellReceivedValue: Decimal;
  sellFeeValue: Decimal;

  valueFor(side: 'buy'|'sell', direction: 'sent'|'received'|'fee'): Decimal {
    const tx: Transaction = this[`_${side}`];
    if (!tx) return new Decimal(0);
    const result = tx[`${direction}Value`];
    // console.debug('value for...', side, direction, result);
    return result || new Decimal(0);
  }

  public get soldAt(): Date {
    return this._sell.date;
  }

  public get profit(): Decimal {
    return new Decimal(this.proceeds).minus(this.cost).toDecimalPlaces(2);
  }

  constructor(buy: Transaction, sell: Transaction) {
    if (!sell) {
      throw new Error('no sell...');
    }
    if (buy && buy.receivedCoinId !== sell.sentCoinId) {
      // console.debug(buy, sell);
      throw new Error('buy and sell symbols do not match');
    }
    if (buy && buy.receivedAmount.comparedTo(sell.sentAmount) !== 0) {
      throw new Error('amounts do not match, buy received amount must match sell sentAmount');
    }
    this._buy = buy;
    this._sell = sell;

    this.buySentValue = this.valueFor('buy', 'sent');
    this.buyReceivedValue = this.valueFor('buy', 'received');
    this.buyFeeValue = this.valueFor('buy', 'fee');
    this.sellSentValue = this.valueFor('sell', 'sent');
    this.sellReceivedValue = this.valueFor('sell', 'received');
    this.sellFeeValue = this.valueFor('sell', 'fee');

    this.currentBalance.sent = GainLossEntry.balances[this._sell.sentCoinId];
    this.currentBalance.received = GainLossEntry.balances[this._sell.receivedCoinId];
  }

  updateFees(tx: Transaction) {
    // console.debug('updating fee...', tx.feeValue, this.buyFeeValue);
    if (!tx && !tx.feeValue) return;
    this.buyFeeValue = this.buyFeeValue.add(tx.feeValue);
    this.interestApplied = true;
  }
  /**
   * ex.
   *  { 'sym': { date: '', sent: '', received: '', fee: 0 } }
   * @param tx 
   * @returns 
   */
  static consolidatedTx(tx: Transaction): Transaction[] {
    // let i = 0;

    const newTx = (t: ConsolidatedTx, sentId: string = null, receivedId = null, includeFee = true): Transaction => {
      const result = {
        _id: '',
        date: t.date,
        receivedSymbol: null,
        receivedCoin: null,
        receivedCoinId: null,
        receivedAmount: new Decimal(0),
        receivedValue: new Decimal(0),
        sentSymbol: null,
        sentCoin: null,
        sentCoinId: null,
        sentAmount: new Decimal(0),
        sentValue: new Decimal(0),
        fee: includeFee ? t.fee : new Decimal(0),
        feeSymbol: 'USD',
        feeValue: includeFee ? t.fee : new Decimal(0),
        price: new Decimal(0),
        description: t.description,
        children: [],
        source: t.source
      }
      if (sentId) {
        result.sentCoinId = sentId;
        result.sentCoin = GainLossEntry.coinMap[sentId];
        result.sentSymbol = result.sentCoin ? result.sentCoin.symbol : sentId; 
        result.sentAmount = t.sent[sentId];
        result.sentValue = t.sentValue[sentId];
      }
      if (receivedId) {
        result.receivedCoinId = receivedId;
        result.receivedCoin = GainLossEntry.coinMap[receivedId];
        result.receivedSymbol = result.receivedCoin ? result.receivedCoin.symbol : receivedId; 
        result.receivedAmount = t.received[receivedId];
        result.receivedValue = t.receivedValue[receivedId];
      }
      if (result.receivedAmount.isZero()) {
        result.receivedSymbol = null;
        result.receivedCoinId = null;
        result.receivedCoin = null;
      }
      if (result.sentAmount.isZero()) {
        result.sentSymbol = null;
        result.sentCoinId = null;
        result.sentCoin = null;
      }
      return result;
    };

    const consTx: ConsolidatedTx = [tx, ...tx.children].reduce((a: ConsolidatedTx, t: Transaction) => {
      a.date = a.date || t.date;
      a.source = a.source || t.source;
      a.description = a.description || t.description;
      a.fee = a.fee || new Decimal(0);
      a.fee = a.fee.add(t.feeValue || new Decimal(0));

      if (t.receivedCoin && t.receivedAmount.gt(0)) {
        a.received[t.receivedCoin._id] = a.received[t.receivedCoin._id] || new Decimal(0);
        a.received[t.receivedCoin._id] = a.received[t.receivedCoin._id].add(t.receivedAmount);
        a.receivedValue[t.receivedCoin._id] = a.receivedValue[t.receivedCoin._id] || new Decimal(0);
        a.receivedValue[t.receivedCoin._id] = a.receivedValue[t.receivedCoin._id].add(t.receivedValue);
      }
      
      if (t.sentCoin && t.sentAmount.gt(0)) {
        a.sent[t.sentCoin._id] = a.sent[t.sentCoin._id] || new Decimal(0);
        a.sent[t.sentCoin._id] = a.sent[t.sentCoin._id].add(t.sentAmount);
        a.sentValue[t.sentCoin._id] = a.sentValue[t.sentCoin._id] || new Decimal(0);
        a.sentValue[t.sentCoin._id] = a.sentValue[t.sentCoin._id].add(t.sentValue);
      }
      
      return a;
    }, { date: null, fee: new Decimal(0), sent: {}, received: {}, sentValue: {}, receivedValue: {}, description: null, source: null });

    let receivedIds = Object.keys(consTx.received);
    let sentIds = Object.keys(consTx.sent);
    for (const sym of receivedIds) {
      for (const oSym of sentIds) {
        if (sym && sym === oSym) {
          if (consTx.received[sym].gte(consTx.sent[sym])) {
            consTx.received[sym] = consTx.received[sym].minus(consTx.sent[sym]);
            delete consTx.sent[sym];
          } else {
            consTx.sent[sym] = consTx.sent[sym].minus(consTx.received[sym]);
            delete consTx.received[sym];
          }
          if (consTx.receivedValue[sym].gte(consTx.sentValue[sym])) {
            consTx.receivedValue[sym] = consTx.receivedValue[sym].minus(consTx.sentValue[sym]);
            delete consTx.sentValue[sym];
          } else {
            consTx.sentValue[sym] = consTx.sentValue[sym].minus(consTx.receivedValue[sym]);
            delete consTx.receivedValue[sym];
          }
        }
      }
    }

    receivedIds = Object.keys(consTx.received);
    sentIds = Object.keys(consTx.sent);

    // console.debug('constTx', consTx);

    const txs: Transaction[] = [];
    switch ([receivedIds.length, sentIds.length].reverse().join('-')) {
      // sent-received
      case '0-0':
        txs.push(newTx(consTx))
        break;
      case '1-0':
        txs.push(newTx(consTx, sentIds[0]));
        break;
      case '0-1':
        txs.push(newTx(consTx, null, receivedIds[0]));
        break;
      case '0-2':
        txs.push(newTx(consTx, null, receivedIds[0]));
        txs.push(newTx(consTx, null, receivedIds[1], false));
        break;
      case '2-0':
        txs.push(newTx(consTx, sentIds[0]));
        txs.push(newTx(consTx, sentIds[1], null, false));
        break;
      case '1-2':
        txs.push(newTx(consTx, sentIds[0], receivedIds[0]));
        txs.push(newTx(consTx, sentIds[0], receivedIds[1], false));
        break;
      case '2-1':
        txs.push(newTx(consTx, sentIds[0], receivedIds[0]));
        txs.push(newTx(consTx, sentIds[1], receivedIds[0], false));
        break;
      case '1-1':
        txs.push(newTx(consTx, sentIds[0], receivedIds[0]));
        break;
      case '2-2':
        txs.push(newTx(consTx, sentIds[0], receivedIds[0]));
        txs.push(newTx(consTx, sentIds[1], receivedIds[1], false));
        break;
      default:
        if (sentIds.length <= 1 && receivedIds.length > 1) {
          for (const sym of receivedIds) {
            txs.push(newTx(consTx, sentIds[0], sym, receivedIds.indexOf(sym) === 0));
          }
        } else if (receivedIds.length <=1 && sentIds.length > 1) {
          for (const sym of sentIds) {
            txs.push(newTx(consTx, sym, receivedIds[0], sentIds.indexOf(sym) === 0));
          }
        }
        // TODO match multiple to multiple
        break;
    }

    const counts = txs.reduce((prev, curr: Transaction) => {
      prev.received[curr.receivedCoinId] = prev.received[curr.receivedCoinId] || 0;
      prev.received[curr.receivedCoinId]++;
      prev.sent[curr.sentCoinId] = prev.sent[curr.sentCoinId] || 0;
      prev.sent[curr.sentCoinId]++;
      return prev;
    }, { received: {}, sent: {} });


    for (const tx of txs) {
      tx.receivedAmount = tx.receivedAmount.div(counts.received[tx.receivedCoinId]);
      tx.sentAmount = tx.sentAmount.div(counts.sent[tx.sentCoinId]);
      if (tx.receivedValue)
        tx.receivedValue = tx.receivedValue.div(counts.received[tx.receivedCoinId]);
      if (tx.sentValue)
        tx.sentValue = tx.sentValue.div(counts.sent[tx.sentCoinId]);
    }

    return txs;
  }

  static calculator(transactions: Transaction[], prices?: any, coinMap?: any): Promise<GainLossEntry[]> {
    this.balances = {};
    transactions = [...transactions];
    const marginTransactions = transactions.filter(t => t.source && t.source.accountType === 'MARGIN');
    transactions = transactions.filter(t => !t.source || t.source.accountType !== 'MARGIN');
    return new Promise((resolve, reject) => {
      try {
        const gains: GainLossEntry[] = [];
        for (const tx of transactions) {
          const consolidatedTxs: Transaction[] = GainLossEntry.consolidatedTx(tx);
          // console.debug(consolidatedTxs);
          GainLossEntry.updateSymbolKey(consolidatedTxs);
          // console.debug(consolidatedTxs);
          for (const t of consolidatedTxs) {
            GainLossEntry.fifo(t, gains);
          }
        }
        // console.debug('after first forEach....');
        // GainLossEntry.clear();
        for (const tx of marginTransactions) {
          const consolidatedTxs: Transaction[] = GainLossEntry.consolidatedTx(tx);
          for (const t of consolidatedTxs) {
            GainLossEntry.fifo(t, gains, true);
          }
        }
        resolve(gains);
      }
      catch(e) {
        reject(e);
      }
    });
  } 

  public static clear() {
    GainLossEntry.buys = {};
    GainLossEntry.marginBuys = {};
    GainLossEntry.borrows = {};
    GainLossEntry.borrowedTotals = {};
    GainLossEntry.gains = [];
  }

  /**
   * method to update symbols, ex. UNI-V2-ETH-USDT
   * @param txs list of consolidated transactions to 
   * @param lpSymbol liquidity pool symbol ex. UNI-V2
   */
  private static updateSymbolKey(txs: Transaction[], lpSymbol = 'UNI-V2'): void {
    const lpTxs = txs.filter(t => t.receivedSymbol === lpSymbol || t.sentSymbol === lpSymbol);
    const otherSymbols = txs.map(t => [t.receivedSymbol, t.sentSymbol]).flat().filter(sym => sym !== lpSymbol && !!sym);
    const key = otherSymbols.join('-');
    for (const t of lpTxs) {
      if (t.receivedSymbol === lpSymbol) {
        t.receivedSymbol = `${lpSymbol}-${key}`;
        t.receivedCoinId = t.receivedSymbol;
      }
      if (t.sentSymbol === lpSymbol) {
        t.sentSymbol = `${lpSymbol}-${key}`;
        t.sentCoinId = t.sentSymbol;
      }
    }
  }

  private static fifo(sell: Transaction, gains: GainLossEntry[], isMargin = false) {
    let sellClone: Transaction = { ...sell };
    // const buys = isMargin ? GainLossEntry.marginBuys : GainLossEntry.buys;
    const buys = GainLossEntry.buys;
    let buy;

    // console.debug(GainLossEntry.borrowedTotals);
    if (isMargin && sellClone.source.bizType === 'Debt Repayment') {
      buys[sellClone.sentCoinId] = buys[sellClone.sentCoinId] || [];
      const currentPrice = sellClone.sentAmount.gt(0) ? sellClone.sentValue.div(sellClone.sentAmount) : new Decimal(0);
      // console.debug('sellClone sent amount/value', sellClone.sentAmount, sellClone.sentValue)
      GainLossEntry.borrowedTotals[sellClone.sentCoinId] = GainLossEntry.borrowedTotals[sellClone.sentCoinId].minus(sellClone.sentAmount);
      const feeAmount = GainLossEntry.borrowedTotals[sellClone.sentCoinId].abs();
      const feeValue = currentPrice.mul(feeAmount);
      if (GainLossEntry.borrowedTotals[sellClone.sentCoinId].lt(0)) {
        const feeTx: Transaction = {
          _id: null,
          sentCoinId: null,
          sentAmount: new Decimal(0),
          sentSymbol: null,
          sentValue: new Decimal(0),
          receivedCoinId: null,
          receivedAmount: new Decimal(0),
          receivedSymbol: null,
          receivedValue: new Decimal(0),
          fee: feeAmount,
          feeCoinId: sellClone.sentCoinId,
          feeSymbol: null,
          feeValue: feeValue,
          date: sellClone.date,
          description: 'Debt interest',
          price: new Decimal(0),
          source: {},
          children: []
        }
        // console.debug('repayment...', feeTx);
        const matchingGains = gains.filter(g => g._buy && g._buy.sentCoinId && g._buy.sentCoinId === feeTx.feeCoinId && !g.interestApplied);
        const match = matchingGains[0];
        if (match) {
          match.updateFees(feeTx);
          GainLossEntry.borrowedTotals[sellClone.sentCoinId] = new Decimal(0);
        }
        // console.debug('match for repay...', match);
      }
      if (GainLossEntry.calcMethod === CalcMethod.LIFO) {
        buy = buys[sellClone.sentCoinId].pop();
      } else {
        buy = buys[sellClone.sentCoinId].shift();
      }
      while (buy) {
        if (sellClone.sentAmount.gte(buy.receivedAmount)) {
          sellClone.sentAmount = sellClone.sentAmount.minus(buy.receivedAmount)
          sellClone.sentValue = sellClone.sentValue.minus(buy.receivedValue)
          if (GainLossEntry.calcMethod === CalcMethod.LIFO) {
            buy = buys[sellClone.sentCoinId].pop();
          } else {
            buy = buys[sellClone.sentCoinId].shift();
          }
        } else {
          const newBuy: Transaction = { ...buy };

          // Amount
          const newReceived: Decimal = newBuy.receivedAmount.minus(sellClone.sentAmount);
          const fractReceived = newReceived.div(newBuy.receivedAmount);
          newBuy.sentAmount = fractReceived.mul(newBuy.sentAmount);
          newBuy.receivedAmount = newReceived;

          // Value
          const newReceivedValue: Decimal = newBuy.receivedValue.minus(sellClone.sentValue);
          const fractReceivedValue = newReceived.div(newBuy.receivedValue);
          newBuy.sentValue = fractReceivedValue.mul(newBuy.sentValue);
          newBuy.receivedValue = newReceivedValue;

          buys[sellClone.sentCoinId].push(newBuy);
          buy = null;
        }
      }
      return;
    }
    if (!sellClone.receivedCoinId) { return; } // its a withdrawal or transfer
    if (isMargin && sellClone.source.bizType === 'Borrowings') {
      this.borrowedTotals[sellClone.receivedCoinId] = this.borrowedTotals[sellClone.receivedCoinId] || new Decimal(0);
      this.borrows[sellClone.receivedCoinId] = this.borrows[sellClone.receivedCoinId] || [];
      this.borrows[sellClone.receivedCoinId].push(sellClone);
      this.borrowedTotals[sellClone.receivedCoinId] = this.borrowedTotals[sellClone.receivedCoinId].add(sellClone.receivedAmount);
      return;
    }
    // if (sellClone.source && sellClone.source.name === 'KuCoin' && sellClone.source.accountType === 'MARGIN') { return; } //skip margin for now, unsupported
    if (sellClone.sentCoinId === sellClone.receivedCoinId && sellClone.sentAmount.comparedTo(sellClone.receivedAmount) === 0) { return; } // linked transfer
    
    if (sellClone.receivedCoinId) {
      buys[sellClone.receivedCoinId] = buys[sellClone.receivedCoinId] || [];
    }
    if (sellClone.sentCoinId) {
      buys[sellClone.sentCoinId] = buys[sellClone.sentCoinId] || [];
    }
    buys[sellClone.receivedCoinId].push(sell);

    // skip sell
    // console.debug('borrows...', this.borrows);
    if (isMargin && this.borrows[sellClone.sentCoinId]) {
      let checkMoreBorrowed = true;
      while(checkMoreBorrowed) {
        const borrowed = this.borrows[sellClone.sentCoinId].shift();
        // console.debug('#### Borrowed...', JSON.stringify(borrowed, null, 2));
        // console.debug('#### Sell...', JSON.stringify(sell, null, 2));
        if (borrowed && sellClone.sentAmount.eq(borrowed.receivedAmount)) {
          checkMoreBorrowed = false;
          return;
        } else if (borrowed && sellClone.sentAmount.lt(borrowed.receivedAmount)) {
          borrowed.receivedAmount = borrowed.receivedAmount.minus(sellClone.sentAmount);
          borrowed.receivedValue = borrowed.receivedValue.minus(sellClone.sentValue);
          this.borrows[sellClone.sentCoinId].push(borrowed);
          checkMoreBorrowed = false;
          return;
        } else if (borrowed && sellClone.sentAmount.gt(borrowed.receivedAmount)) {
          // Amount
          const proportionBorrowed = borrowed.receivedAmount.dividedBy(sellClone.sentAmount);
          sellClone.receivedAmount = sellClone.receivedAmount.minus(sellClone.receivedAmount.mul(proportionBorrowed));
          sellClone.sentAmount = sellClone.sentAmount.minus(borrowed.receivedAmount);
          
          // Value
          // const proportionBorrowedValue = borrowed.receivedValue.dividedBy(sellClone.sentValue);
          sellClone.receivedValue = sellClone.receivedValue.minus(sellClone.receivedValue.mul(proportionBorrowed));
          sellClone.sentValue = sellClone.sentValue.minus(borrowed.receivedValue);
        } else if (!borrowed) {
          checkMoreBorrowed = false;
        }
      }
    }

    if (sellClone.sentCoinId && sellClone.sentCoinId !== 'stax-fiat-usd') {
      let glEntry;
      let sellPending = true;
        while (sellPending) {
          if (GainLossEntry.calcMethod === CalcMethod.LIFO) {
            buy = buys[sellClone.sentCoinId].pop();
          } else {
            buy = buys[sellClone.sentCoinId].shift();
          }
          if (!buy) {
            glEntry = new GainLossEntry(null, sellClone);
            gains.push(glEntry);
            sellPending = false;
          } else if (buy.receivedAmount.gt(sellClone.sentAmount)) {
            const proportionSent = sellClone.sentAmount.div(buy.receivedAmount);
            // buy amount is greater than sell amount - add back buy less sell
            const buyOne: Transaction = { ...buy };
            const buyTwo: Transaction = { ...buy };

            // Amount
            buyOne.receivedAmount = sellClone.sentAmount;
            buyOne.sentAmount = buyOne.sentAmount.mul(proportionSent);
            
            // Value
            buyOne.receivedValue = buyOne.receivedValue.mul(proportionSent);
            buyOne.sentValue = buyOne.sentValue.mul(proportionSent);

            glEntry = new GainLossEntry(buyOne, sellClone);

            // Amount
            buyTwo.receivedAmount = buy.receivedAmount.minus(buyOne.receivedAmount);
            buyTwo.sentAmount = buy.sentAmount.minus(buyOne.sentAmount);

            // Value
            buyTwo.receivedValue = buy.receivedValue.minus(buyOne.receivedValue);
            buyTwo.sentValue = buy.sentValue.minus(buyOne.sentValue);

            if (GainLossEntry.calcMethod === CalcMethod.LIFO) {
              buys[sellClone.sentCoinId].push(buyTwo);
            } else {
              buys[sellClone.sentCoinId].unshift(buyTwo);
            }
            gains.push(glEntry);
            sellPending = false;
          } else if (buy.receivedAmount.comparedTo(sellClone.sentAmount) === 0) {
            // buy amount = sellClone amount - 
            glEntry = new GainLossEntry(buy, sellClone);
            gains.push(glEntry);
            sellPending = false;
          } else if (buy.receivedAmount.lt(sellClone.sentAmount)) {
            const proportionReceived = buy.receivedAmount.div(sellClone.sentAmount);
            // buy amount is less than sell amount - partial buy
            const sellOne: Transaction = { ...sellClone };
            sellClone = { ...sellClone };

            // Amount
            sellOne.sentAmount = buy.receivedAmount;
            sellOne.receivedAmount = sellOne.receivedAmount.mul(proportionReceived);
            
            // Value
            sellOne.sentValue = sellOne.sentValue.mul(proportionReceived);
            sellOne.receivedValue = sellOne.receivedValue.mul(proportionReceived);

            glEntry = new GainLossEntry(buy, sellOne);
            gains.push(glEntry);

            // Amount
            sellClone.sentAmount = sellClone.sentAmount.minus(sellOne.sentAmount);
            sellClone.receivedAmount = sellClone.receivedAmount.minus(sellOne.receivedAmount);

            // Value
            sellClone.sentValue = sellClone.sentValue.minus(sellOne.sentValue);
            sellClone.receivedValue = sellClone.receivedValue.minus(sellOne.receivedValue);
          }
        }
    }
  }

  public get sellMonth(): string {
    return `${this._sell.date.getMonth() + 1}/${this._sell.date.getFullYear()}`;
  }

  public get toDb(): any {
    return {
      description: this.description,
      cost: this.cost.toNumber(),
      proceeds: this.proceeds.toNumber(),
      boughtAt: this.boughtAt,
      soldAt: this.soldAt,
      profit: this.profit.toNumber(),
      symbol: this._sell.sentSymbol.trim(),
      amount: this._sell.sentAmount.toNumber()
    }
  }

  public static fromDb(gl: any) {
    return {
      _id: gl._id,
      description: gl.description,
      cost: new Decimal(gl.cost),
      proceeds: new Decimal(gl.proceeds),
      boughtAt: gl.boughtAt,
      soldAt: gl.soldAt,
      profit: new Decimal(gl.profit),
      symbol: gl.symbol,
      amount: new Decimal(gl.amount)
    }
  }
}