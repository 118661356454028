import { DateHelpers, YearMonth } from "@/helpers/general-helpers";
import { DbTable } from "@/helpers/DbTable";
import { GainLossEntry } from "@/models/gain-loss-entry";
import { Filters } from "@/store/interfaces/filters";
import { GainLossChart } from "@/store/interfaces/report-store-state";
import Decimal from "decimal.js-light";
import { dbFind } from "@/services/db/dbFind";

export function glChart(filters: Filters, payload: any): Promise<GainLossChart> {
  return new Promise((resolve, reject) => {
    dbFind(null, DbTable.GAINS, payload, null, { soldAt: 1 }, filters)
      .then((docs: any[]) => {
        const gains = docs.map(d => GainLossEntry.fromDb(d));
        const dates = gains.map(g => g.boughtAt ? [g.boughtAt, g.soldAt] : g.soldAt).flat();
        if (dates.length === 0) {
          return resolve({
            proceeds: {
              labels: [],
              values: []
            },
            cost: {
              labels: [],
              values: []
            },
            profit: {
              labels: [],
              values: []
            }
          });
        }
        const start = dates.reduce(function (a, b) { return a < b ? a : b; });
        const last = dates.reduce(function (a, b) { return a > b ? a : b; });
        const buckets = {
          proceeds: {},
          cost: {},
          profit: {}
        };
        if (start && last) {
          Object.keys(buckets).forEach(key => {
            const bucket = buckets[key];
            const months = DateHelpers.getMonths(start, last);
            months.forEach((ym: YearMonth) => {
              bucket[`${ym.month + 1}/${ym.year}`] = new Decimal(0);
            });
            gains.forEach(g => {
              const date = key === 'cost' && g.boughtAt ? g.boughtAt : g.soldAt;
              const month = `${date.getMonth() + 1}/${date.getFullYear()}`;
              bucket[month] = bucket[month].add(g[key]);
            });
          });
        }
        resolve(
          {
            proceeds: {
              labels: Object.keys(buckets.proceeds),
              values: Object.values(buckets.proceeds).map((val: Decimal) => val.toDecimalPlaces(2).toNumber())
            },
            cost: {
              labels: Object.keys(buckets.cost),
              values: Object.values(buckets.cost).map((val: Decimal) => val.toDecimalPlaces(2).toNumber())
            },
            profit: {
              labels: Object.keys(buckets.profit),
              values: Object.values(buckets.profit).map((val: Decimal) => val.toDecimalPlaces(2).toNumber())
            }
          }
        );
      })
      .catch(err => reject(err));
  });
}
