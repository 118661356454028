import { DbTable } from "@/helpers/DbTable"
import { PriceData } from "@/models/gain-loss-entry";
import { Transaction, TransactionFactory } from "@/models/transaction"
import { dbFind } from "./dbFind"
import { dbUpdate } from "./dbUpdate";
import { priceAt } from "./priceAt";

export const updateValues = async (): Promise<void> => {
  const prices: PriceData[] = await dbFind(null, DbTable.PRICES, {}, null, null, null);
  // find transactions without values or zero values
  const query = {
    $or: [
      {
        feeValue: 0,
        feeSymbol: { $exists: true }
      },
      {
        sentValue: 0,
        sentSymbol: { $exists: true }
      },
      {
        feeValue: 0,
        receivedSymbol: { $exists: true }
      },
      {
        feeValue: { $exists: false },
        feeSymbol: { $exists: true }
      },
      {
        sentValue: { $exists: false },
        sentSymbol: { $exists: true }
      },
      {
        feeValue: { $exists: false },
        receivedSymbol: { $exists: true }
      },
      {
        'children.feeValue': 0,
        'children.feeSymbol': { $exists: true }
      },
      {
        'children.sentValue': 0,
        'children.sentSymbol': { $exists: true }
      },
      {
        'children.feeValue': 0,
        'children.receivedSymbol': { $exists: true }
      },
      {
        'children.feeValue': { $exists: false },
        'children.feeSymbol': { $exists: true }
      },
      {
        'children.sentValue': { $exists: false },
        'children.sentSymbol': { $exists: true }
      },
      {
        'children.feeValue': { $exists: false },
        'children.receivedSymbol': { $exists: true }
      }
    ]
  }
  const docs = await dbFind(null, DbTable.TRANSACTIONS, query, null, null, null);
  const txs: Transaction[] = docs.map(d => TransactionFactory.fromDB(d));
  // console.debug('txs... for values update', txs);

  for (const tx of txs) {
    for (const child of [tx, tx.children].flat()) {
      for (const type of ['fee', 'received', 'sent']) {
        const coinId = child[`${type}Coin`] ? child[`${type}Coin`]._id : null;
        if (coinId && child[`${type}Value`].comparedTo(0) === 0) {
          const amountKey = type === 'fee' ? 'fee' : `${type}Amount`;
          if (coinId === 'stax-fiat-usd') {
            child[`${type}Value`] = child[amountKey];
          } else {
            const price = prices.find(chart => chart._id === coinId);
            child[`${type}Value`] = priceAt(price, child.date).mul(child[amountKey]);
            // console.debug('child...', child, child.date);
          }
        }
      }
    }
    // console.debug('updated values...', tx, [tx.children]);
    await dbUpdate(DbTable.TRANSACTIONS, { _id: tx._id }, TransactionFactory.toDB(tx), null);
  }
}