<template>
  <div>
    <b-toast :variant="msg.type" :visible="true" v-for="msg in messages" :key="msg.id" auto-hide-delay="10000" @hidden="hidden(msg)">
      <template #toast-title v-if="msg.type === 'success'">
        <b-icon-check></b-icon-check> Success
      </template>
      <template #toast-title v-else-if="msg.type === 'danger'">
        <b-icon-exclamation-triangle-fill></b-icon-exclamation-triangle-fill> Error
      </template>
      <template #toast-title v-else-if="msg.type === 'warning'">
        <b-icon-exclamation-triangle></b-icon-exclamation-triangle> Warning
      </template>
      <template #toast-title v-else-if="msg.type === 'info'">
        <b-icon-info-circle-fill></b-icon-info-circle-fill> Info
      </template>
      {{ msg.text }}
    </b-toast>
    <b-toast variant="info" :visible="loading" no-auto-hide>
      <template #toast-title>
        <b-icon-calculator-fill></b-icon-calculator-fill> Calculating
      </template>
      <b-progress :value="txProcessed" :max="txTotal" :variant="txProcessed === txTotal ? 'success' : 'primary'"></b-progress>
    </b-toast>
    <b-toast variant="info" :visible="importing" no-auto-hide>
      <template #toast-title>
        <b-spinner type="grow" small class="mr-1"></b-spinner> Importing transactions
      </template>
      <p>Importing transactions... sit back and relax, this may take a while.</p>
    </b-toast>
    <b-toast variant="info" :visible="nimiqConsensus && nimiqConsensus !== 'established'" no-auto-hide>
      <template #toast-title>
        <b-spinner type="grow" small class="mr-1"></b-spinner> Connecting
      </template>
      <p>Syncing to the Nimiq Network... status: {{ nimiqConsensus }}</p>
    </b-toast>
    <b-toast variant="info" no-auto-hide :visible="isTrial && trialDaysLeft > 0">
      <template #toast-title>
        <b-icon-info-circle small class="mr-1"></b-icon-info-circle>{{ trialDaysLeft }} day{{ trialDaysLeft === 1 ? '' : 's' }} left in trial!
      </template>
      <p>Enter a license key on the <router-link to="/settings">Settings page</router-link></p>
    </b-toast>
    <b-toast variant="danger" no-auto-hide :visible="isTrial && trialDaysLeft <= 0">
      <template #toast-title>
        <b-icon-info-circle small class="mr-1"></b-icon-info-circle>Trial Expired!
      </template>
      <p>Enter a license key on the <router-link to="/settings">Settings page</router-link></p>
    </b-toast>
    <b-toast variant="info" :visible="!ready" no-auto-hide>
      <template #toast-title>
        <b-icon-info-circle small class="mr-1"></b-icon-info-circle>{{ !ready ? '' : 'Loaded' }} {{ loadingInfo !== 'loaded' ? loadingInfo : '' }}
      </template>
      <b-progress :value="loadingIndex" :max="loadingStates.length-1" :variant="loadingIndex === loadingStates.length-1 ? 'primary' : 'secondary'"></b-progress>
    </b-toast>
    <b-toast variant="info" :visible="isBasic" v-if="isBasic" no-auto-hide>
      <template #toast-title>
        <b-icon-info-circle small class="mr-1"></b-icon-info-circle>Upgrade to Pro
      </template>
      <p>You currently have the basic version of Symbol Tax installed. Upgrade to Pro on the <router-link to="/settings">Settings page</router-link>.</p>
    </b-toast>
    <b-toast :variant="purchaseDetails[purchaseInfo.state].type" :visible="!!purchaseInfo && !!purchaseDetails[purchaseInfo.state]" :no-auto-hide="isPurchasing" v-if="!!purchaseInfo && !!purchaseDetails[purchaseInfo.state]">
      <template #toast-title>
        <b-spinner type="grow" small class="mr-1" v-if="purchaseInfo.state === 'purchasing'"></b-spinner>
        <b-icon-info-circle-fill v-else></b-icon-info-circle-fill>
        {{ purchaseDetails[purchaseInfo.state].title }}
      </template>
      <p>{{ purchaseDetails[purchaseInfo.state].message }}</p>
    </b-toast>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      loadingStates: [
        '',
        'Running data migrations...',
        'Checking settings...',
        'Refreshing coin list...',
        'Getting coin prices...',
        'Updating missing transaction values...',
        'Fetching portfolio summary...',
        'loaded'
      ]
    }
  },
  computed: {
    ...mapState({
      messages: state => state.messages,
      nimiqConsensus: state => state.nimiqConsensus,
      ready: state => state.loadingInfo === 'loaded',
      loadingInfo: state => state.loadingInfo,
      purchaseInfo: state => state.purchaseInfo
    }),
    purchaseDetails() {
      return {
        failed: {
          type: 'danger',
          title: 'Purchase Failed',
          message: 'Something went wrong during the In-App Purchase process.'
        },
        restored: {
          type: 'success',
          title: 'Purchase Restored',
          message: 'Your Purchase for Symbol Tax Pro has been restored.'
        },
        deferred: {
          type: 'warning',
          title: 'Purchase Deferred',
          message: 'Your Purchase for Symbol Tax Pro has been deferred.'
        },
        purchasing: {
          type: 'info',
          title: 'Purchasing',
          message: 'In-App purchase in progress...'
        },
        purchased: {
          type: 'success',
          title: 'Purchased',
          message: 'In-App purchase succeeded.'
        }
      }
    },
    ...mapState('reports', ['txProcessed', 'txTotal', 'loading']),
    ...mapState('transactions', ['importing']),
    ...mapGetters(['isTrial', 'trialExpired', 'trialDaysLeft', 'isBasic', 'isPurchasing']),
    loadingIndex () {
      return this.loadingStates.indexOf(this.loadingInfo)
    }
  },
  methods: {
    ...mapMutations(['deleteMessage']),
    hidden(msg) {
      this.deleteMessage(msg.id);
    }
  }
}
</script>

<style>
.b-toast {
  z-index: 599;
}
</style>