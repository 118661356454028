import { Transaction } from "@/models/transaction";
import Decimal from "decimal.js-light";
import assets from "./assets";
import { KrakenApiTransactionWithId, KrakenApiTransaction } from "./kraken-api";

const altSyms = {
  'XDG': 'DOGE',
  'XBT': 'BTC'
}

export class KrakenTransaction implements Transaction {
  private _tx: KrakenApiTransactionWithId;

  constructor(tx: KrakenApiTransaction, id: string) {
    this._tx = {
      id,
      ...tx
    };
  }

  get symbol(): string {
     let sym = assets[this._tx.asset] ? assets[this._tx.asset].altname : this._tx.asset;
     if (altSyms[sym]) {
       sym = altSyms[sym];
     }
     return sym;
  }

  get amount(): Decimal { return new Decimal(this._tx.amount); }
  get date(): Date { return new Date(`${this._tx.time} GMT`); }

  get receivedSymbol(): string {
    if (this.amount.gt(0)) {
      return this.symbol;
    } else {
      return null;
    }
  }

  get receivedAmount(): Decimal {
    if (this.amount.gt(0)) {
      return this.amount;
    } else {
      return new Decimal(0);
    }
  }

  get sentSymbol(): string {
    if (this.amount.lt(0)) {
      return this.symbol;
    } else {
      return null;
    }
  }

  get sentAmount(): Decimal {
    if (this.amount.lt(0)) {
      return this.amount.abs();
    } else {
      return new Decimal(0);
    }
  }

  get price(): Decimal { return new Decimal(0); }
  get fee(): Decimal { return new Decimal(this._tx.fee); }
  get feeSymbol(): string { return this.symbol }
  get description(): string { return `Kraken ${this._tx.type} `; }
  get source(): any {
    return {
      name: 'Kraken',
      ...this._tx
    }
  }
  get _id(): string { return this._tx.id; }
  children: Transaction[] = [];
}
