import { DbTable } from "@/helpers/DbTable";
import { dbInsert } from "@/services/db/dbInsert";
import { dbFind } from "@/services/db/dbFind";
import { ImportResponse } from "@/services/import-response";
import { Transaction, TransactionFactory } from "@/models/transaction";
import { ApiCredentials, BaseApi, OauthCredentials, TokenCredentials } from "./base-api";
import ServiceVerificationFactory from "./service-verification-factory";

export interface BaseImport {
  import(): Promise<ImportResponse>;
}

export class BaseImportService implements BaseImport {
  api: BaseApi;

  constructor(service: string, creds: ApiCredentials | OauthCredentials | TokenCredentials, ...args: any[]) {
    this.api = ServiceVerificationFactory.create(service, creds, ...args);
  }

  import(): Promise<ImportResponse> {
    return new Promise((resolve, reject) => {
      this.api.fetchTransactions()
        .then((txs: Transaction[]) => {
          const importResponse: ImportResponse = {
            new: 0,
            existing: 0,
            linked: 0
          }
          dbFind(null, DbTable.TRANSACTIONS, this.matchingQuery(txs), null, null)
          .then((matchingTxs: any[]) => {
            importResponse.existing = matchingTxs.length;
            const newTxs = txs.filter(tx => !matchingTxs.find(match => match._id === tx._id));
            importResponse.new = newTxs.length;
            dbInsert(DbTable.TRANSACTIONS, newTxs.map(t => TransactionFactory.toDB(t)))
              .then(res => resolve(importResponse))
              .catch(err => reject(err))
          })
          .catch(err => reject(err))
        })
        .catch(err => reject(err))
    })
  }

  protected matchingQuery(txs: Transaction[]) {
    const ids = txs.map(t => t._id);
    const childrenIds = txs.map(t => t.children).flat().map(t => t._id)
    const query = { $or: [{ _id: { $in: ids } }, { 'children._id': { $in: [...ids, ...childrenIds] } }] };
    return query;
  }

  protected newTxs(txs: Transaction[], matchingTxs: any[]) {
    const foundIds = [...matchingTxs.map(t => t._id), ...matchingTxs.map(t => t.children).flat().map(t => t._id)];
    return txs.filter(tx => {
      const found = foundIds.indexOf(tx._id) === -1;
      return found;
    });
  }
}